import React, { FC } from 'react'
import { tr, Label } from 'common/i18n'
import { RoomComponentProps } from './RoomDetail'
import { RoomSelectGroup } from './RoomSelectGroup'

export const RoomVisibility: FC<RoomComponentProps> = ({
  room,
  update,
  getErrorByName,
}) => {
  return (
    <>
      <div className='text-contrast-h text-lg font-bold mb-6'>
        {tr(Label.ROOM_VISIBILITY)}
      </div>
      <RoomSelectGroup
        update={update}
        getErrorByName={getErrorByName}
        name='visibility'
        label={Label.VISIBILITY}
        defaultValue={room?.visibility}
      />
      <RoomSelectGroup
        update={update}
        getErrorByName={getErrorByName}
        name='order_priority'
        label={Label.ORDER_PRIORITY}
        defaultValue={room?.order_priority}
        placeholder={tr(Label.NONE)}
      />
    </>
  )
}
