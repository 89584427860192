import React, { FC, ChangeEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { useFetchRooms, useDeleteRoom, useDebounce } from 'common/hooks'
import { ThIcon, TimesCircleIcon } from 'common/icons'
import { RoutePath } from 'common/constants'
import { tr, Label } from 'common/i18n'
import { Room } from 'common/rest'
import { SEARCH_DEBOUNCE_DELAY } from 'src/constants'
import { ButtonWithConfirmation } from 'src/components/Button/Button'
import { CreateNewRoomButton } from '../CreateNewRoomButton'
import { RequestStateSwitcher } from '../RequestStateSwitcher'
import { SearchInput } from 'src/components/Inputs/SearchInput'
import { toastSuccess } from 'src/services/ToastService'
import { canManageRoom } from '../utils'
import { RoomCopyInviteLinkButton } from 'src/components/Rooms/RoomCopyInviteLinkButton'
import { DuplicateRoomButton } from 'src/components/Rooms/DuplicateRoomButton'
import { withTextTooltip } from 'src/hoc/withTextTooltip'

export const RoomList: FC = () => {
  const { rooms, requestState, refresh, error } = useFetchRooms()
  const [filter, setFilter] = useState('')
  const delayedFilter = useDebounce(
    filter,
    SEARCH_DEBOUNCE_DELAY
  )?.toLowerCase()
  const filteredRooms = rooms.filter(({ name }) =>
    name.toLowerCase().includes(delayedFilter)
  )

  return (
    <>
      <div className='administration-section-title'>
        <div className='font-neue font-bold text-contrast-h text-2xl'>
          {tr(Label.ROOMS)}
        </div>
        <CreateNewRoomButton />
      </div>
      <div className='block-primary administration-section-content'>
        <RequestStateSwitcher
          requestState={requestState}
          refresh={refresh}
          error={error}
        >
          <div className='flex items-center justify-between mb-4'>
            <SearchInput
              className='text-sm mr-4 '
              clearInputFunction={() => setFilter('')}
              currentValue={filter}
              inputChanged={(event: ChangeEvent<HTMLInputElement>) => {
                setFilter(event.target.value)
              }}
              name='groupFilter'
              placeholder='Search'
            />
          </div>
          {!filteredRooms?.length && (
            <p className='text-base py-2'>No rooms found.</p>
          )}
          {filteredRooms.map((room) => {
            const hasPermission = canManageRoom(room?.my_roles)
            if (hasPermission) {
              return (
                <RoomItem
                  key={room.id}
                  refresh={refresh}
                  room={room}
                  rooms={rooms}
                />
              )
            } else {
              return <RoomItemReadOnly key={room.id} name={room.name} />
            }
          })}
        </RequestStateSwitcher>
      </div>
    </>
  )
}

const RoomItemReadOnly: FC<{ name: string }> = ({ name }) => {
  return (
    <div className='h-12 block-primary-tint flex items-center justify-between p-3 mb-3 rounded cursor-not-allowed text-gray-500'>
      <span className='group-hover:text-sw-selected'>
        <ThIcon fixedWidth className='mr-3' />
        {name}
      </span>
    </div>
  )
}

const RoomItem: FC<{
  room: Room
  rooms: Room[]
  refresh: () => Promise<any>
}> = ({ refresh, room, rooms }) => {
  const editUrl = RoutePath.SettingsRoom.replace(':roomId', room.id)

  return (
    <Link
      to={editUrl}
      className='administration-section-item-title group h-12 hover:shadow-md'
    >
      <RoomName name={room.name} />
      <div className='item-actions justify-center gap-3'>
        <DuplicateRoomButton
          iconSize='1x'
          refresh={refresh}
          room={room}
          rooms={rooms}
        />
        <RoomCopyInviteLinkButton roomName={room.name} iconSize='1x' />
        <DeleteRoomButton refresh={refresh} roomId={room.id} />
      </div>
    </Link>
  )
}

const RoomNameComponent: FC<{ name: string }> = ({ name }) => (
  <span
    aria-label={tr(Label.EDIT)}
    className='group-hover:text-sw-selected w-3/4'
  >
    <ThIcon fixedWidth className='mr-3' />
    {name}
  </span>
)

const RoomName = withTextTooltip()(RoomNameComponent)

const DeleteRoomButton: FC<{ roomId: string; refresh: () => Promise<any> }> = ({
  roomId,
  refresh,
}) => {
  const { deleteRoom } = useDeleteRoom(roomId)
  const removeHandler = async () => {
    await deleteRoom()
    toastSuccess(tr(Label.SUCCESSFUL_DELETE, { item: tr(Label.ROOM) }))
    await refresh()
  }

  return (
    <ButtonWithConfirmation
      ariaLabel={tr(Label.REMOVE)}
      className='text-sw-red'
      onClick={removeHandler}
      singleUse
    >
      <TimesCircleIcon />
    </ButtonWithConfirmation>
  )
}
