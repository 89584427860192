import React, { FC } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { getOrderedFloaters } from 'common/redux/features/floaters/floatersSelectors'
import { MEDIA_CONTROLS_FLOATER_ID } from 'common/constants'
import { ScopedParticipantOptions } from 'src/components/ParticipantList/ParticipantOptions'
import { PlaybackControlsFloater } from 'src/components/CallSettings/PlaybackControlsFloater'

export const FloatersController: FC = () => {
  const floaters: string[] = useSelector(getOrderedFloaters, shallowEqual)

  return (
    <>
      {floaters.map((id, i) => {
        if (id === MEDIA_CONTROLS_FLOATER_ID) {
          return <PlaybackControlsFloater index={i} key={id} />
        } else {
          return (
            <ScopedParticipantOptions key={id} index={i} participantId={id} />
          )
        }
      })}
    </>
  )
}
