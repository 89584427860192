import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { CantinaState } from 'common/redux/interfaces'
import { RoomCommandAction } from 'common/constants'
import { DesktopIcon } from 'common/icons'
import { Label } from 'common/i18n'
import { useDispatchRoomParticipantCommand } from 'common/hooks'
import {
  isModerator,
  canShare,
} from 'common/redux/features/participants/participantSelectors'
import { TestId } from 'src/constants'
import { ButtonFeatureToggle } from 'src/components/Inputs/ButtonFeatureToggle'

type GrantScreenShareToggleProps = {
  participantId: string
}

export const GrantScreenShareToggle: FC<GrantScreenShareToggleProps> = ({
  participantId,
}) => {
  const dispatchRoomParticipantCommand =
    useDispatchRoomParticipantCommand(participantId)
  const isMod = useSelector((state: CantinaState) =>
    isModerator(state, participantId)
  )
  const checked = useSelector((state: CantinaState) =>
    canShare(state, participantId)
  )

  if (isMod) {
    return null
  }

  return (
    <ButtonFeatureToggle
      checked={Boolean(checked)}
      Icon={DesktopIcon}
      label={Label.GRANT_SCREEN_SHARE}
      onToggleOn={() => {
        dispatchRoomParticipantCommand({
          action: RoomCommandAction.GrantScreenShare,
          participantId,
        })
      }}
      onToggleOff={() => {
        dispatchRoomParticipantCommand({
          action: RoomCommandAction.RevokeScreenShare,
          participantId,
        })
      }}
      testId={TestId.ParticipantToggleGrantScreenShare}
    />
  )
}
