import React, { FC } from 'react'
import { compose } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { CantinaState } from 'common/redux/interfaces'
import { isPushToTalkEnabled } from 'common/redux/views/callView/callViewSelectors'
import { isSendingAudio } from 'common/redux/features/calls/callSelectors'
import { isMuted } from 'common/redux/features/participants/participantSelectors'
import { uiActions } from 'common/redux/features'
import {
  withConferenceAudioMuteSelfScope,
  withCurrentParticipant,
  withActiveCall,
} from 'common/hoc'
import { Displayable, Scope } from 'common/constants'
import { MicrophoneOffIcon } from 'common/icons'
import { Label, tr } from 'common/i18n'
import { TestId } from 'src/constants'
import { withTooltipCaret } from 'src/components/Tooltip'
import { AudioSettings } from 'src/components/Devices/AudioSettings'
import {
  InCallParticipantAudioMuteButton,
  InCallParticipantAudioPttButton,
  ParticipantCommandButton,
  ParticipantButtonProps,
  InCallParticipantButtonProps,
} from './index'

const InCallParticipantAudioButtonDisabled: FC<ParticipantButtonProps> = ({
  disabled,
  participantId,
  testId,
}) => {
  // Open DevicesModal to select a device
  const dispatch = useDispatch()
  const clickHandler = () => dispatch(uiActions.show(Displayable.DevicesModal))

  return (
    <ParticipantCommandButton
      activated={true}
      clickHandler={clickHandler}
      disabled={disabled}
      Icon={MicrophoneOffIcon}
      label={tr(Label.ENABLE_MICROPHONE)}
      participantId={participantId}
      scope={Scope.ConferenceAudioMuteSelf}
      testId={testId}
    />
  )
}

interface ParticipantAudioControlButtonProps extends ParticipantButtonProps {
  callId: string
}

const ParticipantAudioControlButton: FC<ParticipantAudioControlButtonProps> = ({
  callId,
  ...rest
}) => {
  const sendingAudio = useSelector((state: CantinaState) =>
    isSendingAudio(state, callId)
  )

  return sendingAudio ? (
    <InCallParticipantAudioMuteButton
      testId={TestId.BottomBarButtonAudio}
      {...rest}
    />
  ) : (
    <InCallParticipantAudioButtonDisabled
      testId={TestId.BottomBarButtonAudio}
      {...rest}
    />
  )
}

interface ParticipantAudioButtonProps
  extends ParticipantAudioControlButtonProps {}

const BottomBarParticipantAudioButton: FC<ParticipantAudioButtonProps> = (
  props
) => {
  const { participantId } = props
  const pttEnabled = useSelector((state: CantinaState) =>
    isPushToTalkEnabled(state)
  )
  const muted = useSelector((state: CantinaState) =>
    isMuted(state, participantId)
  )

  // We are determining PTT state first because both PTT and Disabled buttons
  //  use the isSendingAudio flag in a conflicting way
  // A non-functioning PTT button will be displayed if PTT is toggled on with
  //  no mic enabled
  return !muted && pttEnabled ? (
    <InCallParticipantAudioPttButton {...props} />
  ) : (
    <ParticipantAudioControlButton {...props} />
  )
}

const BottomBarParticipantAudioButtonEnabled = compose<
  FC<ParticipantAudioButtonProps>
>(
  withTooltipCaret(
    AudioSettings,
    TestId.BottomBarButtonAudioTooltip,
    Label.MICROPHONE_OPTIONS
  )
)(BottomBarParticipantAudioButton)

// The InCall button is used in the bottom bar and includes the caret for the audio settings
export const InCallParticipantAudioButton = compose<
  FC<InCallParticipantButtonProps>
>(
  withConferenceAudioMuteSelfScope,
  withCurrentParticipant,
  withActiveCall
)((props: ParticipantAudioButtonProps) => {
  if (props.disabled) {
    return <BottomBarParticipantAudioButton {...props} />
  }
  return <BottomBarParticipantAudioButtonEnabled {...props} />
})
