import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { CantinaState } from 'common/redux/interfaces'
import { isScreenShareLeg } from 'common/redux/features/participants/participantSelectors'
import {
  ParticipantAudioMuteButton,
  ParticipantVideoMuteButton,
  ParticipantKickButton,
  ParticipantHandButton,
} from 'src/components/ParticipantCommandButtons'
import { TestId } from 'src/constants'
import { ParticipantSettingsButton } from '../CallButtons/ParticipantSettingsButton'

type ParticipantListRowActionsProps = {
  participantId: string
  testIdSuffix: string
}

export const ParticipantListRowActions: FC<ParticipantListRowActionsProps> = ({
  participantId,
  testIdSuffix,
}) => {
  const isScreenShare = useSelector((state: CantinaState) =>
    isScreenShareLeg(state, participantId)
  )

  return (
    // If you change the toggle-button-grid gap, it needs to also be adjusted in the Safari polyfill
    <div className='participant-row-actions'>
      <ParticipantVideoMuteButton
        participantId={participantId}
        size='1x'
        testId={`${TestId.ParticipantButtonVideo}${testIdSuffix}`}
      />
      <ParticipantAudioMuteButton
        participantId={participantId}
        size='1x'
        testId={`${TestId.ParticipantButtonAudio}${testIdSuffix}`}
      />
      {!isScreenShare && (
        <ParticipantHandButton
          participantId={participantId}
          size='1x'
          testId={`${TestId.ParticipantButtonHand}${testIdSuffix}`}
        />
      )}
      <ParticipantKickButton
        participantId={participantId}
        size='1x'
        testId={`${TestId.ParticipantButtonKick}${testIdSuffix}`}
      />
      <ParticipantSettingsButton
        participantId={participantId}
        size='1x'
        showTooltip
      />
    </div>
  )
}
