import React, { FC } from 'react'
import { toast } from 'react-toastify'
import { ToastPosition, ToastProps, Color } from 'common/constants'
import {
  InfoCircleIconSolid,
  TimesCircleIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
  CloseIcon,
} from 'common/icons'
import { tr, Label } from 'common/i18n'

// Create color classes because PurgeCSS eliminates composed classes, e.g. text-sw-${color}
const colorClass = {
  [Color.Info]: {
    text: 'text-sw-info',
    border: 'border-sw-info',
    hover: 'hover:text-sw-info',
  },
  [Color.Warning]: {
    text: 'text-sw-warning',
    border: 'border-sw-warning',
    hover: 'hover:text-sw-warning',
  },
  [Color.Success]: {
    text: 'text-sw-success',
    border: 'border-sw-success',
    hover: 'hover:text-sw-success',
  },
  [Color.Danger]: {
    text: 'text-sw-danger',
    border: 'border-sw-danger',
    hover: 'hover:text-sw-danger',
  },
}

const getPosition = (toastPosition?: ToastPosition) => {
  switch (toastPosition) {
    case ToastPosition.TOP:
      return toast.POSITION.TOP_CENTER
    case ToastPosition.TOP_RIGHT:
      return toast.POSITION.TOP_RIGHT
    case ToastPosition.TOP_LEFT:
      return toast.POSITION.TOP_LEFT
    case ToastPosition.BOTTOM:
      return toast.POSITION.BOTTOM_CENTER
    case ToastPosition.BOTTOM_RIGHT:
      return toast.POSITION.BOTTOM_RIGHT
    case ToastPosition.BOTTOM_LEFT:
      return toast.POSITION.BOTTOM_LEFT
    default:
      return toast.POSITION.TOP_RIGHT
  }
}

function convertUnicode(input: string) {
  return input.replace(/\\u(\w\w\w\w)/g, function (a, b) {
    const charcode = parseInt(b, 16)
    return String.fromCharCode(charcode)
  })
}

// Additional styles: react/src/theme/vendor/react-toastify.css
export const ToastCard: FC<ToastProps> = ({
  message,
  header,
  link,
  linkText = `${tr(Label.LEARN_MORE)} \u2192`,
  linkTarget = '_blank',
  Icon,
  color = Color.Info,
}) => {
  // @ts-expect-error
  const { border = 'border-sw-info', text = 'text-sw-info' } = colorClass[color]
  return (
    <div className={`flex pl-3 pr-4 border-l-4 text-contrast-h ${border}`}>
      {Icon && <div className={`shrink-0 self-center ${text}`}>{Icon}</div>}
      <div className={`flex flex-col self-center py-2 ${Icon ? 'ml-4' : ''}`}>
        {header && <h4 className='font-medium leading-5 mb-2'>{header}</h4>}
        <div className='text-sm leading-4 opacity-50'>{message}</div>
        {link && (
          <a
            className='text-sm mt-2 text-sw-selected'
            href={link}
            target={linkTarget}
            rel='noreferrer'
          >
            {convertUnicode(linkText)}
          </a>
        )}
      </div>
    </div>
  )
}

const ToastCloseButton =
  (color: Color = Color.Info) =>
  ({ closeToast }: any) =>
    (
      <button
        onClick={closeToast}
        className={`self-center px-2 text-contrast-h ${
          // @ts-expect-error
          colorClass[color]?.hover ?? 'hover:text-sw-info'
        }`}
      >
        <CloseIcon className='text-2xl' />
      </button>
    )

export const toastInfo = (message: string, options?: ToastProps) => {
  const {
    position,
    className = '',
    header = undefined,
    link,
    linkText,
    linkTarget,
    Icon = <InfoCircleIconSolid size='2x' fixedWidth />,
    color = Color.Info,
    autoClose,
  } = options || {}
  return toast(
    <ToastCard
      message={message}
      header={header}
      link={link}
      linkText={linkText}
      linkTarget={linkTarget}
      Icon={Icon}
      color={color}
    />,
    {
      position: getPosition(position),
      className,
      closeButton: ToastCloseButton(color),
      autoClose,
    }
  )
}

export const toastWarn = (message: string, options?: ToastProps) => {
  const {
    position,
    className = '',
    link,
    linkText,
    linkTarget,
    header,
    Icon = <ExclamationCircleIcon size='2x' fixedWidth />,
    color = Color.Warning,
    autoClose,
  } = options || {}
  return toast(
    <ToastCard
      message={message}
      header={header}
      link={link}
      linkText={linkText}
      linkTarget={linkTarget}
      Icon={Icon}
      color={color}
    />,
    {
      position: getPosition(position),
      className,
      closeButton: ToastCloseButton(color),
      autoClose,
    }
  )
}

export const toastError = (message: string, options?: ToastProps) => {
  const {
    position,
    className = '',
    link,
    linkText,
    linkTarget,
    header = undefined,
    Icon = <TimesCircleIcon size='2x' fixedWidth />,
    color = Color.Danger,
    autoClose,
  } = options || {}
  return toast(
    <ToastCard
      message={message}
      header={header}
      link={link}
      linkText={linkText}
      linkTarget={linkTarget}
      Icon={Icon}
      color={color}
    />,
    {
      position: getPosition(position),
      className,
      closeButton: ToastCloseButton(color),
      autoClose,
    }
  )
}

export const toastSuccess = (message: string, options?: ToastProps) => {
  const {
    position,
    className = '',
    link,
    linkText,
    linkTarget,
    header = undefined,
    Icon = <CheckCircleIcon size='2x' fixedWidth />,
    color = Color.Success,
    autoClose,
  } = options || {}
  return toast(
    <ToastCard
      message={message}
      header={header}
      link={link}
      linkText={linkText}
      linkTarget={linkTarget}
      Icon={Icon}
      color={color}
    />,
    {
      position: getPosition(position),
      className,
      closeButton: ToastCloseButton(color),
      autoClose,
    }
  )
}

export const toastCustom = (message: string, options?: ToastProps) => {
  const {
    position,
    className = '',
    link,
    linkText,
    linkTarget,
    header = undefined,
    Icon = <CheckCircleIcon size='2x' fixedWidth />,
    color = Color.Info,
    autoClose,
  } = options || {}
  return toast(
    <ToastCard
      message={message}
      header={header}
      link={link}
      linkText={linkText}
      linkTarget={linkTarget}
      Icon={Icon}
      color={color}
    />,
    {
      position: getPosition(position),
      className,
      closeButton: ToastCloseButton(color),
      autoClose,
    }
  )
}

export const dismissToast = (toastInstance: React.ReactText) =>
  toast.dismiss(toastInstance)

export const dismissAllToast = () => toast.dismiss()
