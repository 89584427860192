import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { CantinaState } from 'common/redux/interfaces'
import { isTalking } from 'common/redux/features/participants/participantSelectors'

type ParticipantTalkingIconProps = {
  participantId: string
}

export const ParticipantTalkingShadow: FC<ParticipantTalkingIconProps> = ({
  participantId,
}) => {
  const talking = useSelector((state: CantinaState) =>
    isTalking(state, participantId)
  )
  if (!talking) {
    return null
  }
  return (
    <span className='talking-shadow absolute w-full h-full rounded-full inset-0'></span>
  )
}
