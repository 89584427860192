import React, { FC } from 'react'
import { useRoomSelector, useSmartRoomSelector } from 'common/hooks'
import { LockIcon, VolumeSlashIcon } from 'common/icons'
import { hasMeetingMode } from 'common/redux/features/rooms/roomsSelectors'
import { FontAwesomeIconProps } from 'common/icons/FontAwesomeIcon'

interface RoomStatusIconProps extends Partial<FontAwesomeIconProps> {
  roomId: string
}

export const RoomStatusIcon: FC<RoomStatusIconProps> = ({
  roomId,
  ...rest
}) => {
  const roomLocked = useSmartRoomSelector('isLocked', roomId)
  const roomMeetingMode = useRoomSelector(hasMeetingMode, roomId)
  let IndicatorIcon = null

  if (roomLocked) {
    IndicatorIcon = <LockIcon {...rest} />
  } else if (roomMeetingMode) {
    IndicatorIcon = <VolumeSlashIcon {...rest} />
  }

  return IndicatorIcon
}
