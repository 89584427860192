import React, { FC } from 'react'
import { Switch, Route, useLocation, Redirect } from 'react-router-dom'
import { UserLookupResponse } from 'common/rest'
import { RoutePath } from 'common/constants'
import { useCantinaId } from 'common/hooks'

import { CenteredBox } from 'src/layout'
import { GuestProvider } from './GuestProvider'
import { LocalProvider } from './LocalProvider'
import { UserLookup } from './UserLookup'
import { Header } from 'src/components/Header/Header'

export const Login: FC = () => {
  return (
    <>
      <Header />
      <CenteredBox>
        <AuthContainer />
      </CenteredBox>
    </>
  )
}

const AuthContainer = () => {
  const cantinaId = useCantinaId()
  const { state } = useLocation<UserLookupResponse>()

  return (
    <Switch>
      <Route exact path={RoutePath.Login}>
        <UserLookup cantinaId={cantinaId} />
      </Route>
      <Route exact path={RoutePath.LoginLocal}>
        {state ? (
          <LocalProvider cantinaId={cantinaId} {...state} />
        ) : (
          <Redirect to={RoutePath.Login} />
        )}
      </Route>
      <Route exact path={RoutePath.LoginGuest}>
        {state ? (
          <GuestProvider cantinaId={cantinaId} {...state} />
        ) : (
          <Redirect to={RoutePath.Login} />
        )}
      </Route>
      <Redirect to={RoutePath.Login} />
    </Switch>
  )
}
