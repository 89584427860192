import React, { FC, ComponentType } from 'react'
import { tr, Label } from 'common/i18n'
import {
  FontAwesomeIconProps,
  FontAwesomeSizeProp,
} from 'common/icons/FontAwesomeIcon'
import { TestId } from 'src/constants'
import { withTextTooltip } from 'src/hoc/withTextTooltip'

type ButtonFeatureToggleProps = {
  checked: boolean
  buttonClassName?: string
  Icon: ComponentType<Partial<FontAwesomeIconProps>>
  iconSize?: FontAwesomeSizeProp
  label: Label
  onToggleOn: () => void
  onToggleOff: () => void
  testId: TestId
}

const ButtonFeatureToggleFunction: FC<ButtonFeatureToggleProps> = ({
  onToggleOn,
  onToggleOff,
  checked = false,
  buttonClassName = '',
  label,
  Icon,
  iconSize,
  testId,
}) => {
  return (
    <div className='flex flex-col items-center mb-2 sm:mb-0'>
      <button
        className={`rounded-md ${iconSize === 'lg' ? 'w-10 h-10' : 'w-8 h-8'} ${
          checked ? 'bg-sw-selected' : ''
        } ${buttonClassName}`}
        onClick={() => (checked ? onToggleOff() : onToggleOn())}
        data-test={testId}
        aria-label={tr(label)}
      >
        <Icon className={`${checked && 'text-primary'}`} size={iconSize} />
      </button>
      {label && (
        <p className='block md:hidden text-xs leading-tight text-center pt-1'>
          {tr(label)}
        </p>
      )}
    </div>
  )
}

export const ButtonFeatureToggle = withTextTooltip()(
  ButtonFeatureToggleFunction
)
