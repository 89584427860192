import React, { FC } from 'react'
import { compose } from 'redux'
import { useDispatch } from 'react-redux'
import { tr, Label } from 'common/i18n'
import { SidebarIcon } from 'common/icons'
import {
  withConferenceZoneManageScope,
  withSidebarConversationsFeatureFlag,
} from 'common/hoc'
import { uiActions } from 'common/redux/features'
import { Displayable } from 'common/constants'
import { TestId } from 'src/constants'
import { TOOLTIP_OFFSET_Y_BOTTOM_BAR } from 'src/hoc/withTextTooltip'
import { Button, ButtonWithTooltip } from '../Button/Button'

type SidebarButtonProps = {
  className?: string
  clickHandler?: () => void
  disabled?: boolean
  label?: string
  showLabel?: boolean
}

const SidebarButton: FC<SidebarButtonProps> = ({
  className = '',
  clickHandler,
  disabled,
  showLabel,
}) => {
  const dispatch = useDispatch()
  const buttonClickHandler = () => {
    if (clickHandler) {
      clickHandler()
    }
    dispatch(uiActions.toggle(Displayable.SidebarStartModal))
  }

  return showLabel ? (
    <Button
      className={`sw-btn-icon-toggle ${className}`}
      disabled={disabled}
      onClick={buttonClickHandler}
      testId={TestId.SidebarIncallButton}
    >
      <SidebarIcon className='svg-inline--fa fa-w-20 fa-fw fa-lg stroke-current fill-current' />
      <span className='more-menu-item-label'>{tr(Label.SIDEBAR)}</span>
    </Button>
  ) : (
    <ButtonWithTooltip
      ariaLabel={!showLabel && tr(Label.CREATE_SIDEBAR)}
      className={`sw-btn-icon-toggle ${className}`}
      disabled={disabled}
      offsetY={TOOLTIP_OFFSET_Y_BOTTOM_BAR}
      onClick={buttonClickHandler}
      testId={TestId.SidebarIncallButton}
      titleSecondary={disabled ? '' : 'S'}
    >
      <SidebarIcon className='svg-inline--fa fa-w-20 fa-fw fa-lg stroke-current fill-current' />
    </ButtonWithTooltip>
  )
}

export const InCallSidebarButton: FC<SidebarButtonProps> = compose<any>(
  withSidebarConversationsFeatureFlag(),
  withConferenceZoneManageScope
)(SidebarButton)
