import React, { FC, FormEvent, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFetchGroup } from 'common/hooks'
import { RequestState } from 'common/rest'
import { tr, Label } from 'common/i18n'
import { CantinaState } from 'common/redux/interfaces'
import { getUserAutoCompleteItems } from 'common/redux/features/users/usersSelectors'
import { PrimaryButton } from 'src/components/Button/Button'
import { Modal } from 'src/modals/Modal'
import { TestId } from 'src/constants'
import { useDecodedParams } from 'src/effects/useDecodedParams'
import { AutoComplete, AutoCompleteItemType } from 'src/components/AutoComplete'
import { AutoCompleteUserData } from './UserData'

export const AddUserToGroupButton: FC<{ refresh: () => void }> = ({
  refresh,
}) => {
  const [modalShow, setModalShow] = useState(false)
  const openModal = () => setModalShow(true)
  const closeModal = () => {
    setModalShow(false)
    refresh()
  }

  return (
    <>
      <PrimaryButton onClick={openModal}>
        {tr(Label.ADD_USER_TO_GROUP)}
      </PrimaryButton>
      <AddUserToGroupModal closeHandler={closeModal} show={modalShow} />
    </>
  )
}

type AddUserToGroupModalProps = {
  closeHandler: () => void
  show: boolean
}

const AddUserToGroupModal: FC<AddUserToGroupModalProps> = ({
  closeHandler,
  show = false,
}) => {
  const { groupId } = useDecodedParams<{ groupId: string }>()
  const { requestState, getGroupUserIds, addUsersToGroup } =
    useFetchGroup(groupId)
  const excludeUserIds = getGroupUserIds()
  const [selectedItems, setSelectedItems] = useState<AutoCompleteItemType[]>([])
  const items = useSelector((state: CantinaState) =>
    getUserAutoCompleteItems(state, excludeUserIds)
  )
  const getItemComponent = useCallback(() => AutoCompleteUserData, [])

  const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const userIds = selectedItems.map((item) => item.id)
    if (userIds.length) {
      await addUsersToGroup(userIds)
    }
    setSelectedItems([])
    closeHandler()
    return false
  }

  const resetModal = () => {
    setSelectedItems([])
    closeHandler()
  }

  return (
    <Modal
      autoComplete='off'
      closeHandler={resetModal}
      confirmHandler={submitHandler}
      isForm
      isLoading={requestState === RequestState.Loading}
      show={show}
      testId={TestId.SystemSettingsGroupForm}
      title={tr(Label.ADD_USER_TO_GROUP)}
    >
      <AutoComplete
        items={items}
        selectedItems={selectedItems}
        selectedItemsChangeHandler={setSelectedItems}
        getItemComponent={getItemComponent}
        inputPlaceholder={Label.SEARCH_USERS}
        inputLabel={Label.SELECT_USERS}
        testId={TestId.AutoCompleteUser}
      />
    </Modal>
  )
}
