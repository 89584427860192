import React, { FC } from 'react'
import { compose } from 'redux'
import { useSelector, shallowEqual } from 'react-redux'
import { CantinaState } from 'common/redux/interfaces'
import { tr, Label } from 'common/i18n'
import { UserIcon } from 'common/icons'
import { getParticipantsGroupedByReservationId } from 'common/redux/views/callView/callViewSelectors'
import { TestId } from 'src/constants'
import { withConferenceGroupsViewRolesAssigned } from 'common/hoc'
import { ParticipantGroup } from './ParticipantGroup'

interface ParticipantGroupRolesAssignedProps {
  className?: string
  roomId: string
}

const ParticipantGroupRolesAssignedFunction: FC<ParticipantGroupRolesAssignedProps> = ({
  className = '',
  roomId,
}) => {
  const rolesIds = useSelector(
    (state: CantinaState) =>
      getParticipantsGroupedByReservationId(state, roomId),
    shallowEqual
  )

  return (
    <ParticipantGroup
      className={className}
      heading={tr(Label.ROLES_ASSIGNED)}
      Icon={UserIcon}
      memberIds={rolesIds}
      groupParticipantTestId='roleAssigned'
      testId={TestId.ParticipantsGroupRolesAssigned}
    />
  )
}

export const ParticipantGroupRolesAssigned = compose<any>(
  withConferenceGroupsViewRolesAssigned
)(ParticipantGroupRolesAssignedFunction)
