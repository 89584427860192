import React, { FC } from 'react'
import { tr, Label } from 'common/i18n'
import { useRoomTemplateField } from 'common/hooks'
import { RoomTemplateKeys } from 'common/rest'
import { Checkbox } from 'src/components/Inputs/Checkbox'

export type RoomTemplateCheckboxProps = {
  name: RoomTemplateKeys
  label: Label
}
export const RoomTemplateCheckbox: FC<RoomTemplateCheckboxProps> = ({
  name,
  label,
}) => {
  const { reduxValue, update } = useRoomTemplateField(name)

  return (
    <Checkbox
      name={name}
      label={tr(label)}
      onChange={(event) => update(event.target.checked)}
      defaultChecked={reduxValue as boolean}
    />
  )
}
