import React, { FC } from 'react'
import { EllipsisIcon } from 'common/icons'
import { withConferenceLayerControlsScope } from 'common/hoc'
import { tr, Label } from 'common/i18n'
import { useFloater } from 'common/hooks'
import { FontAwesomeSizeProp } from 'common/icons/FontAwesomeIcon'
import { FloaterType } from 'common/constants'
import { TestId } from 'src/constants'
import { withTextTooltip } from 'src/hoc/withTextTooltip'

type ParticipantSettingsButtonProps = {
  children?: React.ReactNode
  className?: string
  participantId: string
  reservationId?: string
  size?: FontAwesomeSizeProp
  showTooltip?: boolean
}

const Button: FC<ParticipantSettingsButtonProps> = ({
  children,
  className,
  participantId,
  reservationId,
  size,
}) => {
  const { toggleFloaterFunction, ref } = useFloater({
    id: participantId,
    reservationId,
    type: FloaterType.ParticipantOptions,
  })

  return (
    <button
      aria-label={tr(Label.OPTIONS)}
      className='w-full'
      data-test={`${TestId.ParticipantToggleModal}_${participantId}`}
      onClick={toggleFloaterFunction}
      ref={ref}
    >
      {children ?? (
        <EllipsisIcon className={className} size={size ?? 'lg'} fixedWidth />
      )}
    </button>
  )
}

// Allow hiding the basic tooltip for the case where we are wrapping the button with a more
// informative tooltip
export const ParticipantSettingsButton: FC<ParticipantSettingsButtonProps> = ({
  showTooltip,
  ...rest
}) => {
  if (showTooltip) {
    return withTextTooltip()(Button)({ ...rest })
  }
  return <Button {...rest} />
}

export const ScopedParticipantSettingsButton: FC<ParticipantSettingsButtonProps> =
  withConferenceLayerControlsScope(ParticipantSettingsButton)
