import React, { FC, useCallback } from 'react'
import { compose } from 'redux'
import { useSelector } from 'react-redux'
import {
  RoomCommandAction,
  AUDIO_MAX_VOLUME,
  AUDIO_MIN_VOLUME,
} from 'common/constants'
import { CantinaState } from 'common/redux/interfaces'
import { useDispatchRoomCommand } from 'common/hooks'
import { VolumeDownIcon } from 'common/icons'
import { tr, Label } from 'common/i18n'
import {
  withConferenceZoneExtVolScope,
  withSidebarConversationsFeatureFlag,
} from 'common/hoc'
import { getVolumeSidebarExternal } from 'common/redux/features/roomZones/roomZonesSelectors'
import { TestId } from 'src/constants'
import RangeSlider from 'src/components/Inputs/RangeSlider'

type SidebarExternalVolumeSliderProps = {
  roomId: string
  zoneId: string
}

const SidebarExternalVolumeSlider: FC<SidebarExternalVolumeSliderProps> = ({
  roomId,
  zoneId,
}) => {
  const dispatchRoomCommand = useDispatchRoomCommand()
  const currentVolume = useSelector((state: CantinaState) =>
    getVolumeSidebarExternal(state, zoneId)
  )
  const changeHandler = useCallback(
    (volume: number) => {
      dispatchRoomCommand({
        action: RoomCommandAction.ZoneSetExternalVolume,
        roomId,
        volume,
        zoneId,
      })
    },
    [dispatchRoomCommand, roomId, zoneId]
  )

  if (currentVolume === null) {
    return null
  }

  return (
    <RangeSlider
      className='flex-auto max-w-sm'
      currentValue={currentVolume}
      horizontalLabel
      label={tr(Label.ROOM_VOLUME)}
      labelClassName='whitespace-normal text-xs md:text-base w-2/6 md:w-3/6 sm:ml-4 mr-1'
      LowerIcon={VolumeDownIcon}
      maxValue={AUDIO_MAX_VOLUME}
      minValue={AUDIO_MIN_VOLUME}
      onValueChange={changeHandler}
      step={1}
      testId={TestId.SidebarExtVolSlider}
    />
  )
}

export default compose<any>(
  withSidebarConversationsFeatureFlag(),
  withConferenceZoneExtVolScope
)(SidebarExternalVolumeSlider)
