import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { inviteViewActions } from 'common/redux/views'
import {
  isVisible,
  getRoom,
} from 'common/redux/views/inviteView/inviteViewSelectors'
import { getActiveCallRoomId } from 'common/redux/features/calls/callSelectors'
import { TestId } from 'src/constants'
import { Modal } from './Modal'
import ByLink from './InviteModal/ByLink'
import BySms from './InviteModal/BySms'
import ByCall from './InviteModal/ByCall'

export const InviteModal: FC = () => {
  const dispatch = useDispatch()
  const roomName = useSelector(getRoom)
  const show = useSelector(isVisible)

  const closeHandler = () => {
    dispatch(inviteViewActions.hide())
  }

  return (
    <Modal
      closeHandler={closeHandler}
      show={show}
      showCancelButton={false}
      showConfirmButton={false}
      testId={TestId.InviteModal}
      title={`Invite to: ${roomName}`}
    >
      <InvitationFields />
    </Modal>
  )
}

const InvitationFields = () => {
  const roomId = useSelector(getActiveCallRoomId)

  return (
    <div className='w-full flex flex-col gap-2 divide-y divide-contrast-m'>
      <ByLink />
      <ByCall roomId={roomId} />
      <BySms roomId={roomId} />
    </div>
  )
}
