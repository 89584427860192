import React, { FC } from 'react'
import { tr, Label } from 'common/i18n'
import { UseFetchRoomConsumerProps } from 'common/hooks'
import { RoomKeys } from 'common/src/rest'
import { Checkbox } from 'src/components/Inputs/Checkbox'
import { TestId } from 'src/constants'
import { useRoomAutoSave } from '../../../effects/useRoomAutoSave'

export type RoomCheckboxProps = UseFetchRoomConsumerProps & {
  name: RoomKeys
  label: Label
  defaultChecked: boolean
}
export const RoomCheckbox: FC<RoomCheckboxProps> = ({
  update,
  name,
  label,
  defaultChecked,
  getErrorByName,
}) => {
  const { currentValue, changeHandler } = useRoomAutoSave({
    defaultValue: defaultChecked,
    updateRoomHandler: update,
  })
  const error = getErrorByName(name)

  return (
    <>
      <Checkbox
        checked={currentValue as boolean}
        label={tr(label)}
        name={name}
        onChange={changeHandler}
      />
      <div
        className={`sw-error-hint ${error ? 'visible' : 'invisible'}`}
        data-test={TestId.MessageError}
      >
        {error}
      </div>
    </>
  )
}
