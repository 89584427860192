import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { uiActions } from 'common/redux/features'
import { hasFlipLocalVideo } from 'common/redux/features/ui/uiSelectors'
import HorizontalFeatureToggle from 'src/components/Inputs/HorizontalFeatureToggle'
import { TestId } from 'src/constants'

type FlipLocalVideoToggleProps = {}

export const FlipLocalVideoToggle: FC<FlipLocalVideoToggleProps> = () => {
  const dispatch = useDispatch()
  const active = useSelector(hasFlipLocalVideo)
  const toggleHandler = () => dispatch(uiActions.toggleFlipLocalVideo())

  return (
    <HorizontalFeatureToggle
      onToggleOn={toggleHandler}
      onToggleOff={toggleHandler}
      label='Mirror Video'
      checked={active}
      className='text-sm p-2 pl-6'
      testId={TestId.VideoToggleMirror}
    />
  )
}
