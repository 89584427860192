import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { RoomCommandAction } from 'common/constants'
import { useDispatchRoomParticipantCommand } from 'common/hooks'
import { CantinaState } from 'common/redux/interfaces'
import { hasCustomBanner as hasCustomBannerSelector } from 'common/redux/features/participants/participantSelectors'
import { tr, Label } from 'common/i18n'
import SubmittableInput from 'src/components/Inputs/SubmittableInput'
import { useInputChange } from 'src/effects/useInputChange'
import { Button } from 'src/components/Button/Button'
import { TestId } from 'src/constants'

interface EditBannerProps {
  className?: string
  participantId: string
}

export const EditBanner: FC<EditBannerProps> = ({
  className,
  participantId,
}) => {
  const [values, setValues, bulkUpdate] = useInputChange({ bannerText: '' })
  const hasCustomBanner = useSelector((state: CantinaState) =>
    hasCustomBannerSelector(state, participantId)
  )
  const dispatchRoomParticipantCommand =
    useDispatchRoomParticipantCommand(participantId)

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    const { bannerText } = values

    event.preventDefault()

    if (bannerText.trim() === '') {
      return
    }

    dispatchRoomParticipantCommand({
      action: RoomCommandAction.SetBannerText,
      participantId,
      text: bannerText.trim(),
    })
    bulkUpdate({ bannerText: '' })
  }

  const restoreBanner = () => {
    dispatchRoomParticipantCommand({
      action: RoomCommandAction.RestoreBannerText,
      participantId,
    })
  }

  return hasCustomBanner ? (
    <Button
      className='block ml-auto my-3 p-0 mr-1 text-blue-500 bg-transparent'
      onClick={restoreBanner}
      data-test={TestId.ParticipantButtonClearBanner}
    >
      {tr(Label.CLEAR_BANNER)}
    </Button>
  ) : (
    <div className={className}>
      <SubmittableInput
        canHaveError={false}
        currentValue={values.bannerText}
        inputChanged={setValues}
        label={tr(Label.SET_CUSTOM_BANNER)}
        name='bannerText'
        placeholder={tr(Label.BANNER_PLACEHOLDER)}
        required
        submitButtonLabel={tr(Label.UPDATE)}
        submitHandler={submitHandler}
        testId={TestId.ParticipantInputBannerText}
        type='text'
      />
    </div>
  )
}
