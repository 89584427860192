import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { withConferencePlaybackControlsScope } from 'common/hoc'
import { getActiveCallRoomId } from 'common/redux/features/calls/callSelectors'
import { FloaterType, MEDIA_CONTROLS_FLOATER_ID } from 'common/constants'
import { ScopedPlaybackControls } from 'src/components/CallSettings/PlaybackControls'
import { Floater } from 'src/layout/Floater'
import { TestId } from 'src/constants'

type Props = {
  index: number
}

const PCFloater: FC<Props> = ({ index }) => {
  const roomId = useSelector(getActiveCallRoomId)

  return (
    <Floater
      className='playback-controls-floater'
      id={MEDIA_CONTROLS_FLOATER_ID}
      index={index}
      testId={TestId.FloaterPlaybackControls}
      type={FloaterType.PlaybackControls}
    >
      <div className='px-2 pb-1'>
        <ScopedPlaybackControls isPopout roomId={roomId} />
      </div>
    </Floater>
  )
}

export const PlaybackControlsFloater =
  withConferencePlaybackControlsScope(PCFloater)
