import { useSelector } from 'react-redux'
import { getMyParticipantId } from '../redux/features/calls/callSelectors'
import { getParticipantRoomZoneId } from '../redux/features/participants/participantSelectors'
import { CantinaState } from '../redux/interfaces'
import { getRoomZone } from '../redux/features/roomZones/roomZonesSelectors'
import { RoomZone } from '../redux/interfaces'

export function useMyZone() {
  const participantId = useSelector(getMyParticipantId)
  const roomZoneId = useSelector((state: CantinaState) =>
    getParticipantRoomZoneId(state, participantId)
  )
  const isInZone = Boolean(roomZoneId)
  const myZone: RoomZone = useSelector((state: CantinaState) =>
    // @ts-expect-error
    getRoomZone(state, roomZoneId)
  )

  return { isInZone, myZone }
}
