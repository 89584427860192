import React, { FC, useCallback, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'
import { Displayable, RoomCommandAction } from 'common/constants'
import {
  withConferenceZoneManageScope,
  withSidebarConversationsFeatureFlag,
} from 'common/hoc'
import { uiActions, roomZoneActions } from 'common/redux/features'
import { CantinaState } from 'common/redux/interfaces'
import { tr, Label } from 'common/i18n'
import { useDispatchRoomCommand } from 'common/hooks'
import { SidebarIcon } from 'common/icons'
import {
  getRoomZoneName,
  getZoneEditInvitationList,
} from 'common/redux/features/roomZones/roomZonesSelectors'
import { getRoomParticipantModalIds } from 'common/redux/views/callView/callViewSelectors'
import { getMyParticipantId } from 'common/redux/features/calls/callSelectors'
import { isSidebarEditModalVisible } from 'common/redux/features/ui/uiSelectors'
import { getRoomParticipantIdsByRoomZoneId } from 'common/redux/features/participants/participantSelectors'

import { TestId } from 'src/constants'
import { PrimaryButton, SecondaryButton } from 'src/components/Button/Button'
import { SearchParticipantModalWithFilter } from 'src/components/ParticipantList/SearchParticipantList'
import { Modal } from '../Modal'
import { ParticipantData } from '../../components/ParticipantList/ParticipantData'

type EditSideBarModalProps = {
  roomId: string
  zoneId: string
}

const EditSideBarModal: FC<EditSideBarModalProps> = ({ roomId, zoneId }) => {
  const dispatch = useDispatch()
  const currentZoneName = useSelector((state: CantinaState) =>
    getRoomZoneName(state, zoneId)
  )
  const myParticipantId = useSelector(getMyParticipantId)
  /* eslint-disable-next-line */
  const [zoneName, setZoneName] = useState(currentZoneName)
  const show: boolean = useSelector(isSidebarEditModalVisible)
  const participantIds: string[] = useSelector(
    (state: CantinaState) => getRoomParticipantModalIds(state, roomId),
    shallowEqual
  )

  if (!show) {
    return null
  }

  const closeHandler = () =>
    dispatch(uiActions.toggle(Displayable.SidebarEditModal))

  return (
    <Modal
      byline={tr(Label.START_A_SIDEBAR_BYLINE)}
      closeHandler={closeHandler}
      confirmHandler={closeHandler}
      confirmLabel={tr(Label.FINISH_EDITING)}
      confirmTestId={TestId.SidebarModalEditSave}
      show={show}
      showCancelButton={false}
      testId={TestId.SidebarModalEdit}
      title={tr(Label.EDIT_SIDEBAR)}
      TitleIcon={SidebarIcon}
    >
      <div className='flex flex-col'>
        <div className='relative grow focus-within:z-10'>
          {/* TODO: When a method for editing the zone name becomes available */}
          {/* <InputGroup
              autoFocus
              disableAutoComplete
              inputClassName='text-base p-2 focus-within:z-10 focus:rounded-l-md'
              name='group_name'
              onChange={e => setZoneName(e.target.value)}
              required
              testId={TestId.SidebarModalEditTextName}
              title={tr(Label.ENTER_SIDEBAR_NAME)}
              value={zoneName}
            /> */}
          <div className='font-bold text-xl mb-4'>{zoneName}</div>
        </div>
        <div
          className='block-secondary p-4 flex flex-col h-full'
          style={{ height: '40vh' }}
        >
          <SearchParticipantModalWithFilter className='mb-4' />
          <div className='overflow-auto'>
            {participantIds?.map((id, index) => (
              <div key={id} className='flex justify-between'>
                <div className='pb-4 max-w-xs'>
                  <ParticipantData
                    key={`partRow-${id}`}
                    participantId={id}
                    showTalkingIndicator={false}
                  />
                </div>
                <div>
                  {id !== myParticipantId && (
                    <ActionButton
                      currentZoneName={currentZoneName as string}
                      participantId={id}
                      roomId={roomId}
                      testId={`${TestId.SidebarModalEditButton}_${index}`}
                      pendingTestId={`${TestId.SidebarModalEditPending}_${index}`}
                      zoneId={zoneId}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  )
}

type ActionButtonProps = {
  currentZoneName: string
  participantId: string
  pendingTestId: string
  roomId: string
  testId: string
  zoneId: string
}

const ActionButton: FC<ActionButtonProps> = ({
  currentZoneName,
  pendingTestId,
  participantId,
  roomId,
  testId,
  zoneId,
}) => {
  const dispatchRoomCommand = useDispatchRoomCommand()
  const dispatch = useDispatch()
  const alreadyInZoneIds = useSelector(
    (state: CantinaState) =>
      getRoomParticipantIdsByRoomZoneId(state, roomId, zoneId),
    shallowEqual
  )
  const invitedList = useSelector(
    (state: CantinaState) => getZoneEditInvitationList(state),
    shallowEqual
  )
  const isInZone = alreadyInZoneIds.includes(participantId)
  const invited = invitedList.includes(participantId)

  const invite = useCallback(
    (id) => {
      // Send out the invitation
      dispatchRoomCommand({
        action: RoomCommandAction.ZoneInvite,
        participantId: id,
        roomId,
        zoneName: currentZoneName,
      })
      // Keep a record of invitees
      dispatch(roomZoneActions.setZoneEditInvitationList(id))
    },
    [currentZoneName, dispatch, dispatchRoomCommand, roomId]
  )
  const remove = useCallback(
    (id) => {
      dispatchRoomCommand({
        action: RoomCommandAction.ZoneKick,
        participantId: id,
        roomId,
        zoneName: currentZoneName,
      })
    },
    [currentZoneName, dispatchRoomCommand, roomId]
  )

  if (!isInZone && !invited) {
    return (
      <PrimaryButton
        className='w-24 px-0'
        onClick={() => invite(participantId)}
        testId={testId}
      >
        {tr(Label.ADD)}
      </PrimaryButton>
    )
  }

  if (!isInZone && invited) {
    return (
      <div className='py-2' data-test={pendingTestId}>
        {tr(Label.PENDING_INVITE)}
      </div>
    )
  }

  if (isInZone) {
    return (
      <SecondaryButton
        className='w-24 px-0'
        onClick={() => remove(participantId)}
        testId={testId}
        transparent
      >
        {tr(Label.REMOVE)}
      </SecondaryButton>
    )
  }
  return null
}

export default compose<any>(
  withSidebarConversationsFeatureFlag(),
  withConferenceZoneManageScope
)(EditSideBarModal)
