import React, { FC, useEffect, useState } from 'react'
import { compose } from 'redux'
import { useDispatch } from 'react-redux'
import { MicrophoneOffIcon, MicrophoneOnIcon } from 'common/icons'
import {
  stopLocalAudioTrack,
  restoreLocalAudioTrack,
} from 'common/redux/features'
import { withActiveCall } from 'common/hoc'
import { withInCallButtonWrapper } from './withInCallButtonWrapper'
import { withTooltipWrapper } from 'src/components/Tooltip'
import { AudioSettings } from 'src/components/Devices/AudioSettings'

type LocalAudioTrackButtonProps = {
  callId: string
  label?: string
  offLabel?: string
}
const LocalAudioTrackButton: FC<LocalAudioTrackButtonProps> = ({ callId }) => {
  const dispatch = useDispatch()
  const [muted, setMuted] = useState(false)
  const className = !muted ? 'active' : 'disabled'

  useEffect(() => {
    const action = muted
      ? stopLocalAudioTrack(callId)
      : restoreLocalAudioTrack(callId)
    dispatch(action)
  }, [dispatch, muted, callId])

  const clickHandler = () => setMuted(!muted)

  return (
    <button
      className={`sw-btn-icon-toggle ${className}`}
      onClick={clickHandler}
    >
      {muted ? (
        <MicrophoneOffIcon size='lg' fixedWidth />
      ) : (
        <MicrophoneOnIcon size='lg' fixedWidth />
      )}
    </button>
  )
}

// Do not check scopes here
export const InCallLocalAudioTrackButton = compose<
  FC<Partial<LocalAudioTrackButtonProps>>
>(
  withTooltipWrapper(AudioSettings),
  withInCallButtonWrapper,
  withActiveCall
)(LocalAudioTrackButton)
