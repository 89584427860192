import React, { FC, useEffect, useState } from 'react'
import { compose } from 'redux'
import { useDispatch } from 'react-redux'
import { CameraOffIcon, CameraOnIcon } from 'common/icons'
import { withActiveCall } from 'common/hoc'
import {
  stopLocalVideoTrack,
  restoreLocalVideoTrack,
} from 'common/redux/features'
import { withInCallButtonWrapper } from './withInCallButtonWrapper'
import { withTooltipWrapper } from 'src/components/Tooltip'
import { VideoSettings } from 'src/components/Devices/VideoSettings'

type LocalVideoTrackButtonProps = {
  callId: string
  label?: string
  offLabel?: string
}
const LocalVideoTrackButton: FC<LocalVideoTrackButtonProps> = ({ callId }) => {
  const dispatch = useDispatch()
  const [muted, setMuted] = useState(false)
  const className = !muted ? 'active' : 'disabled'

  useEffect(() => {
    const action = muted
      ? stopLocalVideoTrack(callId)
      : restoreLocalVideoTrack(callId)
    dispatch(action)
  }, [dispatch, muted, callId])

  const clickHandler = () => setMuted(!muted)

  return (
    <button
      className={`sw-btn-icon-toggle ${className}`}
      onClick={clickHandler}
    >
      {muted ? (
        <CameraOffIcon size='lg' fixedWidth />
      ) : (
        <CameraOnIcon size='lg' fixedWidth />
      )}
    </button>
  )
}

// Do not check scopes here
export const InCallLocalVideoTrackButton = compose<
  FC<Partial<LocalVideoTrackButtonProps>>
>(
  withTooltipWrapper(VideoSettings),
  withInCallButtonWrapper,
  withActiveCall
)(LocalVideoTrackButton)
