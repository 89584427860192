import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link, LinkProps } from 'react-router-dom'
import { getActiveCallId } from 'common/redux/features/calls/callSelectors'

export const CantinaLink: FC<LinkProps> = ({ children, ...props }) => {
  const callId = useSelector(getActiveCallId)
  const target = callId ? '_blank' : '_self'

  return (
    <Link target={target} {...props}>
      {children}
    </Link>
  )
}
