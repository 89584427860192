import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MixPanelEvent } from 'common/constants'
import { useProductLine } from 'common/hooks'
import { mixPanelTrackAction } from 'common/redux/actions'
import { getSignInNotificationBody } from 'common/redux/features/settings/settingsSelectors'
import { capitalize } from 'common/services/helpers'
import { tr, Label } from 'common/i18n'
import { PrimaryButton } from 'src/components/Button/Button'
import { TestId } from 'src/constants'
import { Modal } from 'src/modals/Modal'

const NotificationModal = ({
  closeHandler,
  content,
  agreeHandler,
  show = false,
}: any) => (
  <Modal
    closeHandler={closeHandler}
    show={show}
    showCancelButton={false}
    showConfirmButton={false}
    testId={TestId.NotificationModal}
    title={tr(Label.NOTICE)}
  >
    <p
      className='text-sm text-justify text-contrast-m'
      dangerouslySetInnerHTML={{ __html: content }}
    ></p>
    <hr className='my-5' />
    <PrimaryButton
      className='w-full'
      onClick={agreeHandler}
      testId={TestId.NotificationModalButtonConfirm}
    >
      {tr(Label.AGREE)}
    </PrimaryButton>
  </Modal>
)

export const SigninNotification = ({ refPage }: any) => {
  const dispatch = useDispatch()
  const content = useSelector(getSignInNotificationBody)
  const productLine = useProductLine()
  const [show, setShow] = useState(false)

  if (!content) {
    return null
  }

  const agreeHandler = () => {
    if (show) {
      // Send mixPanel event on modal close
      dispatch(
        mixPanelTrackAction({
          event: MixPanelEvent.SigninNotificationAgree,
          params: { refPage },
        })
      )
    }
    setShow(!show)
  }

  const showHandler = () => {
    setShow(!show)
  }

  return (
    <>
      <button
        className='text-xs text-contrast-m cursor-pointer underline'
        onClick={showHandler}
      >
        A Notice From Your {capitalize(productLine)} Administrators
      </button>
      <NotificationModal
        content={content}
        agreeHandler={agreeHandler}
        closeHandler={showHandler}
        show={show}
      />
    </>
  )
}
