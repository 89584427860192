import React, { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { SpinnerIcon } from 'common/icons'
import { Endpoint, UserLookupResponse } from 'common/rest'
import { RoutePath, AuthProvider } from 'common/constants'
import { tr, Label } from 'common/i18n'
import { useCantinaBackendAPI } from 'common/hooks'
import { SAML_AUTH_PATH, TestId } from 'src/constants'
import { InputGroup } from 'src/components/InputGroup/InputGroup'
import { useInputChange } from 'src/effects/useInputChange'
import { PrimaryButton } from 'src/components/Button/Button'

const userLookupOptions = {
  endpoint: Endpoint.UsersLookup,
  method: 'GET',
}

type UserLookupProps = {
  cantinaId: string
}

export const UserLookup: FC<UserLookupProps> = ({ cantinaId }) => {
  const { invokeAPI: userLookupHandler } =
    useCantinaBackendAPI(userLookupOptions)
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useInputChange({
    email: '',
  })
  const [error, setError] = useState('')
  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (values.email) {
      try {
        setLoading(true)
        setError('')
        const email = encodeURIComponent(values.email)
        const response: UserLookupResponse = await userLookupHandler({
          urlTransform: (url) => {
            return `${url}?cantinaId=${cantinaId}&email=${email}`
          },
        })

        // This is a guest
        if (!response.user_exists) {
          const authSettings = response?.authorization_settings
          const numAuthSettings =
            (authSettings && Object.keys(authSettings)?.length) ?? 0

          // If authorization_settings is empty it means this instance
          // does not support any other authorization (guest or saml)
          if (!authSettings || !numAuthSettings) {
            setLoading(false)
            setError('Unknown email')
            return
          }
          response.email = values.email

          // If a guest's only avaialble authorization_settings option
          // is saml, send them directly to the saml login route
          if (numAuthSettings === 1 && authSettings?.[AuthProvider.Saml]) {
            return window.location.replace(SAML_AUTH_PATH)
          }

          // Send all other guest to the GuestProvider, which will
          // display the available methods
          return history.push(RoutePath.LoginGuest, response)
        }

        // Check available auth methods for an existing user
        switch (response?.auth_provider) {
          case AuthProvider.Local:
            return history.push(RoutePath.LoginLocal, response)
          case AuthProvider.Saml:
            return window.location.replace(SAML_AUTH_PATH)
          default:
            return console.warn('Unknown auth provider', response)
        }
      } catch (error) {
        setLoading(false)
        setError('Unknown email')
      }
    }
    return false
  }

  return (
    <>
      <div className='text-center text-contrast-h text-4xl font-neue'>
        {tr(Label.WELCOME)}
      </div>
      <div className='text-sm text-contrast-m pb-4 text-center'>
        {tr(Label.ENTER_EMAIL)}
      </div>
      <form
        onSubmit={submitHandler}
        autoComplete='off'
        data-test={TestId.LoginForm}
      >
        <InputGroup
          autoFocus
          error={error}
          label={tr(Label.EMAIL)}
          name='email'
          onChange={setValues}
          required
          type='email'
          testId={TestId.LoginInputEmail}
        />
        <PrimaryButton
          className='w-full mb-2 px-16'
          disabled={loading}
          testId={TestId.LoginButtonContinue}
          type='submit'
        >
          {loading ? <SpinnerIcon /> : tr(Label.WELCOME)}
        </PrimaryButton>
      </form>
    </>
  )
}
