import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { callViewActions } from 'common/redux/views'
import { CantinaState } from 'common/redux/interfaces'
import { isPushToTalkEnabled } from 'common/redux/views/callView/callViewSelectors'
import { hasSilentMode as hasSilentModeSelector } from 'common/redux/features/rooms/roomsSelectors'
import {
  getActiveCallRoomId,
  getActiveCallId,
  getMyParticipantId,
} from 'common/redux/features/calls/callSelectors'
import HorizontalFeatureToggle from 'src/components/Inputs/HorizontalFeatureToggle'
import { callDTMF } from 'common/redux/features'
import { isMuted } from 'common/redux/features/participants/participantSelectors'
import { TestId } from 'src/constants'

export const PushToTalkToggle = () => {
  const dispatch = useDispatch()
  const active = useSelector((state: CantinaState) =>
    isPushToTalkEnabled(state)
  )
  const callId = useSelector(getActiveCallId)
  const activeRoomId = useSelector(getActiveCallRoomId)
  const participantId = useSelector(getMyParticipantId)
  const hasSilentMode = useSelector((state: CantinaState) =>
    hasSilentModeSelector(state, activeRoomId)
  )
  const muted = useSelector((state: CantinaState) =>
    isMuted(state, participantId)
  )

  if (hasSilentMode) {
    return null
  }

  const toggleOn = () => {
    if (muted) {
      dispatch(callDTMF(callId, '#71'))
    }
    dispatch(callViewActions.enablePtt())
  }

  const toggleOff = () => dispatch(callViewActions.disablePtt())

  return (
    <HorizontalFeatureToggle
      onToggleOn={toggleOn}
      onToggleOff={toggleOff}
      label='Enable Push to Talk'
      checked={active}
      className='text-sm p-2 pl-6'
      testId={TestId.AudioTogglePTT}
    />
  )
}
