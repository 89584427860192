import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { PURGE } from 'redux-persist'
import { authActions } from 'common/redux/features'
import { useAutoLogin } from 'src/effects/useAutoLogin'
import { Loading } from 'src/App'

const noop = () => {}
const Impersonate: FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: PURGE, result: noop })
    dispatch(authActions.temporary())
  }, [dispatch])

  useAutoLogin()

  return <Loading />
}

export default Impersonate
