import React, { FC, useState } from 'react'
import { useInputChange } from 'src/effects/useInputChange'
import SubmittableInput from 'src/components/Inputs/SubmittableInput'
import { withConferenceInviteByCallScope } from 'common/hoc'
import { Endpoint } from 'common/rest'
import { useCantinaBackendAPI, useCantinaId } from 'common/hooks'
import { tr, Label } from 'common/i18n'
import InviteOption from './InviteOption'

const outboundCallOptions = {
  withAuth: true,
  endpoint: Endpoint.OutboundCall,
  method: 'POST',
}

interface ByCallProps {
  roomId: string
}

const ByCall: FC<ByCallProps> = ({ roomId }) => {
  const [values, setValues, bulkUpdate] = useInputChange({ inviteByCall: '' })
  const { invokeAPI: invitationHandler } =
    useCantinaBackendAPI(outboundCallOptions)
  const [error, setError] = useState('')

  const cantinaId = useCantinaId()

  const inviteByCallHandler = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()
    if (values.inviteByCall) {
      try {
        setError('')
        await invitationHandler({
          urlTransform: (url) => {
            // FIXME: remove `cantinaId` replace
            return url
              .replace(':cantinaId', cantinaId)
              .replace(':roomId', roomId)
          },
          body: JSON.stringify({ toNumber: values.inviteByCall }),
        })

        bulkUpdate({ inviteByCall: '' })
      } catch (error) {
        setError(tr(Label.INVITE_BY_CALL_ERROR))
      }
    }
  }

  return (
    <InviteOption
      description={tr(Label.INVITE_BY_CALL_DESCRIPTION)}
      label={tr(Label.INVITE_BY_CALL)}
    >
      <SubmittableInput
        className='text-sm'
        currentValue={values.inviteByCall}
        inputChanged={setValues}
        name='inviteByCall'
        placeholder='e.g. +15551231234'
        submitButtonLabel={tr(Label.SEND)}
        submitHandler={inviteByCallHandler}
        type='text'
        error={error}
      />
    </InviteOption>
  )
}

export default withConferenceInviteByCallScope(ByCall)
