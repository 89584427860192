import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { RepeatIcon } from 'common/icons'
import { uiActions } from 'common/redux/features'
import { tr, Label } from 'common/i18n'
import { withTextTooltip } from 'src/hoc/withTextTooltip'

type ButtonProps = {}

const Button: FC<ButtonProps> = () => {
  const dispatch = useDispatch()
  const clickHandler = () => dispatch(uiActions.toggleFlipLocalVideo())

  return (
    <button aria-label={tr(Label.MIRROR_VIDEO)} onClick={clickHandler}>
      <RepeatIcon size='lg' fixedWidth />
    </button>
  )
}

export const FlipLocalVideoButton = withTextTooltip()(Button)
