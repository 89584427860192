import React, { FC } from 'react'
import { Link } from 'react-router-dom'
// import { useHistory } from 'react-router'
import { tr, Label } from 'common/i18n'
import { RoutePath } from 'common/constants'
import { ChevronRightIcon, TimesCircleIcon, DownloadIcon } from 'common/icons'
import { useFetchRoom, useFetchRoomRecordings } from 'common/hooks'
import { Recording } from 'common/rest'
import { durationBetweenDates } from 'src/services/Helpers'
import { useDecodedParams } from 'src/effects/useDecodedParams'
import { RequestStateSwitcher } from '../RequestStateSwitcher'
// import { canManageRoom } from '../utils'
import {
  ButtonWithTooltip,
  ButtonWithConfirmation,
} from 'src/components/Button/Button'

export const RoomRecordingDetail: FC = () => {
  // const history = useHistory()
  const { roomId } = useDecodedParams<{ roomId: string }>()
  const { room } = useFetchRoom(roomId)
  const {
    recordings,
    requestState,
    error,
    refresh,
    deleteHandler,
    getDownloadURI,
  } = useFetchRoomRecordings(roomId)

  // useEffect(() => {
  //   if (room) {
  //     const hasPermission = canManageRoom(room?.my_roles)
  //     if (!hasPermission) {
  //       history.push(RoutePath.SettingsRecordingList)
  //     }
  //   }
  // }, [history, room])

  return (
    <>
      <div className='administration-section-title'>
        <div className='text-contrast-h text-xl'>
          <Link
            to={RoutePath.SettingsRecordingList}
            title={tr(Label.RECORDINGS)}
            className='pr-2'
          >
            {tr(Label.RECORDINGS)}
          </Link>
          <ChevronRightIcon size='sm' />{' '}
          <span className='font-bold'>{room?.name}</span>
        </div>
      </div>
      <div className='block-primary administration-section-content'>
        <RequestStateSwitcher
          requestState={requestState}
          refresh={refresh}
          error={error}
        >
          {!recordings?.length && (
            <p className='text-base py-2'>{tr(Label.RECORDINGS_NO_RESULTS)}</p>
          )}
          <div className='divide-y divide-contrast-l'>
            {recordings?.map((recording) => {
              return (
                <RecordingItem
                  key={recording.id}
                  recording={recording}
                  roomName={room?.name}
                  deleteHandler={deleteHandler}
                  getDownloadURI={getDownloadURI}
                />
              )
            })}
          </div>
        </RequestStateSwitcher>
      </div>
    </>
  )
}

type RecordingItemProps = {
  roomName?: string
  recording: Recording
  deleteHandler: (id: string) => void
  getDownloadURI: (id: string) => Promise<string>
}
const RecordingItem: FC<RecordingItemProps> = ({
  roomName = 'Room',
  recording,
  deleteHandler,
  getDownloadURI,
}) => {
  const {
    id,
    recording_format,
    created_at,
    recording_started_at,
    recording_finished_at,
  } = recording

  const duration = durationBetweenDates(
    new Date(recording_finished_at),
    new Date(recording_started_at)
  )
  const downloadHandler = async () => {
    const uri = await getDownloadURI(id)
    if (uri) {
      window.open(uri, '_blank')
    }
  }

  return (
    <div
      className='flex items-center justify-between p-3 pt-6'
      aria-label={tr(Label.RECORDINGS)}
    >
      <div>
        <p className='text-base text-contrast-h font-bold'>{`${roomName} Recording`}</p>
        <div className='text-xs'>
          <p>
            <span className='font-bold'>{`${tr(Label.DURATION)}: `}</span>
            {duration}
          </p>
          <p>
            <span className='font-bold'>{`${tr(Label.DATE)}: `}</span>
            {created_at && new Date(created_at).toLocaleString()}
          </p>
          <p>
            <span className='font-bold'>{`${tr(Label.FORMAT)}: `}</span>
            {recording_format}
          </p>
        </div>
      </div>
      <div className='flex items-center gap-3'>
        <ButtonWithTooltip
          ariaLabel={tr(Label.DOWNLOAD)}
          onClick={() => downloadHandler()}
        >
          <DownloadIcon fixedWidth />
        </ButtonWithTooltip>
        <ButtonWithConfirmation
          ariaLabel={tr(Label.REMOVE)}
          className='text-sw-red'
          onClick={() => deleteHandler(id)}
          singleUse
        >
          <TimesCircleIcon />
        </ButtonWithConfirmation>
      </div>
    </div>
  )
}
