import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { CantinaState } from 'common/redux/interfaces'
import { RoomCommandAction } from 'common/constants'
import { KeyIcon } from 'common/icons'
import { useDispatchRoomParticipantCommand } from 'common/hooks'
import {
  isMyself,
  isModerator,
} from 'common/redux/features/participants/participantSelectors'
import { Label } from 'common/i18n'
import { TestId } from 'src/constants'
import { ButtonFeatureToggle } from 'src/components/Inputs/ButtonFeatureToggle'

type GrantModeratorToggleProps = {
  participantId: string
  className?: string
}

export const GrantModeratorToggle: FC<GrantModeratorToggleProps> = ({
  participantId,
}) => {
  const dispatchRoomParticipantCommand =
    useDispatchRoomParticipantCommand(participantId)
  const myself = useSelector((state: CantinaState) =>
    isMyself(state, participantId)
  )
  const isMod = useSelector((state: CantinaState) =>
    isModerator(state, participantId)
  )
  if (myself) {
    return null
  }

  return (
    <ButtonFeatureToggle
      checked={isMod}
      Icon={KeyIcon}
      label={Label.GRANT_MODERATOR}
      onToggleOn={() => {
        dispatchRoomParticipantCommand({
          action: RoomCommandAction.GrantModerator,
          participantId,
        })
      }}
      onToggleOff={() => {
        dispatchRoomParticipantCommand({
          action: RoomCommandAction.RevokeModerator,
          participantId,
        })
      }}
      testId={TestId.ParticipantToggleGrantModerator}
    />
  )
}
