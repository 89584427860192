import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
  getAvatar,
  getPublicProfile,
  hasMissingProfileInfo,
} from 'common/redux/features/auth/authSelectors'
import { isProfileModalVisible } from 'common/redux/features/ui/uiSelectors'
import {
  getCustomCompanyLabel,
  getCustomNameLabel,
} from 'common/redux/features/settings/settingsSelectors'
import { uiActions, updateProfileRequest } from 'common/redux/features'
import { withAuth } from 'common/hoc'
import { tr, Label } from 'common/i18n'
import { Displayable } from 'common/constants'
import { TestId } from 'src/constants'
import { Avatar } from 'src/components/Images/Avatar'
import { InputGroup } from 'src/components/InputGroup/InputGroup'
import { useInputChange } from 'src/effects/useInputChange'
import { Modal } from './Modal'

const ProfileAvatar = () => {
  const avatar = useSelector(getAvatar)
  return (
    <Avatar
      className='rounded-full mx-auto'
      src={avatar}
      alt={tr(Label.PROFILE_IMAGE)}
    />
  )
}

type ProfileModalContentProps = {
  showAvatar: boolean
  title: string
}

const ProfileModalContent: FC<ProfileModalContentProps> = ({
  showAvatar,
  title,
}) => {
  const dispatch = useDispatch()

  const nameFieldLabel = useSelector(getCustomNameLabel)
  const companyFieldLabel = useSelector(getCustomCompanyLabel)
  const profile = useSelector(getPublicProfile, shallowEqual)

  const [values, setValues] = useInputChange(profile)

  const closeHandler = useCallback(() => {
    dispatch(uiActions.hide(Displayable.ProfileModal))
  }, [dispatch])

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const { name, phone, company } = values

    if (name?.trim()) {
      dispatch(updateProfileRequest({ name, phone, company }))
      closeHandler()
    }

    return false
  }

  return (
    <Modal
      closeHandler={closeHandler}
      confirmHandler={submitHandler}
      confirmLabel={tr(Label.UPDATE_PROFILE)}
      disabled={!values.name.trim()}
      isForm
      show
      testId={TestId.ProfileForm}
      title={tr(title)}
    >
      {showAvatar && <ProfileAvatar />}
      <div className='p-2 font-semibold text-center'>{values.email}</div>
      <InputGroup
        label={nameFieldLabel}
        name='name'
        onChange={setValues}
        required
        value={values.name}
      />
      <InputGroup
        label={`${tr(Label.PHONE)}`}
        name='phone'
        onChange={setValues}
        type='tel'
        value={values.phone}
      />
      <InputGroup
        label={`${companyFieldLabel}`}
        name='company'
        onChange={setValues}
        value={values.company}
      />
    </Modal>
  )
}

export const ProfileModal: FC = withAuth(() => {
  const hasMissingInfo = useSelector(hasMissingProfileInfo)
  const hasOpenedModal = useSelector(isProfileModalVisible)

  const show = hasMissingInfo || hasOpenedModal
  const title = hasMissingInfo ? Label.COMPLETE_YOUR_PROFILE : Label.MY_PROFILE

  return show ? (
    <ProfileModalContent title={title} showAvatar={!hasMissingInfo} />
  ) : null
})
