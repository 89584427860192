import React, { FC } from 'react'
import { compose } from 'redux'
import { useSelector } from 'react-redux'
import { CantinaState } from 'common/redux/interfaces'
import {
  hasHandRaised,
  isDeaf,
} from 'common/redux/features/participants/participantSelectors'
import {
  withCurrentParticipant,
  withActiveCall,
  withHideRaiseHandFeatureFlag,
} from 'common/hoc'
import { Scope, RoomCommandAction } from 'common/constants'
import { RaiseHandIcon } from 'common/icons'
import { tr, Label } from 'common/i18n'
import { TestId } from 'src/constants'
import { TOOLTIP_OFFSET_Y_BOTTOM_BAR } from 'src/hoc/withTextTooltip'
import {
  ParticipantCommandButton,
  ParticipantButtonProps,
  InCallParticipantButtonProps,
} from './index'

type ParticipantHandButtonProps = {
  lowerOnly?: boolean
  showDNDLabel?: boolean
} & ParticipantButtonProps

const ParticipantHandButton = compose<FC<ParticipantHandButtonProps>>(
  withHideRaiseHandFeatureFlag({ show: false })
)(
  ({
    lowerOnly = false,
    participantId,
    scope,
    showDNDLabel = true,
    ...rest
  }: ParticipantHandButtonProps) => {
    let buttonActivated = false
    let buttonScope = scope ?? Scope.ConferenceRaiseHand
    let buttonLabel = tr(Label.RAISE_HAND)
    const buttonAction = {
      participantId,
      action: RoomCommandAction.RaiseHand,
    }

    const handRaised = useSelector((state: CantinaState) =>
      hasHandRaised(state, participantId)
    )

    const dndEnabled = useSelector((state: CantinaState) =>
      isDeaf(state, participantId)
    )

    // Don't show button to raise hand if lowerOnly
    if (!handRaised && lowerOnly) {
      return null
    }

    if (handRaised) {
      buttonActivated = true
      buttonScope = scope ?? Scope.ConferenceLowerHand
      buttonLabel = tr(Label.LOWER_HAND)
      buttonAction.action = RoomCommandAction.LowerHand
    }

    if (dndEnabled && showDNDLabel) {
      buttonLabel = tr(Label.DND_UNAVAILABLE)
    }

    return (
      <ParticipantCommandButton
        action={buttonAction}
        activated={buttonActivated}
        disabled={dndEnabled}
        Icon={RaiseHandIcon}
        label={buttonLabel}
        participantId={participantId}
        scope={buttonScope}
        {...rest}
      />
    )
  }
)

// The InCall version is used in the bottom bar and includes tooltip overrides
const InCallParticipantHandButton = compose<FC<InCallParticipantButtonProps>>(
  withCurrentParticipant,
  withActiveCall
)((props: ParticipantHandButtonProps) => (
  <ParticipantHandButton
    scope={Scope.ConferenceRaiseHandSelf}
    showDNDLabel={false}
    testId={TestId.BottomBarButtonRaiseHand}
    offsetY={TOOLTIP_OFFSET_Y_BOTTOM_BAR}
    titleSecondary={props.disabled ? '' : 'H'}
    {...props}
  />
))

export { ParticipantHandButton, InCallParticipantHandButton }
