export const SEARCH_DEBOUNCE_DELAY = 300
export const SAML_AUTH_PATH = '/sso/saml2'

export enum Size {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  ExtraLarge = 'xlarge',
}

export enum TestId {
  // Forms
  LoginGuestForm = 'login_guest_form',
  LoginGuestInputUsername = 'login_guest_input_username',
  LoginGuestInputCompany = 'login_guest_input_company',
  LoginGuestInputEmail = 'login_guest_input_email',
  LoginGuestButtonLogin = 'login_guest_button_join',

  LoginRegisteredForm = 'login_registered_form',
  LoginRegisteredInputUsername = 'login_registered_input_username',
  LoginRegisteredInputPassword = 'login_registered_input_password',
  LoginRegisteredButtonLogin = 'login_registered_button_login',
  LoginRegisteredButtonForgot = 'login_registered_button_forgot',
  LoginButtonNotYou = 'login_button_notYou',

  ResetPasswordForm = 'reset_password_form',
  ResetPasswordInputEmail = 'reset_password_input_email',
  ResetPasswordButtonReset = 'reset_password_button_reset',
  ResetPasswordButtonCancel = 'reset_password_button_cancel',

  SetNewPasswordForm = 'set_new_password_form',
  SetPasswordForm = 'set_password_form',
  SetPasswordButtonReset = 'set_password_button_reset',
  SetPasswordButtonSubmit = 'set_password_button_submit',
  SetPasswordInputPassword = 'set_password_input_password',
  SetPasswordInputConfirm = 'set_password_input_confirm',

  GlobalPreferencesForm = 'global_preferences_form',
  ProfileForm = 'profile_form',
  RoomForm = 'room_form',
  UserForm = 'user_form',
  ChatForm = 'chat_form',

  LoginForm = 'login_form',
  LoginInputEmail = 'login_input_email',
  LoginButtonSaml = 'login_button_saml',
  LoginButtonContinue = 'login_button_continue',

  AutoCompleteCounter = 'autocomplete_counter',
  AutoCompleteAccessGrant = 'autocomplete_access_grant',
  AutoCompleteGroup = 'autocomplete_group',
  AutoCompleteUser = 'autocomplete_user',

  ClipeezeButtonUpload = 'clipeeze_button_upload',
  ClipeezeButtonCancel = 'clipeeze_button_cancel',

  // Common Links
  PrivacyPolicyLink = 'privacyPolicy_link',

  // Controls
  HomeHeaderRoom = 'home_header_room',
  HomeRoomGridFiltered = 'home_roomGrid_filtered',
  HomeRoomGridPinned = 'home_roomGrid_pinned',
  HomeRoomGridUnpinned = 'home_roomGrid_unpinned',
  HomeInputRoomNavFilter = 'home_input_roomNavFilter',
  LobbyGridViewContainer = 'lobby_grid_view_container',

  TopBarButtonRightSideMenu = 'topBar_button_rightSideMenu',
  TopBarButtonHelp = 'topBar_button_help',
  TopBarButtonNotices = 'topBar_button_notices',
  TopBarButtonGridView = 'topBar_button_gridView',
  TopBarButtonInvite = 'topBar_button_invite',
  TopBarButtonClipeeze = 'topBar_button_clipeeze',
  TopBarButtonParticipantList = 'topBar_button_participantList',
  TopBarButtonRoomNav = 'topBar_button_roomNav',
  TopBarButtonFilter = 'topBar_button_filter',

  BottomBarContainer = 'bottomBar_container',
  BottomBarMoreContainer = 'bottomBar_moreContainer',
  BottomBarButtonPTT = 'bottomBar_button_ptt',
  BottomBarButtonAudio = 'bottomBar_button_audio',
  BottomBarButtonAudioTooltip = 'bottomBar_button_audioTooltip',
  BottomBarButtonVideo = 'bottomBar_button_video',
  BottomBarButtonVideoTooltip = 'bottomBar_button_videoTooltip',
  BottomBarButtonVideoSettings = 'bottomBar_button_videoSettings',
  BottomBarButtonMore = 'bottomBar_button_more',
  BottomBarButtonMoreOptions = 'bottomBar_button_moreOptions',
  BottomBarButtonShare = 'bottomBar_button_share',
  BottomBarButtonShareTooltip = 'bottomBar_button_shareTooltip',
  BottomBarButtonRaiseHand = 'bottomBar_button_raiseHand',
  BottomBarButtonDnd = 'bottomBar_button_dnd',
  BottomBarButtonChat = 'bottomBar_button_chat',
  BottomBarButtonHangUp = 'bottomBar_button_hangUp',
  BottomBarButtonHangUpCaret = 'bottomBar_button_hangUp_caret',
  BottomBarVideoSettings = 'bottomBar_div_video-settings',
  BottomBarMicrophoneList = 'bottomBar_div_microphone-list',

  // Sidebars
  SidebarControlsEndForAllButton = 'sidebar_controls_end-for-all-button',
  SidebarControlsLeaveButton = 'sidebar_controls_leave-button',
  SidebarExtVolSlider = 'sidebar_controls_slider_external-volume',
  SidebarIncallButton = 'sidebar_incall_bottom_bar_button',
  SidebarJoinButton = 'sidebar_join_button',
  SidebarModalCreate = 'sidebar_modal_create',
  SidebarModalCreateCheckbox = 'sidebar_modal_create_checkbox',
  SidebarModalCreateCancel = 'sidebar_modal_create_btn_bottom_cancel',
  SidebarModalCreateDivParticipantCounter = 'sidebar_modal_create_div_participant-counter',
  SidebarModalCreateStart = 'sidebar_modal_create_btn_bottom_start',
  SidebarModalCreateTextName = 'sidebar_modal_create_txt_name',
  SidebarModalEdit = 'sidebar_modal_edit',
  SidebarModalEditButton = 'sidebar_modal_edit_button',
  SidebarModalEditPending = 'sidebar_modal_edit_pending',
  SidebarModalEditDivParticipantCounter = 'sidebar_modal_edit_div_participant-counter',
  SidebarModalEditSave = 'sidebar_modal_edit_btn_bottom_save',
  SidebarModalEditTextName = 'sidebar_modal_edit_txt_name',
  SidebarModalInvitation = 'sidebar_modal_invitation',
  SidebarModalInvitationConfirmButton = 'sidebar_modal_invitation-confirm',
  SidebarModalInvitationDeclineButton = 'sidebar_modal_invitation-decline',
  SidebarModalRequestToJoin = 'sidebar_modal_join_request',
  SidebarModalRequestToJoinConfirmButton = 'sidebar_modal_join_request-confirm',
  SidebarModalRequestToJoinDeclineButton = 'sidebar_modal_join_request-decline',

  // App Menu
  AppMenuButtonHome = 'appMenu_button_home',
  AppMenuButtonAccount = 'appMenu_button_account',
  AppMenuButtonSettings = 'appMenu_button_settings',
  AppMenuButtonDevices = 'appMenu_button_devices',
  AppMenuButtonAbout = 'appMenu_button_about',
  AppMenuToggleTheme = 'appMenu_toggle_theme',
  AppMenuButtonHelp = 'appMenu_button_help',
  AppMenuButtonLogout = 'appMenu_button_logout',

  // Right drawer
  DrawerButtonClose = 'drawer_button_close',
  DrawerTitleChat = 'drawer_title_chat',
  DrawerTitleClipeeze = 'drawer_title_clipeeze',
  DrawerTitleParticipantList = 'drawer_title_participant_list',
  DrawerTitleRoomNavigtor = 'drawer_title_room_navigator',

  // Icons
  HigherIcon = 'global_icon_higher_range',
  LowerIcon = 'global_icon_lower_range',

  ChatMessage = 'chat_message',
  ChatInputMessage = 'chat_input_message',
  ChatButtonSubmit = 'chat_button_submit',

  ClipeezeButtonClearFilter = 'clipeeze_button_clearFilter',
  ClipeezeClip = 'clipeeze_clip',
  ClipeezeInputSearch = 'clipeeze_input_search',
  ClipeezeToggleFullScreen = 'clipeeze_toggle_fullScreen',
  ClipeezeToggleLoop = 'clipeeze_toggle_loop',

  ParticipantsButtonClearFilter = 'participants_button_clearFilter',
  ParticipantsButtonShowGroupBy = 'participants_button_showGroupBy',
  ParticipantsGroupEdit = 'participantsGroup_edit',
  ParticipantsGroupHandsRaised = 'participantsGroup_handsRaised',
  ParticipantsGroupModerator = 'participantsGroup_moderator',
  ParticipantsGroupName = 'participantsGroup_name',
  ParticipantsGroupRolesAssigned = 'participantsGroup_rolesAssigned',
  ParticipantsGroupTalking = 'participantsGroup_talking',
  ParticipantsGroupToggleHandsRaised = 'participantsGroup_toggle_handsRaised',
  ParticipantsGroupToggleModerator = 'participantsGroup_toggle_moderator',
  ParticipantsGroupToggleRolesAssigned = 'participantsGroup_toggle_rolesAssigned',
  ParticipantsGroupToggleShow = 'participantsGroup_toggle_show',
  ParticipantsGroupToggleTalking = 'participantsGroup_toggle_talking',
  ParticipantsGroupZone = 'participantsGroup_zone',
  ParticipantsInputSearch = 'participants_input_search',

  ParticipantDetails = 'participant_details',
  ParticipantButtonClose = 'participant_button_close',
  ParticipantButtonVideo = 'participant_button_video',
  ParticipantButtonAudio = 'participant_button_audio',
  ParticipantButtonHand = 'participant_button_hand',
  ParticipantButtonDnd = 'participant_button_dnd',
  ParticipantToggleView = 'participant_toggle_view',
  ParticipantToggleModal = 'participant_toggle_modal',
  ParticipantButtonKick = 'participant_button_kick',
  ParticipantToggleDisableAutoRole = 'participant_toggle_disableAutoRole',
  ParticipantToggleGrantModerator = 'participant_toggle_grantModerator',
  ParticipantToggleGrantScreenShare = 'participant_toggle_grantScreenShare',
  ParticipantToggleBanner = 'participant_toggle_banner',
  ParticipantButtonClearBanner = 'participant_button_clearBanner',
  ParticipantInputBannerText = 'participant_input_bannerText',
  ParticipantInputTextToSpeech = 'participant_input_textToSpeech',
  ParticipantOptions = 'participant_options',

  RoomNavigatorRoom = 'roomNavigator_room',
  RoomNavigatorInputSearch = 'roomNavigator_input_search',
  RoomNavigatorButtonJoin = 'roomNavigator_button_join',
  RoomNavigatorButtonTransfer = 'roomNavigator_button_transfer',
  RoomNavigatorButtonCancel = 'roomNavigator_button_cancel',
  RoomNavigatorButtonParticipants = 'roomNavigator_button_participants',
  RoomNavigatorButtonCompleteTransfer = 'roomNavigator_button_completeTransfer',

  RoomPreviewButtonView = 'roomPreview_button_view',
  RoomPreviewButtonJoin = 'roomPreview_button_join',

  AudioButtonMicrophone = 'audio_button_microphone',
  AudioButtonSpeakers = 'audio_button_speakers',
  AudioToggleDenoise = 'audio_toggle_denoise',
  AudioTogglePTT = 'audio_toggle_ptt',
  AudioToggleEchoCancellation = 'audio_toggle_echoCancellation',
  AudioToggleNoiseSuppression = 'audio_toggle_noiseSuppression',
  AudioToggleAutoGain = 'audio_toggle_autoGain',
  AudioToggleLowBitrate = 'audio_toggle_lowBitrate',

  VideoButtonCamera = 'video_button_camera',
  VideoToggleMirror = 'video_toggle_mirror',
  VideoToggleLowBitrate = 'video_toggle_lowBitrate',
  VideoToggleDisplayBanner = 'video_toggle_displayBanner',
  VideoButtonPictureInPicture = 'video_button_pictureInPicture',
  VideoIconPictureInPicture = 'video_icon_pictureInPicture',
  VideoButtonFullscreen = 'video_button_fullscreen',
  VideoIconFullscreen = 'video_icon_fullscreen',
  VideoButtonFullscreenVideo = 'video_icon_fullscreenVideo',
  VideoIconFullscreenVideo = 'video_icon_fullscreenVideo',
  MCUVideoSettings = 'mcu_div_videoSettings',

  VideoDevicesButtonAdd = 'videoDevices_button_add',
  VideoDevicesButtonRemove = 'videoDevices_button_remove',

  RoomSettingsToggleLock = 'roomSettings_toggle_lock',
  RoomSettingsToggleClipeeze = 'roomSettings_toggle_clipeeze',
  RoomSettingsToggleMeetingMode = 'roomSettings_toggle_meetingMode',
  RoomSettingsToggleSilentMode = 'roomSettings_toggle_silentMode',
  RoomSettingsToggleBlindMode = 'roomSettings_toggle_blindMode',
  RoomSettingsToggleSpeakerHighlight = 'roomSettings_toggle_speakerHighlight',
  RoomSettingsToggleHideVideoMuted = 'roomSettings_toggle_hideVideoMuted',

  RecordingButtonStart = 'recording_button_start',
  RecordingButtonStop = 'recording_button_stop',
  RecordingButtonCopyWeb = 'recording_button_copyWeb',
  RecordingButtonCopyDash = 'recording_button_copyDash',
  RecordingButtonCopyFlv = 'recording_button_copyFlv',
  RecordingButtonCopyHls = 'recording_button_copyHls',
  RecordingToggleBackup = 'recording_toggle_backup',
  RecordingInputStreamingUrl = 'recording_input_streamingUrl',
  RecordingSelectMethod = 'recording_select_method',

  VideoLayoutSelectDefaultCanvas = 'videoLayout_select_defaultCanvas',
  VideoLayoutSelectCallCanvas = 'videoLayout_select_callCanvas',
  VideoLayoutRangeMotionQuality = 'videoLayout_range_motionQuality',
  VideoLayoutRangeMotionQualityInbound = 'videoLayout_range_motionQualityInbound',
  VideoLayoutSelectQuality = 'videoLayout_select_quality',
  VideoLayoutSelectMode = 'videoLayout_select_mode',
  VideoLayoutSelectRole = 'videoLayout_select_role',

  MediaSelectPlaybackRole = 'media_select_playbackRole',
  MediaButtonStop = 'media_button_stop',
  MediaButtonRewind = 'media_button_rewind',
  MediaButtonFastForward = 'media_button_fastForward',
  MediaButtonPlayPause = 'media_button_playPause',
  MediaInputUrl = 'media_input_url',
  MediaRangeVolume = 'media_range_volume',
  MediaRangeDefaultVolume = 'media_range_defaultVolume',
  MediaInputTextToSpeech = 'media_input_textToSpeech',
  MediaRangePerformerDelay = 'media_range_performerDelay',

  SoundRangeMotionQuality = 'sound_range_motionQuality',
  SoundRangeEnergy = 'sound_range_energy',
  SoundRangeGain = 'sound_range_gain',
  SoundRangeVolume = 'sound_range_volume',

  AudienceButtonVideoOn = 'audience_button_videoOn',
  AudienceButtonVideoOff = 'audience_button_videoOff',
  AudienceButtonMuteAll = 'audience_button_muteAll',
  AudienceButtonUnmuteAll = 'audience_button_unmuteAll',
  AudienceRangeVolume = 'audience_range_volume',
  AudienceButtonToggleBanners = 'audience_button_toggleBanners',
  AudienceButtonRaiseOnScreen = 'audience_button_raiseOnScreen',
  AudienceButtonRaiseOffScreen = 'audience_button_raiseOffScreen',
  AudienceButtonLowerHands = 'audience_button_lowerHands',
  AudienceSelectShuffle = 'audience_select_shuffle',
  AudienceButtonShuffleNow = 'audience_button_shuffleNow',
  AudienceButtonRemoveAll = 'audience_button_removeAll',
  AudienceButtonToggleAllowHandRaising = 'audience_button_allowHandRasing',

  // Special Inputs
  InviteInputUrl = 'invite_input_url',
  PINInputModerator = 'pin_input_moderator',
  PINInputParticipant = 'pin_input_participant',
  PINInputAccessParticipant = 'pin_input_access_participant',

  // Device Dropdowns
  AudioDeviceDropdown = 'audio_device_dropdown',
  VideoDeviceDropdown = 'video_device_dropdown',
  SpeakerDeviceDropdown = 'speaker_device_dropdown',

  // Messages
  MessageEmailSent = 'message_emailSent',
  MessageError = 'message_error',
  MessageErrorPlayback = 'message_error_playback',
  MessageErrorInvalidToken = 'message_error_invalidToken',

  // Modals
  AboutModal = 'modal_about',
  ClipeezePreviewModal = 'modal_clipeeze_preview',
  ClipeezeUploadModal = 'modal_clipeeze_upload',
  DevicesModal = 'modal_devices',
  DevicesModalLobby = 'modal_devices_lobby',
  MediaModalLobby = 'modal_media_lobby',
  DevicesModalSystemSettings = 'modal_devices_system_settings',
  DevicesSecondSourceModal = 'modal_devices_second_source',
  DialpadModal = 'modal_dialpad',
  KnockModal = 'modal_knock',
  InboundCallModal = 'modal_inbound_call',
  InboundCallModalAccept = 'modal_inbound_call_button_accept',
  InboundCallModalDecline = 'modal_inbound_call_button_decline',
  InviteModal = 'modal_invite',
  ModalClose = 'modal_close',
  MutedModal = 'modal_muted',
  NotificationModal = 'modal_notification',
  NotificationModalButtonConfirm = 'modal_notification_button_confirm',
  FloaterClose = 'floater_close',
  FloaterPlaybackControls = 'floater_playback_controls',
  PINModal = 'modal_pin',
  PostCallSurvey = 'modal_postcall_survey',
  RoomPreviewModal = 'modal_room_preview',

  // System Settings
  SystemSettingsProfileForm = 'system_settings_profile_form',
  SystemSettingsGroupForm = 'system_settings_group_form',
  SystemSettingsAccessGrantForm = 'system_settings_access_grant_form',
  SystemSettingsRoomForm = 'system_settings_room_form',
  SystemSettingsClipeeze = 'system_settings_clipeeze',
  SystemSettingsClipeezeSearch = 'system_settings_clipeeze_search',

  // Room Settings Lobby
  RoomSettingsLobbyButtonRecording = 'room_settings_lobby_button_home',
  RoomSettingsLobbyButtonMedia = 'room_settings_lobby_button_media',
  RoomSettingsLobbyButtonTTS = 'room_settings_lobby_button_tts',
  RoomSettingsLobbyButtonBanner = 'room_settings_lobby_button_banner',
  RoomSettingsLobbyButtonAvoidInterruptions = 'room_settings_lobby_button_avoid_interruptions',
  RoomSettingsLobbyButtonInvite = 'room_settings_lobby_button_invite',
  RoomSettingsLobbyButtonRemoveEveryone = 'room_settings_lobby_remove_everyone',
}
