import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RoutePath } from 'common/constants'
import { hasValidAuth } from 'common/redux/features/auth/authSelectors'

export const NotFoundRoute = () => {
  const hasAuth = useSelector(hasValidAuth)
  return (
    <Route>
      <Redirect to={hasAuth ? RoutePath.Home : RoutePath.Login} />
    </Route>
  )
}
