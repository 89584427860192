import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MixPanelEvent, RoutePath } from 'common/constants'
import { useRoomSelector, useProductLine } from 'common/hooks'
import { mixPanelTrackAction } from 'common/redux/actions'
import { getHeaderLogo } from 'common/redux/features/settings/settingsSelectors'
import { getActiveCallRoomId } from 'common/redux/features/calls/callSelectors'
import { getRoomName } from 'common/redux/features/roomList/roomListSelectors'
import { CantinaLink } from '../CantinaLink'
import { SwLogoFull } from 'src/layout'

export const AppLogo = () => {
  if (process.env.REACT_APP_HYBRID_MODE === '1') {
    return <HybridDynamicLogo />
  }
  return <DefaultLogo />
}

const HybridDynamicLogo = () => {
  const logo = useSelector(getHeaderLogo)
  if (!logo) {
    return <DefaultLogo />
  }
  return (
    <CantinaLink to={RoutePath.Home} className='grid'>
      <img className='dynamic-logo' src={logo} alt='App Logo' />
    </CantinaLink>
  )
}

const DefaultLogo = () => {
  const dispatch = useDispatch()
  const productLine = useProductLine()
  const currentRoomId = useSelector(getActiveCallRoomId)
  const roomName = useRoomSelector(getRoomName, currentRoomId)
  // hardcoded for now
  const targetUrl = `https://signalwire.com/products/${productLine}`
  const clickHandler = () => {
    const action = mixPanelTrackAction({
      event: MixPanelEvent.ClickHomeLogo,
      params: {
        roomName: roomName || '',
        target: productLine,
      },
    })
    dispatch(action)
  }

  return (
    <a href={targetUrl} target='_blank' rel='noreferrer' onClick={clickHandler}>
      <SwLogoFull />
    </a>
  )
}
