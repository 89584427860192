import { BootstrapResponse, FETCH_OPTIONS, cantinaFetch } from './'

type BootstrapParams = {
  baseUrl: string
  hostname: string
}

export const bootstrap = ({
  baseUrl,
  hostname,
}: BootstrapParams): Promise<BootstrapResponse> => {
  const url = `${baseUrl}?domain=${encodeURIComponent(hostname)}`
  return cantinaFetch(url, {
    ...FETCH_OPTIONS,
    method: 'GET',
  })
}
