import React, { FC } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { CantinaState, CanvasLayout } from 'common/redux/interfaces'
import { getCallCanvasMediaLayers } from 'common/redux/features/calls/callSelectors'
import { tr, Label } from 'common/i18n'
import { useFade, useMyZone } from 'common/hooks'
import { StopPlaybackButton } from '../CallSettings/StopPlaybackButton'
import { ScopedPlaybackSettingsButton } from 'src/components/CallButtons/PlaybackSettingsButton'

export const MediaLayers = ({
  callId,
  roomId,
}: {
  callId: string
  roomId: string
}) => {
  const layouts = useSelector(
    (state: CantinaState) => getCallCanvasMediaLayers(state, callId),
    shallowEqual
  )

  return (
    <>
      {layouts.map((layout) => (
        <MediaLayer
          key={`media-${layout.participantId}`}
          roomId={roomId}
          layout={layout}
        />
      ))}
    </>
  )
}

type MediaLayerProps = {
  roomId: string
  layout: CanvasLayout
}
const MediaLayer: FC<MediaLayerProps> = ({ roomId, layout }) => {
  const [menuClass, showMenu, wrapper] = useFade({})
  const { isInZone } = useMyZone()
  const blurClass = isInZone ? 'mcu-blurred-layer' : ''

  let clickHandler
  let classNames = ''
  let title: string | undefined
  if (layout.onclick) {
    clickHandler = () => window.open(layout.onclick, '_blank')
    classNames = 'cursor-pointer'
    title = tr(Label.OPEN_IN_A_NEW_TAB, { url: layout.onclick })
  }

  return (
    <div
      className={`absolute ${blurClass}`} // Blur videos while in a zone
      style={layout.styles}
    >
      <div className='user-menu' ref={wrapper}>
        <div
          className={`absolute w-full h-full ${classNames}`}
          onClick={clickHandler}
          title={title}
        />
        {showMenu && (
          <div
            className={`absolute user-menu-items w-full flex justify-between items-center ${menuClass}`}
          >
            <StopPlaybackButton
              className='sw-btn-icon-toggle'
              roomId={roomId}
            />
            <ScopedPlaybackSettingsButton showTooltip />
          </div>
        )}
      </div>
    </div>
  )
}
