import React, { ComponentType, FC, ReactNode } from 'react'
import ReactDOM from 'react-dom'
import { CloseIcon, SpinnerIcon } from 'common/icons'
import { tr, Label } from 'common/i18n'
import { PrimaryButton, SecondaryButton } from '../components/Button/Button'
import { FontAwesomeIconProps } from 'common/icons/FontAwesomeIcon'
import { TestId } from 'src/constants'
import { ButtonGroup } from 'src/components/Button/ButtonGroup'

// Required props: show, testId, title

type props = {
  autoComplete?: 'off' | 'on'
  afterTitle?: any // add arbitrary html
  beforeTitle?: any // add arbitrary html
  byline?: string
  cancelHandler?: (params: any) => void
  cancelLabel?: string
  cancelTestId?: TestId
  children?: ReactNode
  closeHandler?: (params: any) => void
  confirmHandler?: (params: any) => void
  confirmLabel?: string
  confirmTestId?: TestId
  containerClassName?: string
  disabled?: boolean
  isForm?: boolean
  isLoading?: boolean
  isPreview?: boolean // reduce padding from content area
  show: boolean
  showCancelButton?: boolean
  showCloseButton?: boolean
  showConfirmButton?: boolean
  testId: TestId
  title: string
  TitleIcon?: ComponentType<Partial<FontAwesomeIconProps>>
  wrapperClassName?: string
}

export const Modal: FC<props> = ({
  autoComplete,
  afterTitle,
  beforeTitle,
  byline,
  cancelHandler,
  cancelLabel,
  cancelTestId,
  children,
  closeHandler,
  confirmHandler,
  confirmLabel,
  confirmTestId,
  containerClassName = '',
  disabled = false,
  isForm,
  isLoading = false,
  isPreview = false,
  show,
  showCancelButton = true,
  showCloseButton = true,
  showConfirmButton = true,
  testId,
  title,
  TitleIcon,
  wrapperClassName = '',
}) => {
  if (!show) {
    return null
  }

  const hasButton = !!(showCancelButton || showConfirmButton)

  const modalButtons = (
    <ButtonGroup className='mt-6'>
      {showCancelButton && (
        <SecondaryButton
          onClick={cancelHandler ?? closeHandler}
          testId={cancelTestId || `${testId}_cancel`}
          transparent
        >
          {cancelLabel || tr(Label.CANCEL)}
        </SecondaryButton>
      )}
      {showConfirmButton && (
        <PrimaryButton
          onClick={isForm ? undefined : confirmHandler}
          disabled={disabled || isLoading}
          testId={confirmTestId || `${testId}_confirmation`}
          type={isForm ? 'submit' : 'button'}
        >
          {isLoading ? <SpinnerIcon /> : confirmLabel || tr(Label.CONFIRM)}
        </PrimaryButton>
      )}
    </ButtonGroup>
  )

  const modalContents = (
    <div className={`sw-modal ${wrapperClassName}`} data-test={testId}>
      <div onClick={closeHandler} className='modal-overlay'></div>
      <div
        className={`modal-container block-primary flex flex-col ${containerClassName} ${
          isPreview ? 'px-1 pb-1' : ''
        }`}
      >
        <div className='shrink-0 px-6 py-4'>
          <div className='flex items-center justify-between text-contrast-h text-xl md:text-2xl'>
            <div className='flex items-center w-full'>
              {TitleIcon && (
                <div className='shrink-0 self-center mr-2'>
                  <TitleIcon
                    size='3x'
                    className='stroke-current fill-current'
                  />
                </div>
              )}
              {beforeTitle}
              <div className='shrink font-bold font-neue leading-tight pr-4'>
                {title}
              </div>
              {afterTitle}
            </div>
            {showCloseButton && (
              <button
                aria-label={tr(Label.CLOSE)}
                className='sw-btn-close ml-2'
                data-test={TestId.ModalClose}
                onClick={closeHandler}
              >
                <CloseIcon size='lg' />
              </button>
            )}
          </div>
          {byline ? (
            <div className='text-contrast-m text-sm pb-1 leading-tight'>
              {byline}
            </div>
          ) : null}
        </div>
        <div className={`overflow-y-auto ${isPreview ? '' : 'px-6 pb-4'}`}>
          {isForm ? (
            <form autoComplete={autoComplete} onSubmit={confirmHandler}>
              {children}
              {hasButton && modalButtons}
            </form>
          ) : (
            <>
              {children}
              {hasButton && modalButtons}
            </>
          )}
        </div>
      </div>
    </div>
  )

  const mainContentElement = document.querySelector('.app-main-content')
  if (mainContentElement) {
    // Add modal to bottom of app to overlay all elements, including toasts
    return ReactDOM.createPortal(modalContents, mainContentElement)
  }

  return modalContents
}
