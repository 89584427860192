import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { CURRENT_HOSTNAME } from 'common/constants'
import { withConferenceInviteBySmsScope } from 'common/hoc'
import { Endpoint } from 'common/rest'
import { useCantinaBackendAPI, useCantinaId } from 'common/hooks'
import { getName } from 'common/redux/features/auth/authSelectors'
import { tr, Label } from 'common/i18n'
import { useInputChange } from 'src/effects/useInputChange'
import SubmittableInput from 'src/components/Inputs/SubmittableInput'
import InviteOption from './InviteOption'

const smsInvitationOptions = {
  withAuth: true,
  endpoint: Endpoint.SmsInvitation,
  method: 'POST',
}

interface BySmsProps {
  roomId: string
}

const BySms: FC<BySmsProps> = ({ roomId }) => {
  const [values, setValues, bulkUpdate] = useInputChange({ inviteBySms: '' })
  const { invokeAPI: invitationHandler } =
    useCantinaBackendAPI(smsInvitationOptions)
  const [error, setError] = useState('')

  const cantinaId = useCantinaId()
  const myName = useSelector(getName)

  const inviteBySmsHandler = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()

    if (values.inviteBySms) {
      try {
        setError('')
        await invitationHandler({
          urlTransform: (url) => {
            return (
              url
                // FIXME: remove `cantinaId` replace
                .replace(':cantinaId', cantinaId)
                .replace(':roomId', roomId)
            )
          },
          body: JSON.stringify({
            domain: CURRENT_HOSTNAME,
            name: myName,
            language: 'en',
            toNumber: values.inviteBySms,
          }),
        })

        bulkUpdate({ inviteBySms: '' })
      } catch (error) {
        setError(tr(Label.INVITE_BY_SMS_ERROR))
      }
    }
  }

  return (
    <InviteOption
      description={tr(Label.INVITE_BY_SMS_DESCRIPTION)}
      label={tr(Label.INVITE_BY_SMS)}
    >
      <SubmittableInput
        className='text-sm'
        currentValue={values.inviteBySms}
        inputChanged={setValues}
        name='inviteBySms'
        placeholder='e.g. +15551231234'
        submitButtonLabel={tr(Label.SEND)}
        submitHandler={inviteBySmsHandler}
        type='text'
        error={error}
      />
    </InviteOption>
  )
}

export default withConferenceInviteBySmsScope(BySms)
