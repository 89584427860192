import React, { FC, ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'
import { tr, Label } from 'common/i18n'
import { withFilter } from 'common/hoc'
import { useScopeSelector } from 'common/hooks'
import { Scope } from 'common/constants'
import { lobbyViewActions } from 'common/redux/views'
import { getRoomFilter } from 'common/redux/views/lobbyView/lobbyViewSelectors'
import { TestId } from 'src/constants'
import { useHistoryPushToRoom } from 'src/effects/useHistoryPushToRoom'
import { SearchInput } from 'src/components/Inputs/SearchInput'
import { NoSearchResults } from 'src/components/Rooms/NoSearchResults'
import { cleanFilter } from 'common/services/helpers'

interface SearchProps {
  clearHandler?: () => void
  filter: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const Search: FC<SearchProps> = ({
  clearHandler,
  filter,
  onChange,
  ...rest
}) => {
  const dispatch = useDispatch()
  const hasScope = useScopeSelector(Scope.UiDialer)
  const { pushHandler } = useHistoryPushToRoom()

  const makeRoomHandler = hasScope
    ? () => {
        if (cleanFilter(filter)?.length) {
          pushHandler({ roomName: filter })
          dispatch(lobbyViewActions.setRoomFilter({ filter: '' }))
        }
      }
    : undefined

  return (
    <div className='flex flex-col gap-4 p-4'>
      <SearchInput
        className='text-contrast-h rounded-md bg-primary border border-contrast-l focus-within:border-contrast-h shadow-2 w-full sm:max-w-md p-2 mx-auto'
        clearInputFunction={clearHandler}
        currentValue={filter}
        enterHandler={makeRoomHandler}
        inputChanged={onChange}
        inputClass='sw-input-search border-0 outline-none text-sm'
        name='lobbyRoomFilter'
        placeholder={tr(Label.FIND_OR_CREATE_A_ROOM)}
        testId={TestId.HomeInputRoomNavFilter}
        {...rest}
      />
      <NoSearchResults filter={filter} makeRoomHandler={makeRoomHandler} />
    </div>
  )
}

export const RoomSearch = withFilter(
  Search,
  lobbyViewActions.setRoomFilter,
  getRoomFilter
)
