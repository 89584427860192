import { createSelector } from 'reselect'
import { CantinaState, Participant } from '../../interfaces'
import { getRooms } from '../../features/roomList/roomListSelectors'
import { getRoomDefaultPlayVolume } from '../../features/rooms/roomsSelectors'
import {
  getParticipants,
  getParticipantIdsByRoomId,
} from '../../features/participants/participantSelectors'
import { getActiveCallRoomId } from '../../features/calls/callSelectors'
import { RoomType, RoomVisibility } from '../../../constants'
import {
  cleanFilter,
  sortByNameProperty,
  sortByOrderPriorityProperty,
  sortFilteredRooms,
  isParticipantIdFromFS,
} from '../../../services/helpers'

const participantId = (_: CantinaState, participantId: string) => participantId

export const isClipeezeShown = ({ callView }: CantinaState) => {
  return callView.clipeeze || false
}

export const getClipeezePlayOptions = ({ callView }: CantinaState) => {
  return {
    clipeezeLoop: Boolean(callView?.clipeezeLoop),
  }
}

export const isChatShown = ({ callView }: CantinaState) => {
  return callView.chat || false
}

export const isParticipantListShown = ({ callView }: CantinaState) => {
  return callView.participantList || false
}

export const isPushToTalkEnabled = ({ callView }: CantinaState) => {
  return callView.ptt || false
}

export const isPipEnabled = ({ callView }: CantinaState) => {
  return callView.pictureInPicture || false
}

export const isRoomNavigatorShown = ({ callView }: CantinaState) => {
  return callView.roomNavigator || false
}

export const shouldRoomNavigatorAutoFocus = ({ callView }: CantinaState) => {
  return callView.roomNavigatorAutoFocus
}

export const getParticipantIdsToTransfer = ({ callView }: CantinaState) => {
  return callView.participantIdsToTransfer || []
}

export const getParticipantListFilter = ({ callView }: CantinaState) =>
  callView.participantListFilter || ''

export const getParticipantModalFilter = ({ callView }: CantinaState) =>
  callView.participantModalFilter || ''

export const isScreenShareActive = ({ callView }: CantinaState) => {
  return callView.screenShareActive
}

export const getRecentlyPlayedVideos = ({ callView }: CantinaState) => {
  return callView.recentlyPlayedVideos
}

export const getDefaultPlaybackVolume = createSelector(
  ({ callView }: CantinaState) => callView.defaultPlaybackVolume,
  getRoomDefaultPlayVolume,
  (defaultPlaybackVolume, globalDefaultPlayVolume) =>
    Number(defaultPlaybackVolume ?? globalDefaultPlayVolume)
)

export const isParticipantIdToTransfer = createSelector(
  getParticipantIdsToTransfer,
  participantId,
  (ids, participantId) => ids.includes(participantId)
)

export const isDrawerShown = createSelector(
  isClipeezeShown,
  isChatShown,
  isParticipantListShown,
  isRoomNavigatorShown,
  (clipeeze, chat, participantList, roomNavigator) => {
    return clipeeze || chat || participantList || roomNavigator
  }
)

export const getRoomNavigatorFilter = ({ callView }: CantinaState) =>
  callView.roomNavigatorFilter || ''

// prettier-ignore
export const getRoomIdsFiltered = createSelector(
  getRooms,
  getRoomNavigatorFilter,
  (rooms, filter) => {
    const roomIds = Object.keys(rooms)
    if (!filter) {
      return roomIds
    }
    const cleanedFilter = cleanFilter(filter)
    return roomIds.filter((id) => rooms[id]?.name?.toLowerCase().includes(cleanedFilter))
  }
)

// prettier-ignore
export const getCurrentRoomIdFiltered = createSelector(
  getRooms,
  getActiveCallRoomId,
  getRoomNavigatorFilter,
  (rooms, activeRoomId, filter) => {
    if (!filter) {
      return activeRoomId
    }
    const cleanedFilter = cleanFilter(filter)
    const match = activeRoomId && rooms[activeRoomId]?.name?.toLowerCase().includes(cleanedFilter)

    return match ? activeRoomId : null
  }
)

export const hasExactRoomNameMatch = createSelector(
  getRooms,
  getRoomNavigatorFilter,
  (rooms, filter) => {
    const cleanedFilter = cleanFilter(filter)

    return (
      rooms &&
      Object.keys(rooms)
        .map((id) => rooms?.[id]?.name?.toLowerCase())
        .includes(cleanedFilter)
    )
  }
)

export const isCallSettingsShown = ({ callView }: CantinaState) => {
  return callView.callSettings || false
}

export const getCallSettingsTabIndex = ({ callView }: CantinaState) => {
  return callView.callSettingsTabIndex || 0
}

export const isPostCallSurveyShown = ({ callView }: CantinaState) => {
  return callView.showPostCallSurvey
}

export const getRoomNavigatorRooms = createSelector(
  getRooms,
  getCurrentRoomIdFiltered,
  getRoomIdsFiltered,
  (rooms, activeRoomId, roomIds) => {
    const filteredRoomIds = roomIds
      .filter((roomId) => roomId !== activeRoomId)
      .sort((id1: string, id2: string) => {
        // Sort array by running first, then visibility and orderPriority
        if (rooms[id1].running && rooms[id2].running) {
          return sortByNameProperty(rooms[id1], rooms[id2])
        }
        if (!rooms[id1].running && rooms[id2].running) {
          return 1
        } else if (rooms[id1].running && !rooms[id2].running) {
          return -1
        }
        if (
          rooms[id1].visibility === RoomVisibility.Normal &&
          rooms[id2].visibility === RoomVisibility.Pinned
        ) {
          return 1
        } else if (
          rooms[id1].visibility === RoomVisibility.Pinned &&
          rooms[id2].visibility === RoomVisibility.Normal
        ) {
          return -1
        }
        const byOrder = sortByOrderPriorityProperty(rooms[id1], rooms[id2])
        if (byOrder !== 0) {
          return byOrder
        }

        // Sort by name as last
        return sortByNameProperty(rooms[id1], rooms[id2])
      })
      .map((roomId) => `${roomId}|${RoomType.Live}`)

    const currentRoom = activeRoomId
      ? [`${activeRoomId}|${RoomType.Current}`]
      : []

    // Always concat the currentRoom first, then running and permanent rooms
    return currentRoom.concat(filteredRoomIds)
  }
)

export const getFilteredRoomNavigatorRooms = createSelector(
  getRooms,
  getCurrentRoomIdFiltered,
  getRoomNavigatorFilter,
  getRoomIdsFiltered,
  (rooms, activeRoomId, roomFilter, roomIds) => {
    return roomIds
      .sort((id1, id2) => {
        return sortFilteredRooms(rooms[id1], rooms[id2], roomFilter)
      })
      .map((roomId) =>
        roomId === activeRoomId
          ? `${roomId}|${RoomType.Current}`
          : `${roomId}|${RoomType.Live}`
      )
  }
)

const getParticipantsGroupedByRoomId = createSelector(
  getParticipants,
  (participants) => {
    const hash: Record<string, any> = {}

    Object.keys(participants).forEach((participantId) => {
      const { name, isScreenShareLeg, isSecondSourceLeg, roomId } =
        participants[participantId]
      // Skip screenShare or secondSource legs
      const isMember = !(isScreenShareLeg || isSecondSourceLeg)
      // Skip members from CantinaMgr
      const fromFS = isParticipantIdFromFS(participantId)

      if (roomId && isMember && fromFS) {
        if (!hash[roomId]) {
          hash[roomId] = []
        }
        hash[roomId].push({
          label: name,
          value: participantId,
        })
      }
    })

    return hash
  }
)

/**
 * Return transfer list nodes filtered by "currentRoomId"
 */
export const getTransferListNodes = createSelector(
  getRooms,
  getParticipantsGroupedByRoomId,
  getActiveCallRoomId,
  (rooms, hash, currentRoomId) => {
    const nodes: any[] = []

    Object.keys(rooms).forEach((roomId) => {
      const children = hash?.[roomId]
      // Filter for only currentRoomId
      if (currentRoomId === roomId && children?.length) {
        nodes.push({
          children,
          label: `${rooms[roomId].name} (${children.length})`,
          value: roomId,
        })
      }
    })

    return nodes
  }
)

/**
 * Return tranfer list nodes excluding "currentRoomId"
 */
export const getCurrentRoomTransferListNodes = createSelector(
  getRooms,
  getParticipantsGroupedByRoomId,
  getActiveCallRoomId,
  (rooms, hash, currentRoomId) => {
    const nodes: any[] = []

    Object.keys(rooms).forEach((roomId) => {
      const children = hash?.[roomId]
      // Exclude the currentRoomId
      if (currentRoomId !== roomId && children?.length) {
        nodes.push({
          children,
          label: `${rooms[roomId].name} (${children.length})`,
          value: roomId,
        })
      }
    })

    return nodes
  }
)

type MakeRoomParticipantIdsSelectorParams = {
  membersOnly?: boolean
  sortBy: (keyof Participant)[]
  filterSelector:
    | typeof getParticipantListFilter
    | typeof getParticipantModalFilter
}
const makeRoomParticipantIdsSelector = ({
  membersOnly = false,
  sortBy,
  filterSelector,
}: MakeRoomParticipantIdsSelectorParams) => {
  return createSelector(
    getParticipants,
    getParticipantIdsByRoomId,
    filterSelector,
    (participants, allIds, filter) => {
      const cleanedFilter = cleanFilter(filter)
      return [...allIds]
        .filter((id) => {
          const participant = participants?.[id]

          if (participant) {
            // Filter out screenShare and secondSource if needed
            if (
              membersOnly &&
              (participant.isScreenShareLeg || participant.isSecondSourceLeg)
            ) {
              return false
            }

            const { name = '', email = '' } = participants?.[id]
            return `${name}|${email}`.toLowerCase().includes(cleanedFilter)
          }

          return false
        })
        .sort((id1, id2) => {
          for (let i = 0; i < sortBy.length; i++) {
            const prop = sortBy[i]
            // Sort array following sortBy properties
            if (!participants[id1][prop] && participants[id2][prop]) {
              return 1
            } else if (participants[id1][prop] && !participants[id2][prop]) {
              return -1
            }
          }
          // otherwise do nothing
          return 0
        })
    }
  )
}

/**
 * The sort order is:
 *
 * - My user always on top
 * - ScreenShare callers
 * - SecondSource callers
 * - Moderators
 * - All the others (already sorted by room join)
 *
 * This selector also filter the list using the "participantListFilter"
 */
export const getRoomParticipantListIds = makeRoomParticipantIdsSelector({
  sortBy: ['myself', 'isScreenShareLeg', 'isSecondSourceLeg', 'moderator'],
  filterSelector: getParticipantListFilter,
})

/**
 * The sort order is:
 *
 * - My user always on top
 * - Users within a zone
 * - All the others (already sorted by room join)
 *
 * This selector also filter the list using the "participantModalFilter"
 */
export const getRoomParticipantModalIds = makeRoomParticipantIdsSelector({
  membersOnly: true,
  sortBy: ['myself', 'roomZoneId'],
  filterSelector: getParticipantModalFilter,
})

/**
 * Return array of Participants talking now or within the last 5 seconds
 */
export const PARTICIPANT_TALKING_TIMEOUT = 5000

export const getParticipantsGroupedByTalking = createSelector(
  getParticipants,
  getRoomParticipantListIds,
  (participants, ids) => {
    const talkingParticipants: Participant[] = []
    Object.keys(participants).forEach((participantId) => {
      const participant = participants[participantId]
      if (
        ids.includes(participantId) &&
        // Talking now
        (participant.talking ||
          // Or has talked within the last n seconds
          (participant.lastTalkingTime &&
            participant.lastTalkingTime >
              Date.now() - PARTICIPANT_TALKING_TIMEOUT))
      ) {
        talkingParticipants.push(participant)
      }
    })
    return talkingParticipants
  }
)

/**
 * Make a selector filtered by a Participant property.
 */
const makeRoomParticipantIdsGroupedByPropSelector = (
  property: keyof Participant
) => {
  return createSelector(
    getParticipants,
    getRoomParticipantListIds,
    (participants, ids) => {
      return ids.filter((id) => Boolean(participants?.[id]?.[property]))
    }
  )
}
export const getParticipantsGroupedByHandRaised =
  makeRoomParticipantIdsGroupedByPropSelector('handRaised')
export const getParticipantsGroupedByModerator =
  makeRoomParticipantIdsGroupedByPropSelector('moderator')
export const getParticipantsGroupedByReservationId =
  makeRoomParticipantIdsGroupedByPropSelector('reservationId')

export const getParticipantListGroups = ({ callView }: CantinaState) => {
  return callView.participantListGroups
}
