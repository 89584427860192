import React, { FC, ChangeEvent } from 'react'
import { withFilter } from 'common/hoc'
import { clipeezeActions } from 'common/redux/features'
import { tr, Label } from 'common/i18n'
import { getClipeezeFilter } from 'common/redux/features/clipeeze/clipeezeSelectors'
import { TestId } from 'src/constants'
import { SearchInput } from 'src/components/Inputs/SearchInput'

type ClipeezeSearchProps = {
  autoFocus?: boolean
  className?: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  clearHandler?: () => void
  filter: string
}

export const ClipeezeSearch: FC<ClipeezeSearchProps> = ({
  autoFocus = false,
  className,
  onChange,
  clearHandler,
  filter,
}) => {
  return (
    <SearchInput
      autoFocus={autoFocus}
      className={className}
      clearInputFunction={clearHandler}
      currentValue={filter}
      inputChanged={onChange}
      name='clipeezeFilter'
      placeholder={tr(Label.FIND_CLIPEEZE)}
      testId={TestId.ClipeezeInputSearch}
    />
  )
}

export const ClipeezeSearchWithFilter = withFilter(
  ClipeezeSearch,
  clipeezeActions.setClipeezeFilter,
  getClipeezeFilter
)
