import React, { FC, ReactElement } from 'react'
import { SpinnerIcon } from 'common/icons'
import { RequestState } from 'common/rest'
import { tr, Label } from 'common/i18n'
import { PrimaryButton } from 'src/components/Button/Button'

type RequestStateSwitcherProps = {
  requestState: RequestState
  refresh: () => void
  error: string | null
}

export const RequestStateSwitcher: FC<RequestStateSwitcherProps> = ({
  children,
  requestState,
  error,
  refresh,
}) => {
  switch (requestState) {
    case RequestState.Success:
      return children as ReactElement
    case RequestState.Error:
      return (
        <div className='text-center'>
          <p className='py-4'>{error}</p>
          <PrimaryButton onClick={refresh}>{tr(Label.RETRY)}</PrimaryButton>
        </div>
      )
    case RequestState.Loading:
    default:
      return (
        <SpinnerIcon
          size='2x'
          fixedWidth
          className='block mx-auto text-contrast-h'
        />
      )
  }
}
