import React, { FC, ChangeEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { useFetchRecordings, useDebounce } from 'common/hooks'
import { ThIcon } from 'common/icons'
import { RoutePath } from 'common/constants'
import { tr, Label } from 'common/i18n'
import { sortByNameProperty } from 'common/services/helpers'
import { SEARCH_DEBOUNCE_DELAY } from 'src/constants'
import { SearchInput } from 'src/components/Inputs/SearchInput'
import { RequestStateSwitcher } from '../RequestStateSwitcher'

export const RoomRecordingList: FC = () => {
  const { rooms, requestState, refresh, error } = useFetchRecordings()
  const [filter, setFilter] = useState('')
  const delayedFilter = useDebounce(
    filter,
    SEARCH_DEBOUNCE_DELAY
  )?.toLowerCase()
  const filteredRooms = rooms
    ?.filter(({ name }) => name.toLowerCase().includes(delayedFilter))
    ?.sort(sortByNameProperty)

  return (
    <>
      <div className='administration-section-title'>
        <div className='font-neue font-bold text-contrast-h text-2xl'>
          {tr(Label.RECORDINGS)}
        </div>
      </div>
      <div className='block-primary administration-section-content'>
        <RequestStateSwitcher
          requestState={requestState}
          refresh={refresh}
          error={error}
        >
          <div className='flex items-center justify-between'>
            <SearchInput
              className='text-sm mr-4 '
              clearInputFunction={() => setFilter('')}
              currentValue={filter}
              inputChanged={(event: ChangeEvent<HTMLInputElement>) => {
                setFilter(event.target.value)
              }}
              name='groupFilter'
              placeholder='Search'
            />
          </div>
          {filteredRooms?.length ? (
            <p className='text-sm text-contrast-m pt-2 pb-4'>
              {tr(Label.RECORDINGS_CHOOSE_ROOM)}
            </p>
          ) : (
            <p className='text-base py-4'>{tr(Label.RECORDINGS_NO_RESULTS)}</p>
          )}
          {filteredRooms?.map((room) => {
            return (
              <RoomItem key={room.id} roomId={room.id} roomName={room.name} />
            )
          })}
        </RequestStateSwitcher>
      </div>
    </>
  )
}

const RoomItem: FC<{ roomId: string; roomName: string }> = ({
  roomId,
  roomName,
}) => {
  return (
    <Link
      to={RoutePath.SettingsRecordingDetail.replace(':roomId', roomId)}
      className='administration-section-item-title group h-12 hover:shadow-md'
    >
      <span className='group-hover:text-sw-selected'>
        <ThIcon fixedWidth className='mr-3' />
        {roomName}
      </span>
    </Link>
  )
}
