import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ThIcon } from 'common/icons'
import { Displayable } from 'common/constants'
import { uiActions } from 'common/redux/features'
import { isDialpadModalVisible } from 'common/redux/features/ui/uiSelectors'
import { withInCallButtonWrapper } from './withInCallButtonWrapper'
import { InCallLocalAudioTrackButton } from './LocalAudioTrackButton'
import { InCallLocalVideoTrackButton } from './LocalVideoTrackButton'
import { InCallHangupButton } from './HangupButton'
import { InCallHoldButton } from './HoldButton'

export const DirectCallButtons: FC = () => {
  return (
    <div className='call-bottom-btn-wrapper flex justify-between'>
      <div className='flex mr-10'>
        <InCallHangupButton label='leave' />
      </div>
      <div className='flex flex-1 gap-6'>
        <InCallLocalAudioTrackButton label='Mute' offLabel='Unmute' />
        <InCallLocalVideoTrackButton
          label='Stop Video'
          offLabel='Start Video'
        />
      </div>
      <div className='flex gap-6'>
        <DialpadButton label='Dialpad' />
        <InCallHoldButton />
      </div>
      <div className='flex flex-1 justify-end mr-3'></div>
    </div>
  )
}

const DialpadButton = withInCallButtonWrapper(() => {
  const dispatch = useDispatch()
  const isShown = useSelector(isDialpadModalVisible)
  const clickHandler = () => dispatch(uiActions.show(Displayable.DialpadModal))
  const className = isShown ? 'active' : ''

  return (
    <button
      className={`sw-btn-icon-toggle ${className}`}
      onClick={clickHandler}
    >
      <ThIcon size='lg' fixedWidth />
    </button>
  )
})
