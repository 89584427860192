import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHotkeys } from 'react-hotkeys-hook'
import { WalkieTalkieIcon } from 'common/icons'

import { callViewActions } from 'common/redux/views'
import {
  stopLocalAudioTrack,
  restoreLocalAudioTrack,
} from 'common/redux/features'
import { hasSilentMode as hasSilentModeSelector } from 'common/redux/features/rooms/roomsSelectors'
import { CantinaState } from 'common/redux/interfaces'
import { getActiveCallRoomId } from 'common/redux/features/calls/callSelectors'
import { tr, Label } from 'common/i18n'
import { withConferenceAudioMuteSelfScope } from 'common/hoc'
import { TOOLTIP_OFFSET_Y_BOTTOM_BAR } from 'src/hoc/withTextTooltip'
import { ButtonWithTooltip } from 'src/components/Button/Button'
import { TestId } from 'src/constants'

export interface InCallParticipantAudioPttButtonProps {
  callId: string
  disabled?: boolean
}

const ParticipantAudioPttButton: FC<InCallParticipantAudioPttButtonProps> = ({
  callId,
  disabled,
}) => {
  const dispatch = useDispatch()
  const [isPressing, setIsPressing] = useState(false)
  const activeRoomId = useSelector(getActiveCallRoomId)
  const hasSilentMode = useSelector((state: CantinaState) =>
    hasSilentModeSelector(state, activeRoomId)
  )

  const cssClasses = isPressing ? 'ptt-pulse' : ''

  useEffect(() => {
    if (hasSilentMode) {
      dispatch(callViewActions.disablePtt())
    }
  }, [dispatch, hasSilentMode])

  useEffect(() => {
    return () => {
      dispatch(restoreLocalAudioTrack(callId))
    }
  }, [dispatch, callId])

  useEffect(() => {
    const action = isPressing
      ? restoreLocalAudioTrack(callId)
      : stopLocalAudioTrack(callId)

    dispatch(action)
  }, [dispatch, isPressing, callId])

  useHotkeys(
    'x',
    (event: KeyboardEvent) => {
      if (event.type === 'keyup') {
        setIsPressing(false)
      }
      if (event.type === 'keydown') {
        setIsPressing(true)
      }
    },
    { keyup: true, keydown: true }
  )

  return (
    <ButtonWithTooltip
      className={`sw-btn-icon-toggle ${cssClasses}`}
      disabled={disabled}
      onMouseDown={() => setIsPressing(true)}
      onMouseUp={() => setIsPressing(false)}
      onMouseLeave={() => setIsPressing(false)}
      testId={TestId.BottomBarButtonPTT}
      ariaLabel={tr(Label.PUSH_TO_TALK)}
      offsetY={TOOLTIP_OFFSET_Y_BOTTOM_BAR}
      titleSecondary={disabled ? '' : 'X'}
    >
      <WalkieTalkieIcon size='lg' fixedWidth />
    </ButtonWithTooltip>
  )
}

// The InCall button is used in the bottom bar
export const InCallParticipantAudioPttButton: FC<InCallParticipantAudioPttButtonProps> =
  withConferenceAudioMuteSelfScope(ParticipantAudioPttButton)
