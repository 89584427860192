import React, {
  Dispatch,
  FC,
  SetStateAction,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { MediaDevice } from 'common/constants'
import { MicrophoneOnIcon, MicrophoneOffIcon } from 'common/icons'
import { useMicrophonePreview, useMountedRef } from 'common/hooks'
import { useAudioAnalyser } from 'src/effects/useAudioAnalyser'

export const CurrentAudioPreview = () => {
  const [microphoneId] = useMicrophonePreview()
  return <AudioPreview microphoneId={microphoneId} />
}

type AudioPreviewType = {
  microphoneId?: string
}

const AudioPreview: FC<AudioPreviewType> = ({ microphoneId = '' }) => {
  return (
    <div className='h-10'>
      {!microphoneId || microphoneId === MediaDevice.None ? (
        <MicrophoneMeter error={true} />
      ) : (
        <MicrophoneFeed microphoneId={microphoneId} />
      )}
    </div>
  )
}

type MicrophoneFeedType = {
  microphoneId: string
  setDeviceError?: Dispatch<SetStateAction<boolean>>
}

export const MicrophoneFeed: FC<MicrophoneFeedType> = ({
  microphoneId,
  setDeviceError,
}) => {
  const [stream, setStream] = useState<MediaStream>()
  const [error, setError] = useState(false)
  const mountedRef = useMountedRef()

  const cleanup = useCallback((stream: MediaStream) => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop())
    }
  }, [])

  useEffect(() => {
    return () => {
      stream && cleanup(stream)
    }
  }, [cleanup, stream])

  useEffect(() => {
    const constraints: MediaStreamConstraints = {
      audio: {
        deviceId: microphoneId,
      },
    }

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        if (mountedRef.current) {
          setStream(stream)
        } else {
          cleanup(stream)
        }
      })
      .catch((error) => {
        console.error('AudioPreview', error)
        setError(true)
        setDeviceError && setDeviceError(true)
      })
  }, [microphoneId, cleanup, mountedRef, setDeviceError])

  return stream ? (
    <MicrophoneMeterWithStream stream={stream} error={error} />
  ) : null
}

type MicrophoneMeterWithStreamType = {
  stream: MediaStream
  error: any
}

const MicrophoneMeterWithStream: FC<MicrophoneMeterWithStreamType> = ({
  stream,
  error,
}) => {
  const [micEnergy] = useAudioAnalyser({ stream })

  return <MicrophoneMeter micEnergy={micEnergy} error={error} />
}

const STICKS = Array.from({ length: 10 }, (x, i) => i + 1)
type MicrophoneMeterType = {
  micEnergy?: number
  error: any
}

const MicrophoneMeter: FC<MicrophoneMeterType> = ({ micEnergy = 0, error }) => {
  return (
    <div className='flex gap-2 items-center justify-center'>
      <div className='inline rounded-md bg-contrast-l p-2'>
        {error ? (
          <MicrophoneOffIcon size='lg' fixedWidth className='text-sw-red ' />
        ) : (
          <MicrophoneOnIcon size='lg' fixedWidth className='text-contrast-h' />
        )}
      </div>
      <div className='microphone-meter-wrapper'>
        {STICKS.map((index) => (
          <div
            key={index}
            className={`microphone-stick ${
              index <= micEnergy ? 'bg-sw-success' : ''
            }`}
          ></div>
        ))}
      </div>
    </div>
  )
}
