import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Displayable } from 'common/constants'
import { getRelayCallDeviceSetupLabel } from 'common/services/relay'
import { CantinaState } from 'common/redux/interfaces'
import { callHangup, uiActions } from 'common/redux/features'
import { withActiveCall } from 'common/hoc'
import { getCallSecondSourceCallIds } from 'common/redux/features/calls/callSelectors'
import { tr, Label } from 'common/i18n'
import { useMyZone } from 'common/hooks'
import { TestId } from 'src/constants'
import { PrimaryButton, SecondaryButton } from '../Button/Button'

type SecondSourceSettingsProps = {
  callId?: string
  closeHandler: () => void
}

export const SecondSourceSettings = withActiveCall<SecondSourceSettingsProps>(
  ({ callId, closeHandler }) => {
    const dispatch = useDispatch()
    const { isInZone } = useMyZone()
    const secondSourceCallIds = useSelector((state: CantinaState) =>
      getCallSecondSourceCallIds(state, callId)
    )
    const addClickHandler = () => {
      closeHandler()
      dispatch(uiActions.show(Displayable.SecondSourceModal))
    }
    const buttonTitle = isInZone
      ? tr(Label.FEATURE_IS_UNAVAILABLE_IN_SIDEBAR, {
          feature: tr(Label.ADDITIONAL_DEVICES),
        })
      : ''

    return (
      <div className='w-screen max-w-md'>
        <div className='flex items-center p-2 text-base font-medium p-3 border-b border-gray-800'>
          <div className='flex-1 overflow-hidden truncate'>
            {tr(Label.ADDITIONAL_DEVICES)}
          </div>
          <PrimaryButton
            className='text-sm'
            disabled={isInZone}
            onClick={addClickHandler}
            testId={TestId.VideoDevicesButtonAdd}
            title={buttonTitle}
          >
            {tr(Label.ADD)}
          </PrimaryButton>
        </div>
        <div className='switch-device-wrapper divide-y divide-gray-800'>
          {!secondSourceCallIds.length && (
            <p className='p-2'>{tr(Label.NO_ACTIVE_DEVICES)}</p>
          )}
          {secondSourceCallIds.map((callId, index) => (
            <SecondSourceRow
              key={callId}
              callId={callId}
              testId={`${TestId.VideoDevicesButtonRemove}_${index}`}
            />
          ))}
        </div>
      </div>
    )
  }
)

type SecondSourceRowProps = {
  callId: string
  testId: string
}

const SecondSourceRow: FC<SecondSourceRowProps> = ({ callId, testId }) => {
  const dispatch = useDispatch()
  const hangupHandler = () => dispatch(callHangup(callId))

  return (
    <div className='device flex items-center p-2 text-sm hover:bg-gray-800'>
      <div className='flex-1 overflow-hidden pl-4 truncate'>
        {getRelayCallDeviceSetupLabel(callId)}
      </div>
      <SecondaryButton
        onClick={hangupHandler}
        className='text-xs font-medium'
        testId={testId}
      >
        {tr(Label.REMOVE)}
      </SecondaryButton>
    </div>
  )
}
