import React, { FC } from 'react'
import { compose } from 'redux'
import { useDispatch } from 'react-redux'
import { Checkbox } from '../Inputs/Checkbox'
import { callViewActions } from 'common/redux/views'
import { tr, Label } from 'common/i18n'
import { TestId } from 'src/constants'
import { withConferenceGroupsViewRolesAssigned } from 'common/hoc'

// This is its own component just so we can wrap it with a scope

interface GroupRolesAssignedCheckboxProps {
  checked: boolean
}

const GroupRolesAssignedCheckboxFunction: FC<GroupRolesAssignedCheckboxProps> =
  ({ checked }) => {
    const dispatch = useDispatch()

    return (
      <Checkbox
        checked={checked}
        className='w-full'
        label={tr(Label.ROLES_ASSIGNED)}
        name={tr(Label.ROLES_ASSIGNED)}
        onChange={() =>
          dispatch(callViewActions.updateGroupsList('reservationId'))
        }
        testId={TestId.ParticipantsGroupToggleRolesAssigned}
        wrapperClassName='overflow-hidden'
      />
    )
  }

export const GroupRolesAssignedCheckbox = compose<any>(
  withConferenceGroupsViewRolesAssigned
)(GroupRolesAssignedCheckboxFunction)
