import React, { useRef, ComponentType, useEffect } from 'react'
import { Prompt } from 'react-router'
import { useDispatch } from 'react-redux'
import { callHangup } from 'common/redux/features'
import { RoutePath } from 'common/constants'
import { CallPageProps } from './Call'

export const withBackButtonGuard = <T extends CallPageProps>(
  WrappedComponent: ComponentType<T>
) => {
  return (props: T) => {
    const dispatch = useDispatch()
    const backButtonRef = useRef(false)

    useEffect(() => {
      return () => {
        if (backButtonRef.current) {
          dispatch(callHangup(props.callId))
        }
      }
    }, [dispatch, props.callId])

    return (
      <>
        <WrappedComponent {...props} />
        <Prompt
          message={(location, action) => {
            backButtonRef.current = false
            if (action === 'POP') {
              backButtonRef.current = true
            }
            if (
              location.pathname.startsWith(RoutePath.Room.replace(':room/', ''))
            ) {
              return true
            }
            return 'Are you sure you want to leave the call?'
          }}
        />
      </>
    )
  }
}
