import React, { FC, FormEvent, useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RoutePath, TITLE_MAX_CHARS } from 'common/constants'
import { useCreateGroup } from 'common/hooks'
import { withUiSettingsGroupsCreateScope } from 'common/hoc'
import { RequestState } from 'common/rest'
import { tr, Label } from 'common/i18n'
import { getUserAutoCompleteItems } from 'common/redux/features/users/usersSelectors'
import { CantinaState } from 'common/src/redux/interfaces'
import { useInputChange } from 'src/effects/useInputChange'
import { toastError, toastSuccess } from 'src/services/ToastService'
import { Modal } from 'src/modals/Modal'
import { TestId } from 'src/constants'
import { AutoComplete, AutoCompleteItemType } from 'src/components/AutoComplete'
import { InputGroup } from 'src/components/InputGroup/InputGroup'
import { PrimaryButton } from 'src/components/Button/Button'
import { AutoCompleteUserData } from './UserData'

export const CreateNewGroupButton = withUiSettingsGroupsCreateScope(() => {
  const [modalShow, setModalShow] = useState(false)
  const toggleModal = () => setModalShow(!modalShow)

  return (
    <>
      <PrimaryButton onClick={toggleModal}>
        {tr(Label.CREATE_NEW_GROUP)}
      </PrimaryButton>
      {/* It is important to unmount this component when not in use because
        NewGroupModal has a useEffect that will otherwise be called infinitely */}
      {modalShow && (
        <NewGroupModal closeHandler={toggleModal} show={modalShow} />
      )}
    </>
  )
})

type NewGroupModalProps = {
  closeHandler: () => void
  show: boolean
}

const NewGroupModal: FC<NewGroupModalProps> = ({ closeHandler, show }) => {
  const history = useHistory()
  const { group, requestState, create, getError } = useCreateGroup()
  const [values, setValues] = useInputChange({ name: '' })
  const [selectedItems, setSelectedItems] = useState<AutoCompleteItemType[]>([])
  const items = useSelector((state: CantinaState) =>
    getUserAutoCompleteItems(state, [])
  )
  const getItemComponent = useCallback(() => AutoCompleteUserData, [])
  const nameError = getError && getError('name')

  useEffect(() => {
    if (group && requestState === RequestState.Success) {
      toastSuccess(tr(Label.CREATE_GROUP_SUCCESS))
      const url = RoutePath.SettingsGroup.replace(':groupId', group.id)
      history.push(url)
    }
    if (requestState === RequestState.Error) {
      toastError(tr(Label.CREATE_GROUP_ERROR))
    }
  }, [requestState, history, group])

  const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (values.name) {
      const userIds = selectedItems.map((item) => item.id)
      await create({ name: values.name }, userIds)
    }
    return false
  }

  return (
    <Modal
      autoComplete='off'
      confirmHandler={submitHandler}
      confirmLabel={tr(Label.CREATE_GROUP)}
      disabled={!values.name}
      isForm
      closeHandler={closeHandler}
      show={show}
      testId={TestId.SystemSettingsGroupForm}
      title={tr(Label.CREATE_NEW_GROUP)}
    >
      <InputGroup
        autoFocus
        error={nameError && `${tr(Label.GROUP_NAME)} ${nameError}`}
        label={tr(Label.GROUP_NAME)}
        maxLength={TITLE_MAX_CHARS}
        name='name'
        onChange={setValues}
        required
        showCounter
        value={values.name}
      />
      <AutoComplete
        items={items}
        selectedItems={selectedItems}
        selectedItemsChangeHandler={setSelectedItems}
        getItemComponent={getItemComponent}
        inputPlaceholder={Label.SEARCH_USERS}
        inputLabel={Label.SELECT_USERS}
        testId={TestId.AutoCompleteGroup}
      />
    </Modal>
  )
}
