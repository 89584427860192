import React, { FC, MouseEvent } from 'react'
import { LayerPlusIcon } from 'common/icons'
import { tr, Label } from 'common/i18n'
import { useDuplicateRoom } from 'common/hooks'
import { FontAwesomeSizeProp } from 'common/icons/FontAwesomeIcon'
import { Room } from 'common/rest'
import { withTextTooltip } from 'src/hoc/withTextTooltip'

type DuplicateRoomButtonProps = {
  className?: string
  iconSize?: FontAwesomeSizeProp
  refresh: () => Promise<any>
  room: Room
  rooms: Room[]
}

export const DuplicateRoomButton: FC<DuplicateRoomButtonProps> = withTextTooltip()(
  ({ className, iconSize = 'lg', refresh, room, rooms }) => {
    const { duplicate } = useDuplicateRoom()

    const clickHandler = async (event: MouseEvent) => {
      event.preventDefault()
      await duplicate({ room, rooms })
      await refresh()
      return false
    }

    return (
      <button
        onClick={clickHandler}
        className={className}
        aria-label={tr(Label.DUPLICATE_ROOM)}
      >
        <LayerPlusIcon size={iconSize} />
      </button>
    )
  }
)
