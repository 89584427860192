import React, { FC } from 'react'
import { tr, Label } from 'common/i18n'
import { ErrorRobotIcon } from 'common/icons'
import { SimpleHeaderWithoutLink } from 'src/components/Header/SimpleHeader'
import { CenteredBox } from 'src/layout'

export const CantinaSettingsError: FC = () => {
  return (
    <div className='app-root sw-theme-default'>
      <div className='app-main-content'>
        <SimpleHeaderWithoutLink />
        <div className='sw-page-content'>
          <div className='h-full flex flex-col justify-start'>
            <CenteredBox>
              <div className='text-center mb-6'>
                <ErrorRobotIcon />
                <div className='text-contrast-h text-2xl font-neue my-6'>
                  {tr(Label.DOMAIN_NOT_CONFIGURED)}
                </div>
                <div className='text-contrast-h m-auto'>
                  {tr(Label.DOMAIN_NOT_CONFIGURED_BYLINE)}
                </div>
              </div>
            </CenteredBox>
          </div>
        </div>
      </div>
    </div>
  )
}
