import React, { FC } from 'react'
import { compose } from 'redux'
import {
  withConferenceZoneDestroyScope,
  withSidebarConversationsFeatureFlag,
} from 'common/hoc'
import { tr, Label } from 'common/i18n'
import { TestId } from 'src/constants'
import { TertiaryButton } from 'src/components/Button/Button'

type SidebarCloseForAllButtonProps = { clickHandler: any }

const SidebarCloseForAllButton: FC<SidebarCloseForAllButtonProps> = ({
  clickHandler,
}) => {
  return (
    <TertiaryButton
      className='mr-2 text-xs md:text-base'
      onClick={clickHandler}
      testId={TestId.SidebarControlsEndForAllButton}
    >
      <span className='inline sm:hidden'>{tr(Label.CLOSE)}</span>
      <span className='hidden sm:inline'>
        {tr(Label.CLOSE_SIDEBAR_FOR_ALL)}
      </span>
    </TertiaryButton>
  )
}

export default compose<any>(
  withSidebarConversationsFeatureFlag(),
  withConferenceZoneDestroyScope
)(SidebarCloseForAllButton)
