import React, { FC, useEffect, useState, useRef, useCallback } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  useFetchClipeeze,
  useFetchClipeezeList,
  useDeleteClipeeze,
  useDebounce,
} from 'common/hooks'
import { RequestState } from 'common/rest'
import { ClipeezeStatus } from 'common/constants'
import { Clipeeze } from 'common/redux/interfaces'
import { truncate } from 'common/services/helpers'
import {
  SpinnerIcon,
  TimesCircleIcon,
  PlayCircleIcon,
  DownloadIcon,
} from 'common/icons'
import { tr, Label } from 'common/i18n'
import { TestId, SEARCH_DEBOUNCE_DELAY } from 'src/constants'
import { withTextTooltip } from 'src/hoc/withTextTooltip'
import { toastSuccess, toastError } from 'src/services/ToastService'
import {
  ButtonWithConfirmation,
  PrimaryButton,
} from 'src/components/Button/Button'
import { SearchInput } from 'src/components/Inputs/SearchInput'
import { ClipeezeClip } from 'src/components/Clipeeze/ClipeezeClip'
import { ClipeezeUploadModal } from 'src/modals/SystemSettings/ClipeezeUploadModal'
import { Modal } from 'src/modals/Modal'

const DESCRIPTION_TRUNCATE_LEN = 20
const PROCESSING_REFRESH_INTERVAL = 1000 * 2 // 2 seconds
const CLIPEEZE_PAGE_SIZE = 30
const PERCENT_TO_SHOW_BEFORE_LOADING_MORE = 0.8 // 80 percent

type DeleteClipeezeButtonProps = {
  id: string
  description: string
  refresh: () => any
  testId: string
}

const DeleteClipeezeButton = ({
  id,
  description,
  refresh,
  testId,
}: DeleteClipeezeButtonProps) => {
  const { deleteClipeeze, requestState } = useDeleteClipeeze(id)
  const removeHandler = async () => {
    await deleteClipeeze()
  }

  useEffect(() => {
    if (requestState === RequestState.Error) {
      toastError(tr(Label.CLIPEEZE_DELETE_ERROR))
    } else if (requestState === RequestState.Success) {
      toastSuccess(
        tr(Label.CLIPEEZE_DELETE_SUCCESS, {
          title: truncate(description, DESCRIPTION_TRUNCATE_LEN, true),
        })
      )
      refresh()
    }
  }, [requestState, description, refresh])

  return (
    <ButtonWithConfirmation
      ariaLabel={tr(Label.REMOVE)}
      className='text-sw-red'
      onClick={removeHandler}
      testId={testId}
      singleUse
    >
      <TimesCircleIcon />
    </ButtonWithConfirmation>
  )
}

type DownloadClipeezeLinkProps = {
  url: string
  testId: string
}

const DownloadClipeezeLink = ({ url, testId }: DownloadClipeezeLinkProps) => (
  <a
    aria-label={tr(Label.DOWNLOAD)}
    className='mx-2 block'
    href={url}
    rel='noreferrer'
    download
    data-test={testId}
  >
    <DownloadIcon fixedWidth />
  </a>
)
const DownloadClipeezeLinkWithTooltip = withTextTooltip()(DownloadClipeezeLink)

type ClipeezeRowProps = {
  clickHandler: () => void
  clipeeze: Clipeeze
  testId: string
}

const ClipeezeRow = ({
  clipeeze: rowClipeeze,
  clickHandler,
  testId,
}: ClipeezeRowProps) => {
  const [display, setDisplay] = useState(true)
  const [clipeeze, setClipeeze] = useState(rowClipeeze)
  const { clipeeze: refreshedClipeeze, refresh } = useFetchClipeeze(
    rowClipeeze.id
  )
  let statusLabel
  let statusClasses

  const removeRow = useCallback(() => setDisplay(false), [])
  const refreshInterval = useRef<ReturnType<typeof setInterval>>()

  // Refresh clipeeze if it starts off in 'processing' state
  useEffect(() => {
    if (clipeeze.status === ClipeezeStatus.Processing) {
      if (!refreshInterval.current) {
        refreshInterval.current = setInterval(
          refresh,
          PROCESSING_REFRESH_INTERVAL
        )
      }
      return () => {
        refreshInterval.current && clearInterval(refreshInterval.current)
      }
    } else if (refreshInterval.current) {
      clearInterval(refreshInterval.current)
    }
  }, [clipeeze, refresh])

  // Replace current clipeeze if no longer processing
  useEffect(() => {
    if (
      refreshedClipeeze &&
      refreshedClipeeze.status !== ClipeezeStatus.Processing
    ) {
      setClipeeze(refreshedClipeeze)
    }
  }, [refreshedClipeeze])

  switch (clipeeze.status) {
    case ClipeezeStatus.Processing:
      statusLabel = Label.CLIPEEZE_STATUS_PROCESSING
      break
    case ClipeezeStatus.Failed:
      statusLabel = Label.CLIPEEZE_STATUS_FAILED
      statusClasses = 'text-sw-red'
      break
  }

  return (
    <div
      tabIndex={0}
      className={`administration-section-item-title group hover:shadow-md ${
        display ? '' : 'hidden'
      }`}
      data-test={testId}
    >
      <div className='flex items-center overflow-hidden'>
        <ClipeezeClip
          clipeeze={clipeeze}
          clickHandler={clickHandler}
          className='shrink-0 mr-4'
          testId={`${testId}_clip`}
        />
        <div className='text-base font-normal overflow-hidden'>
          <p className='truncate'>{clipeeze.description}</p>
          {clipeeze.status === ClipeezeStatus.Active ? (
            <div>
              <button
                onClick={clickHandler}
                className='text-sw-blue whitespace-nowrap border border-transparent'
                data-test={`${testId}_preview`}
              >
                <PlayCircleIcon size='1x' fixedWidth className='pr-1' />
                {tr(Label.PREVIEW)}
              </button>
            </div>
          ) : (
            statusLabel && <p className={statusClasses}>{tr(statusLabel)}</p>
          )}
        </div>
      </div>
      <div className='item-actions shrink-0 justify-center'>
        {clipeeze.status === ClipeezeStatus.Active && (
          <DownloadClipeezeLinkWithTooltip
            url={clipeeze.source_file_url}
            testId={`${testId}_download`}
          />
        )}
        {clipeeze.status !== ClipeezeStatus.Processing && (
          <DeleteClipeezeButton
            id={clipeeze.id}
            description={clipeeze.description}
            refresh={removeRow}
            testId={`${testId}_delete`}
          />
        )}
      </div>
    </div>
  )
}

interface ClipeezeListUIProps {
  clipeezePagedList: Clipeeze[]
  requestState: RequestState | null
  endOfList: boolean
  refresh: () => void
  preview: (clipeeze: Clipeeze) => void
}

const ClipeezeListUI: FC<ClipeezeListUIProps> = ({
  clipeezePagedList,
  requestState,
  endOfList,
  refresh,
  preview,
}) => {
  if (clipeezePagedList.length || requestState === RequestState.Loading) {
    return (
      <>
        <InfiniteScroll
          dataLength={clipeezePagedList.length}
          next={requestState !== RequestState.Loading ? refresh : () => {}}
          hasMore={!endOfList && requestState !== RequestState.Error}
          loader={<p></p>}
          scrollableTarget={document.querySelector('.sw-page-content')}
          scrollThreshold={PERCENT_TO_SHOW_BEFORE_LOADING_MORE}
        >
          {clipeezePagedList.map((clipeeze: Clipeeze, i: number) => (
            <div key={clipeeze.id}>
              <ClipeezeRow
                clipeeze={clipeeze}
                clickHandler={() => preview(clipeeze)}
                testId={`${TestId.SystemSettingsClipeeze}_${i}`}
              />
            </div>
          ))}
        </InfiniteScroll>
        {requestState === RequestState.Loading ? (
          <SpinnerIcon size='2x' className='block mx-auto' />
        ) : null}
      </>
    )
  } else {
    return <div>{tr(Label.CLIPEEZE_NO_RESULTS)}</div>
  }
}

export const ClipeezeList = () => {
  const [uploadModalShow, setUploadModalShow] = useState(false)
  const [previewClip, setPreviewClip] = useState<Clipeeze>()
  const [searchValue, setSearchValue] = useState('')
  const [previousSearchValue, setPreviousSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(
    searchValue?.trim(),
    SEARCH_DEBOUNCE_DELAY
  )
  const isFiltered = !!debouncedSearchValue

  // Set up fetch hook for main list and load with initial page
  const { clipeezeList, requestState, error, endOfList, refresh } =
    useFetchClipeezeList({
      pageSize: CLIPEEZE_PAGE_SIZE,
      sortColumn: 'date',
      sortOrder: 'desc',
    })

  // Set up fetch hook for filtered list (don't load initially)
  const {
    clipeezeList: clipeezeListFiltered,
    requestState: requestStateFiltered,
    error: errorFiltered,
    endOfList: endOfListFiltered,
    refresh: refreshFiltered,
  } = useFetchClipeezeList({
    pageSize: CLIPEEZE_PAGE_SIZE,
    makeInitialRequest: false,
  })

  // Set up common variables for the list
  const currentRequestState = isFiltered ? requestStateFiltered : requestState
  const currentError = isFiltered ? errorFiltered : error
  const currentEndOfList = isFiltered ? endOfListFiltered : endOfList
  const currentRefresh = isFiltered ? refreshFiltered : refresh

  // Keep two lists of ClipEEze that have been fetched
  const clipeezePagedList = isFiltered ? clipeezeListFiltered : clipeezeList

  useEffect(() => {
    if (debouncedSearchValue && debouncedSearchValue !== previousSearchValue) {
      // Since we have a new filter value, we need to clear our list and
      // request the first page of data
      refreshFiltered({
        description: debouncedSearchValue,
        clear: true,
      })
      setPreviousSearchValue(debouncedSearchValue)
    }
  }, [refreshFiltered, debouncedSearchValue, previousSearchValue])

  useEffect(() => {
    if (currentRequestState === RequestState.Error && currentError) {
      toastError(tr(Label.CLIPEEZE_LIST_ERROR), {
        autoClose: false,
      })
    }
  }, [currentRequestState, currentError])

  return (
    <>
      <div className='administration-section-title'>
        <div className='font-neue font-bold text-contrast-h text-2xl'>
          {tr(Label.CLIPEEZE)}
        </div>
        <PrimaryButton
          onClick={() => setUploadModalShow(true)}
          testId={`${TestId.SystemSettingsClipeeze}_button_add`}
        >
          {tr(Label.ADD)} {tr(Label.CLIPEEZE)}
        </PrimaryButton>
      </div>

      <div className='block-primary administration-section-content'>
        <div className='flex justify-between items-center mb-4'>
          <SearchInput
            className='text-sm mr-4 '
            clearInputFunction={() => setSearchValue('')}
            currentValue={searchValue}
            inputChanged={(e) => {
              setSearchValue(e?.target?.value)
            }}
            name='clipeezeFilter'
            placeholder={tr(Label.SEARCH)}
            testId={TestId.SystemSettingsClipeezeSearch}
          />
        </div>
        <ClipeezeListUI
          clipeezePagedList={clipeezePagedList}
          requestState={currentRequestState}
          endOfList={currentEndOfList}
          refresh={currentRefresh}
          preview={(clip: Clipeeze) => setPreviewClip(clip)}
        />
      </div>

      {uploadModalShow && (
        <ClipeezeUploadModal
          closeHandler={() => setUploadModalShow(false)}
          successHandler={() => {
            setSearchValue('')
            refresh({ clear: true })
          }}
        />
      )}

      {previewClip && (
        <Modal
          closeHandler={() => setPreviewClip(undefined)}
          isPreview
          show={!!previewClip}
          showCancelButton={false}
          showConfirmButton={false}
          testId={TestId.ClipeezePreviewModal}
          title={previewClip.description}
        >
          <video
            autoPlay
            controls
            controlsList='nofullscreen noremoteplayback nodownload'
            disablePictureInPicture
            className='w-full max-h-[70vh] bg-sw-black rounded-b-lg'
          >
            <source src={previewClip.source_file_url} type='video/mp4' />
            <p>{tr(Label.BROWSER_DOES_NOT_SUPPORT_VIDEO)}</p>
          </video>
        </Modal>
      )}
    </>
  )
}
