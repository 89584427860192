import React, { FC, FormEvent, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useCreateRoom } from 'common/hooks'
import { withUiDialerScope } from 'common/hoc'
import { RequestState } from 'common/rest'
import { tr, Label } from 'common/i18n'
import { RoutePath, TITLE_MAX_CHARS } from 'common/constants'
import { toastError, toastSuccess } from 'src/services/ToastService'
import { useInputChange } from 'src/effects/useInputChange'
import { Modal } from 'src/modals/Modal'
import { TestId } from 'src/constants'
import { PrimaryButton } from 'src/components/Button/Button'
import { InputGroup } from 'src/components/InputGroup/InputGroup'
import { TextAreaGroup } from 'src/components/TextAreaGroup/TextAreaGroup'

/**
 * Use withUiDialerScope because it is injected
 * only if the user has at least one roomTemplate
 */
export const CreateNewRoomButton = withUiDialerScope(() => {
  const [modalShow, setModalShow] = useState(false)
  const toggleModal = () => setModalShow(!modalShow)

  return (
    <>
      <PrimaryButton onClick={toggleModal}>
        {tr(Label.CREATE_NEW_ROOM)}
      </PrimaryButton>
      {/* It is important to unmount this component when not in use because
        NewRoomModal has a useEffect that will otherwise be called infinitely */}
      {modalShow && (
        <NewRoomModal closeHandler={toggleModal} show={modalShow} />
      )}
    </>
  )
})

type NewRoomModalProps = {
  closeHandler: () => void
  show: boolean
}

const NewRoomModal: FC<NewRoomModalProps> = ({ closeHandler, show }) => {
  const history = useHistory()
  const { room, requestState, create, getError } = useCreateRoom()
  const [values, setValues] = useInputChange({
    name: '',
    description: '',
    private: false,
  })
  const nameError = getError && getError('name')

  useEffect(() => {
    if (room && requestState === RequestState.Success) {
      toastSuccess(tr(Label.CREATE_ROOM_SUCCESS))
      const url = RoutePath.SettingsRoom.replace(':roomId', room.id)
      history.push(url)
    }
    if (requestState === RequestState.Error) {
      toastError(tr(Label.CREATE_ROOM_ERROR))
    }
  }, [requestState, history, room])

  const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    await create({ name: values.name, room_description: values.description })
    return false
  }

  return (
    <Modal
      autoComplete='off'
      confirmHandler={submitHandler}
      confirmLabel={tr(Label.CREATE_ROOM)}
      closeHandler={closeHandler}
      disabled={!values.name}
      isForm
      show={show}
      testId={TestId.SystemSettingsRoomForm}
      title={tr(Label.CREATE_NEW_ROOM)}
    >
      <InputGroup
        autoFocus
        error={nameError && `${tr(Label.ROOM_NAME)} ${nameError}`}
        label={tr(Label.ROOM_NAME)}
        maxLength={TITLE_MAX_CHARS}
        name='name'
        onChange={setValues}
        required
        showCounter
        value={values.name}
      />
      <TextAreaGroup
        error={getError('description')}
        name='description'
        label={tr(Label.ROOM_DESCR)}
        onChange={setValues}
        value={values.description}
      />
    </Modal>
  )
}
