import React, { ComponentType, FC } from 'react'
import { useSelector } from 'react-redux'
import { useDispatchRoomParticipantCommand } from 'common/hooks'
import {
  FontAwesomeIconProps,
  FontAwesomeSizeProp,
} from 'common/icons/FontAwesomeIcon'
import {
  RoomParticipantCommandPayload,
  CantinaState,
} from 'common/redux/interfaces'
import { Scope } from 'common/constants'
import { hasScope } from 'common/redux/features/scopes/scopesSelectors'
import { TestId } from 'src/constants'
import {
  Button,
  ButtonWithConfirmation,
  ButtonWithTooltip,
} from '../Button/Button'

// TODO: extends ParticipantCommandButtonProps so we can override props?
export interface ParticipantButtonProps {
  className?: string
  clickHandler?: () => void
  disabled?: boolean
  offsetY?: number
  participantId: string
  scope?: Scope
  showDNDLabel?: boolean
  showLabel?: boolean
  size?: FontAwesomeSizeProp
  testId?: TestId | string // TODO: make required
  titleSecondary?: string
}

interface ParticipantCommandButtonProps extends ParticipantButtonProps {
  action?: RoomParticipantCommandPayload
  activated?: boolean
  Icon: ComponentType<Partial<FontAwesomeIconProps>>
  label: string
  confirm?: boolean
}

export interface InCallParticipantButtonProps
  extends Omit<ParticipantButtonProps, 'participantId'> {}

/**
 * ParticipantCommandButton should never be used on its own
 * but instead use the re-exported components at the bottom
 * - ParticipantAudioMuteButton
 * - ParticipantAudioPttButton
 * - ParticipantVideoMuteButton
 * - ParticipantDnDButton
 * - ParticipantHandButton
 * - ParticipantKickButton
 */
export const ParticipantCommandButton: FC<ParticipantCommandButtonProps> = ({
  action,
  activated = false,
  className = '',
  disabled = false,
  clickHandler,
  confirm = false,
  Icon,
  label,
  offsetY,
  participantId,
  scope,
  showLabel,
  size = 'lg',
  testId,
  titleSecondary,
}) => {
  const dispatchRoomParticipantCommand =
    useDispatchRoomParticipantCommand(participantId)
  const activatedClassName = activated ? 'activated' : ''

  // @ts-expect-error
  const inScope = useSelector((state: CantinaState) => hasScope(state, scope))
  if (!inScope) {
    return null
  }

  const buttonClickHandler = () => {
    if (clickHandler) {
      clickHandler()
    }

    if (action) {
      dispatchRoomParticipantCommand(action)
    }
  }

  if (confirm) {
    return (
      <ButtonWithConfirmation
        ariaLabel={showLabel ? '' : label}
        className={`sw-btn-icon-toggle ${className} ${activatedClassName}`}
        disabled={disabled}
        onClick={buttonClickHandler}
        testId={testId}
      >
        <Icon size={size} fixedWidth />
        {showLabel && <span>{label}</span>}
      </ButtonWithConfirmation>
    )
  }

  return showLabel ? (
    <Button
      className={`sw-btn-icon-toggle ${className} ${activatedClassName}`}
      disabled={disabled}
      testId={testId}
      onClick={buttonClickHandler}
    >
      <Icon size={size} fixedWidth />
      <span className='more-menu-item-label'>{label}</span>
    </Button>
  ) : (
    <ButtonWithTooltip
      ariaLabel={label}
      className={`sw-btn-icon-toggle ${className} ${activatedClassName}`}
      disabled={disabled}
      offsetY={offsetY}
      onClick={buttonClickHandler}
      testId={testId}
      titleSecondary={titleSecondary}
    >
      <Icon size={size} fixedWidth />
    </ButtonWithTooltip>
  )
}

export * from './ParticipantAudioMuteButton'
export * from './ParticipantAudioPttButton'
export * from './ParticipantVideoButton'
export * from './ParticipantDnDButton'
export * from './ParticipantHandButton'
export * from './ParticipantKickButton'
