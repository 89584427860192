import { useState, useEffect, ChangeEvent, useCallback, useRef } from 'react'
import { LabelIconState } from 'common/constants'

type UseRoomAutoSaveParams = {
  defaultValue: null | string | boolean | number | string[]
  required?: boolean
  updateRoomHandler: (params: any) => void
}

type ChangeEventType = ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
>
export const useRoomAutoSave = ({
  defaultValue,
  required = false,
  updateRoomHandler,
}: UseRoomAutoSaveParams) => {
  const [value, setValue] = useState(defaultValue ?? '')
  const isFirstRun = useRef(true)
  const [iconState, setIconState] = useState<LabelIconState>(
    LabelIconState.None
  )

  useEffect(() => {
    setValue(defaultValue ?? '')
    let timer: ReturnType<typeof setTimeout>

    if (!isFirstRun.current && iconState === LabelIconState.Loading) {
      setIconState(LabelIconState.Success)
      timer = setTimeout(() => setIconState(LabelIconState.None), 3000)
    }
    isFirstRun.current = false
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  /**
   * Keep track of the current value and automatically check
   * the type of the element: checkbox has different props
   */
  const changeHandler = useCallback(
    ({ target }: ChangeEventType) => {
      if (target.type === 'checkbox') {
        const checked = (target as HTMLInputElement).checked
        setValue(checked)
        updateRoomHandler({
          [target.name]: checked,
        })
      } else {
        setValue(target.value)
        // setIconState and update room in case of a select tag
        if (target.tagName === 'SELECT') {
          setIconState(LabelIconState.Loading)
          updateRoomHandler({
            [target.name]: target.value,
          })
        }
      }
    },
    [updateRoomHandler]
  )

  /**
   * Used on text input and textarea
   */
  const blurHandler = useCallback(
    ({ target }: ChangeEventType) => {
      if (defaultValue === target.value) {
        return console.debug('Prevent auto-save because value has not changed')
      }
      if (
        required &&
        typeof target.value === 'string' &&
        !target.value.trim()
      ) {
        setValue(defaultValue ?? '')
        return console.debug('Prevent auto-save because value is empty')
      }

      setIconState(LabelIconState.Loading)
      updateRoomHandler({
        [target.name]: target.value,
      })
    },
    [updateRoomHandler, defaultValue, required]
  )

  const freeformHandler = useCallback(
    (name: string, value: any) => {
      if (defaultValue === value) {
        return console.debug('Prevent auto-save because not changed')
      }

      setIconState(LabelIconState.Loading)
      updateRoomHandler({
        [name]: value,
      })
    },
    [defaultValue, updateRoomHandler]
  )

  return {
    currentValue: value,
    changeHandler,
    blurHandler,
    freeformHandler,
    iconState,
  }
}
