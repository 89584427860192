import React, { FC, useState, useEffect } from 'react'
import { useCreateUser } from 'common/hooks'
import { withUiSettingsUsersCreateScope } from 'common/hoc'
import { RequestState } from 'common/rest'
import { tr, Label } from 'common/i18n'
import { CURRENT_HOSTNAME, RoutePath, UserActionType } from 'common/constants'
import { PrimaryButton } from 'src/components/Button/Button'
import { UserActionsModal } from 'src/modals/SystemSettings/UserActionsModal'
import { toastError, toastSuccess } from 'src/services/ToastService'

export const AddUserButton: FC<{
  refresh: () => Promise<void>
}> = withUiSettingsUsersCreateScope(({ refresh }) => {
  const [modalShow, setModalShow] = useState(false)
  const toggleModal = () => setModalShow(!modalShow)

  const onAddUserSuccess = () => {
    refresh()
    setModalShow(false)
    toastSuccess(tr(Label.CREATE_USER_SUCCESS))
  }

  return (
    <>
      <PrimaryButton onClick={toggleModal}>
        {tr(Label.CREATE_NEW_USER)}
      </PrimaryButton>
      {/* It is important to unmount this component when not in use because
        UserActionsModal has a useEffect that will otherwise be called infinitely */}
      {modalShow && (
        <AddUserModal
          closeHandler={toggleModal}
          onAddUserSuccess={onAddUserSuccess}
          show={modalShow}
        />
      )}
    </>
  )
})

type AddUserModalProps = {
  closeHandler: () => void
  onAddUserSuccess: () => void
  show: boolean
}

const AddUserModal: FC<AddUserModalProps> = ({
  closeHandler,
  onAddUserSuccess,
  show,
}) => {
  const { requestState, create, getError } = useCreateUser()

  useEffect(() => {
    if (requestState === RequestState.Success) {
      onAddUserSuccess()
    }
    if (requestState === RequestState.Error) {
      toastError(tr(Label.CREATE_USER_ERROR))
    }
  }, [requestState, onAddUserSuccess])

  const formHandler = ({ user }: any) => {
    create({
      company_name: user.companyName,
      domain: CURRENT_HOSTNAME,
      email: user.email,
      invitation_url: `https://${CURRENT_HOSTNAME}${RoutePath.UserSetup}`,
      name: user.name,
      phone_number: user.phoneNumber,
      user_role: user.role,
    })
  }

  return (
    <>
      {show && (
        <UserActionsModal
          actionType={UserActionType.Add}
          closeHandler={closeHandler}
          formHandler={formHandler}
          getError={getError}
          show={show}
        />
      )}
    </>
  )
}
