import React, { FC } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { ParticipantGroup } from './ParticipantGroup'
import { CantinaState } from 'common/redux/interfaces'
import { getParticipantsGroupedByHandRaised } from 'common/redux/views/callView/callViewSelectors'
import { tr, Label } from 'common/i18n'
import { RaiseHandIcon } from 'common/icons'
import { TestId } from 'src/constants'

interface ParticipantGroupHandsRaisedProps {
  className?: string
  roomId: string
}

export const ParticipantGroupHandsRaised: FC<ParticipantGroupHandsRaisedProps> = ({
  className,
  roomId,
}) => {
  const handsRaisedIds = useSelector(
    (state: CantinaState) => getParticipantsGroupedByHandRaised(state, roomId),
    shallowEqual
  )

  return (
    <ParticipantGroup
      className={className}
      heading={tr(Label.HANDS_RAISED)}
      Icon={RaiseHandIcon}
      memberIds={handsRaisedIds}
      groupParticipantTestId='handRaised'
      testId={TestId.ParticipantsGroupHandsRaised}
    />
  )
}
