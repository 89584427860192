import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { RoutePath } from 'common/constants'
import { tr, Label } from 'common/i18n'
import { TestId } from 'src/constants'

type LoginHeaderProps = { email?: string; name?: string }

export const LoginHeader: FC<LoginHeaderProps> = ({ email, name }) => {
  return (
    <>
      <div className='text-center text-contrast-h text-4xl font-neue'>
        {tr(Label.HELLO)} {name ?? ''}!
      </div>
      <div className='text-sm text-contrast-m pb-2 text-center'>
        {email ?? ''}
        {email && (
          <Link
            to={RoutePath.Login}
            className='text-sw-red font-bold ml-2'
            data-test={TestId.LoginButtonNotYou}
          >
            {tr(Label.NOT_YOU)}
          </Link>
        )}
      </div>
    </>
  )
}
