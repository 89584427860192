import React, { FC, useEffect, useRef, useState } from 'react'
import ReactPinField from 'react-pin-field'
import { tr, Label } from 'common/i18n'
import { LeaveIcon } from 'common/icons'
import { LabelIconState } from 'common/constants'
import { RoomTemplateKeys } from 'common/rest'
import { IconState } from 'src/components/IconState'
import { ButtonWithConfirmation } from 'src/components/Button/Button'
import { TestId } from 'src/constants'

type PINInputGroupProps = {
  addFocus?: boolean
  className?: string
  clearButtonFunction?: () => void
  defaultValue?: any
  label: Label
  labelIconState?: LabelIconState
  name: RoomTemplateKeys | 'mod_pin'
  pinError?: boolean
  testId: TestId
  updateFunction: (params: any) => void
}
const PIN_LENGTH = 4

export const PINInputGroup: FC<PINInputGroupProps> = ({
  addFocus,
  className,
  clearButtonFunction,
  defaultValue,
  labelIconState,
  label,
  name,
  pinError = false,
  testId,
  updateFunction,
}) => {
  const ref = useRef<HTMLInputElement[]>([])
  const [error, setError] = useState('')

  useEffect(() => {
    // Populate/clear the saved data
    if (!ref?.current?.every((x) => x.value)) {
      setError('')
    }

    ref?.current?.forEach((x, i) => {
      x.value = defaultValue?.[i] ?? ''
      x.setAttribute('data-test', `${testId}_${i}`)
    })

    // Set the error if we have an invalid PIN
    if (pinError) {
      setError(tr(Label.PIN_ERROR_INVALID))
    }

    // For the PIN prompt, set the focus
    if (addFocus) {
      ref?.current?.[0]?.focus()
    }
  }, [addFocus, defaultValue, pinError, testId])

  const changeHandler = (val: any) => {
    if (val.length === PIN_LENGTH) {
      updateFunction(val)
    } else {
      setError(tr(Label.PIN_ERROR_LENGTH))
    }
  }

  const completeHandler = () => {
    ref?.current?.forEach((input) => input.blur())
    setError('')
  }

  return (
    <div className={`relative ${className ?? ''}`}>
      <label className='text-center sm:text-left block' htmlFor={name}>
        {tr(label)}
        {!!defaultValue?.length && `: ${defaultValue}`}
        {labelIconState && <IconState state={labelIconState} />}
      </label>
      <div className='flex items-center pb-1 justify-center sm:justify-start ml-5 sm:ml-0'>
        <ReactPinField
          className='text-center mr-2 w-12 h-12'
          length={PIN_LENGTH}
          name={name}
          onChange={changeHandler}
          onComplete={completeHandler}
          ref={ref}
          type='pin'
          validate={/^[0-9]$/}
        />
        <div className={`${!!defaultValue?.length ? 'visible' : 'invisible'}`}>
          <ButtonWithConfirmation
            ariaLabel={tr(Label.CLEAR_INPUT)}
            onClick={clearButtonFunction}
            testId={`${testId}_clear`}
          >
            <LeaveIcon className='text-sw-red' fixedWidth />
          </ButtonWithConfirmation>
        </div>
      </div>
      <div
        className={`sw-error-hint w-full text-center sm:text-left ${
          error ? 'visible' : 'invisible'
        }`}
        data-test={TestId.MessageError}
      >
        {error}
      </div>
    </div>
  )
}
