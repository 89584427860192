import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { tr, Label } from 'common/i18n'
import { uiActions } from 'common/redux/features'
import { hasSkipPrejoinPage } from 'common/redux/features/ui/uiSelectors'
import { Checkbox } from 'src/components/Inputs/Checkbox'
import { DevicesPreview } from 'src/components/Devices/DevicesPreview'
import { ButtonGroup } from 'src/components/Button/ButtonGroup'
import { PrimaryButton, SecondaryButton } from 'src/components/Button/Button'

export const PermissionPage = ({ joinHandler }: any) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const cancelHandler = () => history.goBack()
  const skipPrejoinPage = useSelector(hasSkipPrejoinPage)

  useEffect(() => {
    dispatch(uiActions.setPrejoinPageViewed())
  }, [dispatch])

  const toggleSkipPrejoin = () => {
    dispatch(uiActions.setSkipPrejoinPage(!skipPrejoinPage))
  }

  return (
    <div className='text-center mb-4'>
      <div className='flex items-start md:items-center landscape:items-start justify-center m-2 sm:m-0'>
        <div className='block-primary flex flex-col p-4 w-full sm:w-3/4 rounded-lg shadow-lg max-w-6xl'>
          <div className='flex flex-col w-full mx-auto p-2'>
            <DevicesPreview />
          </div>
        </div>
      </div>
      <div className='flex flex-col-reverse sm:flex-col'>
        <ButtonGroup className='mx-2 my-2 sm:my-6'>
          <SecondaryButton onClick={cancelHandler} transparent>
            {tr(Label.CANCEL)}
          </SecondaryButton>
          <PrimaryButton onClick={joinHandler}>
            {tr(Label.JOIN_ROOM)}
          </PrimaryButton>
        </ButtonGroup>
        <Checkbox
          className='mt-2 text-contrast-h'
          onChange={toggleSkipPrejoin}
          checked={skipPrejoinPage}
          name='skip'
          label={tr(Label.SKIP_THIS_PAGE)}
        />
      </div>
    </div>
  )
}
