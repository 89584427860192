import React, { FC, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import { CantinaState, RoomZone } from 'common/redux/interfaces'
import { RoomCommandAction } from 'common/constants'
import { useDispatchRoomCommand } from 'common/hooks'
import { tr, Label } from 'common/i18n'
import { getMyParticipantId } from 'common/redux/features/calls/callSelectors'
import { withSidebarConversationsFeatureFlag } from 'common/hoc'
import {
  getAvatar,
  getName,
  getParticipantRoomZoneId,
} from 'common/redux/features/participants/participantSelectors'
import { toastSuccess } from 'src/services/ToastService'
import { TestId } from 'src/constants'
import { TertiaryButton } from '../Button/Button'

interface JoinZoneButtonProps {
  zone: RoomZone
  className?: string
}

const JoinZoneButtonFunction: FC<JoinZoneButtonProps> = ({
  zone,
  className = '',
}) => {
  const dispatchRoomCommand = useDispatchRoomCommand()
  const requestingParticipantId = useSelector(getMyParticipantId)
  const myZoneId = useSelector((state: CantinaState) =>
    getParticipantRoomZoneId(state, requestingParticipantId)
  )
  const requestingParticipantAvatar = useSelector((state: CantinaState) =>
    getAvatar(state, requestingParticipantId)
  )
  const requestingParticipantName = useSelector((state: CantinaState) =>
    getName(state, requestingParticipantId)
  )
  const requestToJoinZone = useCallback(
    (zone) => {
      dispatchRoomCommand({
        action: RoomCommandAction.ZoneRequestToJoin,
        requestingParticipantAvatar,
        requestingParticipantId,
        requestingParticipantName,
        roomId: zone.roomId,
        roomZoneId: zone.id,
      })

      toastSuccess(tr(Label.SIDEBAR_REQUEST_TO_JOIN_SENT))
    },
    [
      dispatchRoomCommand,
      requestingParticipantAvatar,
      requestingParticipantId,
      requestingParticipantName,
    ]
  )

  // User is already in this zone, so don't show the Join button
  if (myZoneId === `${zone?.id}`) {
    return null
  }

  return (
    <TertiaryButton
      className={`${className} py-1`}
      data-test={TestId.SidebarJoinButton}
      onClick={() => requestToJoinZone(zone)}
    >
      {tr(Label.JOIN)}
    </TertiaryButton>
  )
}

// prettier-ignore
export const JoinZoneButton = compose<FC<JoinZoneButtonProps>>(
  withSidebarConversationsFeatureFlag(),
)(JoinZoneButtonFunction)
