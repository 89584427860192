import React, { FC } from 'react'
import { compose } from 'redux'
import { useSelector } from 'react-redux'
import { useMyZone, useDispatchRoomCommand } from 'common/hooks'
import { withSidebarConversationsFeatureFlag } from 'common/hoc'
import { tr, Label } from 'common/i18n'
import { RoomCommandAction } from 'common/constants'
import { getParticipantRoomZoneId } from 'common/redux/features/participants/participantSelectors'
import { getRoomZone } from 'common/redux/features/roomZones/roomZonesSelectors'
import { isAuthenticated } from 'common/redux/features/auth/authSelectors'
import { CantinaState } from 'common/redux/interfaces'
import {
  getCallRoomId,
  getMyParticipantId,
} from 'common/redux/features/calls/callSelectors'
import { TestId } from 'src/constants'
import { SecondaryButton } from 'src/components/Button/Button'
import SidebarExternalVolumeSlider from 'src/components/CallSettings/SidebarExternalVolumeSlider'
import SidebarCloseForAllButton from './SidebarCloseForAllButton'

type SidebarControlsProps = { callId: string }

const SidebarControlsFunction: FC<SidebarControlsProps> = ({ callId }) => {
  const dispatchRoomCommand = useDispatchRoomCommand()
  const participantId = useSelector(getMyParticipantId)
  const authenticated = useSelector(isAuthenticated)
  const roomId = useSelector((state: CantinaState) =>
    getCallRoomId(state, callId)
  )
  const zoneId = useSelector((state: CantinaState) =>
    getParticipantRoomZoneId(state, participantId)
  ) as string
  const zone = useSelector((state: CantinaState) => getRoomZone(state, zoneId))
  const { isInZone } = useMyZone()

  if (!isInZone) {
    return null
  }

  const partHandler = () =>
    dispatchRoomCommand({
      action: RoomCommandAction.ZonePart,
      participantId,
      roomId,
      zoneName: zone?.name,
    })

  const destroyHandler = () =>
    dispatchRoomCommand({
      action: RoomCommandAction.ZoneDestroy,
      roomId,
      zoneId: zone?.id,
      zoneName: zone?.name,
    })

  return (
    <div className='block-primary static flex justify-start items-center px-4 py-2'>
      <SecondaryButton
        className='mr-2 text-xs md:text-base'
        onClick={partHandler}
        testId={TestId.SidebarControlsLeaveButton}
      >
        {authenticated ? (
          <>
            <span className='inline sm:hidden'>{tr(Label.LEAVE)}</span>
            <span className='hidden sm:inline'>{tr(Label.LEAVE_SIDEBAR)}</span>
          </>
        ) : (
          <span>{tr(Label.LEAVE_SIDEBAR)}</span>
        )}
      </SecondaryButton>
      <SidebarCloseForAllButton clickHandler={destroyHandler} />
      <SidebarExternalVolumeSlider roomId={roomId} zoneId={zoneId} />
    </div>
  )
}

export const SidebarControls: FC<SidebarControlsProps> = compose(
  withSidebarConversationsFeatureFlag()
)(SidebarControlsFunction)
