import React, { FC } from 'react'
import { tr, Label } from 'common/i18n'
import { useSettingsField } from 'common/hooks'
import { SettingsKeys } from 'common/rest'
import { InputGroup } from 'src/components/InputGroup/InputGroup'

type SettingsInputProps = {
  name: SettingsKeys
  label: Label
}
export const SettingsInputGroup: FC<SettingsInputProps> = ({ name, label }) => {
  const { reduxValue, update, iconState } = useSettingsField(name)

  return (
    <InputGroup
      name={name}
      label={tr(label)}
      labelIconState={iconState}
      onBlur={(event) => update(event.target.value)}
      defaultValue={reduxValue as string}
    />
  )
}
