import React, { FC, ChangeEvent } from 'react'
import { withFilter } from 'common/hoc'
import { callViewActions } from 'common/redux/views'
import {
  getParticipantListFilter,
  getParticipantModalFilter,
} from 'common/redux/views/callView/callViewSelectors'
import { TestId } from 'src/constants'
import { SearchInput } from 'src/components/Inputs/SearchInput'

type SearchParticipantListProps = {
  autoFocus?: boolean
  className?: string
  filter: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  clearHandler?: () => void
}

export const SearchParticipantList: FC<SearchParticipantListProps> = ({
  autoFocus,
  className,
  filter,
  onChange,
  clearHandler,
}) => {
  return (
    <SearchInput
      autoFocus={autoFocus}
      className={`w-full text-sm ${className}`}
      clearInputFunction={clearHandler}
      currentValue={filter}
      inputChanged={onChange}
      name='roomNavFilter'
      placeholder='Find users'
      testId={TestId.ParticipantsInputSearch}
    />
  )
}

export const SearchParticipantListWithFilter = withFilter(
  SearchParticipantList,
  callViewActions.setParticipantListFilter,
  getParticipantListFilter
)

export const SearchParticipantModalWithFilter = withFilter(
  SearchParticipantList,
  callViewActions.setParticipantModalFilter,
  getParticipantModalFilter
)
