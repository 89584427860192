import React, { FC } from 'react'
import { compose } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { withActiveCall, withCurrentParticipant } from 'common/hoc'
import { callDTMF } from 'common/redux/features'
import { hasDenoise } from 'common/redux/features/participants/participantSelectors'
import { CantinaState } from 'common/redux/interfaces'
import HorizontalFeatureToggle from 'src/components/Inputs/HorizontalFeatureToggle'
import { TestId } from 'src/constants'

type DenoiseToggleProps = {
  callId: string
  participantId: string
}

export const DenoiseToggle: FC<DenoiseToggleProps> = ({
  callId,
  participantId,
}) => {
  const dispatch = useDispatch()
  const active = useSelector((state: CantinaState) =>
    hasDenoise(state, participantId)
  )
  const toggleHandler = () => dispatch(callDTMF(callId, '##'))

  return (
    <HorizontalFeatureToggle
      onToggleOn={toggleHandler}
      onToggleOff={toggleHandler}
      label='Remove Background Noise'
      checked={active}
      className='text-sm p-2 pl-6'
      testId={TestId.AudioToggleDenoise}
    />
  )
}

export const InCallParticipantDenoiseToggle = compose<
  FC<Partial<DenoiseToggleProps>>
>(
  withCurrentParticipant,
  withActiveCall
)(DenoiseToggle)
