import React, { FC } from 'react'
import { withUiDialerScope } from 'common/hoc'
import { tr, Label } from 'common/i18n'
import { TestId } from 'src/constants'
import { PrimaryButton } from 'src/components/Button/Button'

type MakeRoomProps = {
  className?: string
  clickHandler: () => void
  testId?: TestId
}

/**
 * This button component check for UiDialerScope to
 * make sure the user can create ad-hoc rooms.
 */
export const MakeRoomButton: FC<MakeRoomProps> = withUiDialerScope(
  ({ className = '', clickHandler, testId }) => {
    return (
      <PrimaryButton
        onClick={clickHandler}
        className={className}
        testId={testId}
      >
        {tr(Label.CREATE_ROOM)}
      </PrimaryButton>
    )
  }
)
