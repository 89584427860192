import React, { FC, ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import { withFilter } from 'common/hoc'
import { callViewActions } from 'common/redux/views'
import {
  getRoomNavigatorFilter,
  shouldRoomNavigatorAutoFocus,
} from 'common/redux/views/callView/callViewSelectors'
import { tr, Label } from 'common/i18n'
import { TestId } from 'src/constants'
import { SearchInput } from 'src/components/Inputs/SearchInput'

type SearchRoomNavigatorProps = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  clearHandler?: () => void
  filter: string
}

export const SearchRoomNavigator: FC<SearchRoomNavigatorProps> = ({
  onChange,
  clearHandler,
  filter,
}) => {
  const roomNavigatorAutoFocus = useSelector(shouldRoomNavigatorAutoFocus)

  return (
    <SearchInput
      autoFocus={roomNavigatorAutoFocus}
      className='w-full flex-1 mt-3 mb-4 px-2'
      clearInputFunction={clearHandler}
      currentValue={filter}
      inputChanged={onChange}
      name='roomNavFilter'
      placeholder={tr(Label.FIND_A_ROOM)}
      testId={TestId.RoomNavigatorInputSearch}
    />
  )
}

export const SearchRoomNavigatorWithFilter = withFilter(
  SearchRoomNavigator,
  callViewActions.setRoomNavigatorFilter,
  getRoomNavigatorFilter
)
