import React, { FC, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'
import { useMyZone, useRoomSelector, useDebounce } from 'common/hooks'
import { UsersIcon, FilmIcon, CompassIcon } from 'common/icons'
import { withActiveCall, withHideParticipantListFeatureFlag } from 'common/hoc'
import { tr, Label } from 'common/i18n'
import { callViewActions } from 'common/redux/views'
import { CantinaState } from 'common/redux/interfaces'
import {
  getActiveCallExtension,
  getActiveCallRoomId,
  getCallRoomId,
  isNotSelfModerator,
} from 'common/redux/features/calls/callSelectors'
import {
  canPlayClipeeze,
  getParticipantCount,
} from 'common/redux/features/rooms/roomsSelectors'

import {
  withTextTooltip,
  TOOLTIP_OFFSET_Y_TOP_BAR,
} from 'src/hoc/withTextTooltip'
import { TitleWithTruncationTooltip } from 'src/hoc/withTruncationTooltip'
import { CallDuration } from 'src/components/CallDuration/CallDuration'
import { InviteButton } from 'src/components/CallButtons/InviteButton'
import { RoomRecordingState } from 'src/components/Rooms/RoomRecordingState'
import { RoomStatusIcon } from '../Rooms/RoomStatusIcon'
import { TestId } from 'src/constants'
import { Header } from './Header'

type RoomIdProp = { roomId: string }

const ClipeezeButton = ({ roomId }: RoomIdProp) => {
  const dispatch = useDispatch()
  const canPlay = useRoomSelector(canPlayClipeeze, roomId)
  const { isInZone } = useMyZone()
  if (!canPlay) {
    return null
  }
  const clickHandler = () => dispatch(callViewActions.toggleClipeeze())
  const buttonTitle = isInZone
    ? tr(Label.FEATURE_IS_UNAVAILABLE_IN_SIDEBAR, {
        feature: tr(Label.CLIPEEZE),
      })
    : tr(Label.CLIPEEZE)

  return (
    <button
      className='p-2'
      data-test={TestId.TopBarButtonClipeeze}
      disabled={isInZone}
      onClick={clickHandler}
      aria-label={buttonTitle}
    >
      <FilmIcon size='lg' />
    </button>
  )
}
const ToggleClipeezeButton = withTextTooltip({
  titleSecondary: 'C',
  offsetY: TOOLTIP_OFFSET_Y_TOP_BAR,
})(ClipeezeButton)

const ToggleParticipantListButtonFunction = ({ roomId }: RoomIdProp) => {
  const dispatch = useDispatch()
  const clickHandler = () => dispatch(callViewActions.toggleParticipantList())
  return (
    <button
      className='relative p-2'
      data-test={TestId.TopBarButtonParticipantList}
      onClick={clickHandler}
      aria-label={tr(Label.PARTICIPANT_LIST)}
    >
      <ParticipantListCounter roomId={roomId} />
      <UsersIcon size='lg' />
    </button>
  )
}

/**
 * This is a way to debounce the re-render of ParticipantListCounterUI:
 * - use useDebounce hook with 100ms delay
 * - use memo on <ParticipantListCounterUI /> to re-render only when the participantCount prop change (that is debounced)
 */
const ParticipantListCounter = ({ roomId }: RoomIdProp) => {
  const participantCount = useSelector((state: CantinaState) =>
    getParticipantCount(state, roomId)
  )
  const delayedParticipantCount = useDebounce(participantCount, 100)

  return <ParticipantListCounterUI participantCount={delayedParticipantCount} />
}

const ParticipantListCounterUI: FC<{ participantCount: number }> = memo(
  ({ participantCount }) => {
    return (
      <span className='absolute top-0 right-0 bg-sw-white text-black rounded-full px-1 -mr-1 text-xxs'>
        {participantCount}
      </span>
    )
  }
)

const ToggleParticipantListButton = compose<FC<RoomIdProp>>(
  withHideParticipantListFeatureFlag({
    show: false,
    customSelector: isNotSelfModerator,
  }),
  withTextTooltip({ titleSecondary: 'P', offsetY: TOOLTIP_OFFSET_Y_TOP_BAR })
)(ToggleParticipantListButtonFunction)

const RoomNavigatorButton = () => {
  const dispatch = useDispatch()
  const clickHandler = () => dispatch(callViewActions.toggleRoomNavigator())
  return (
    <button
      className='p-2'
      data-test={TestId.TopBarButtonRoomNav}
      onClick={clickHandler}
      aria-label={tr(Label.ROOM_NAVIGATOR)}
    >
      <CompassIcon size='lg' />
    </button>
  )
}
const ToggleRoomNavigatorButton = withTextTooltip({
  titleSecondary: 'R',
  offsetY: TOOLTIP_OFFSET_Y_TOP_BAR,
})(RoomNavigatorButton)

const Left: FC = () => {
  const roomId = useSelector(getActiveCallRoomId)
  const extension = useSelector(getActiveCallExtension)

  return (
    <div className='flex items-center px-2'>
      <RoomStatusIcon roomId={roomId} className='mr-2' fixedWidth />
      <div className='overflow-hidden'>
        <TitleWithTruncationTooltip
          title={extension}
          className='room-name text-base leading-9 mr-4 truncate'
          offsetY={TOOLTIP_OFFSET_Y_TOP_BAR}
        />
      </div>
      <RoomRecordingState roomId={roomId} showLabel={false} />
      <InviteButton className='p-2 ml-4 text-xs bg-transparent rounded border' />
    </div>
  )
}

const Right: FC = withActiveCall(({ callId }) => {
  const roomId = useSelector((state: CantinaState) =>
    getCallRoomId(state, callId)
  )
  return (
    <div className='flex flex-1 items-center'>
      <div className='hidden md:flex items-center justify-center flex-1'>
        <CallDuration />
      </div>
      <div className='flex items-center justify-end flex-1'>
        <ToggleClipeezeButton roomId={roomId} />
        <ToggleParticipantListButton roomId={roomId} />
        <ToggleRoomNavigatorButton />
      </div>
    </div>
  )
})

const CallHeader = () => {
  return <Header left={Left} right={Right} />
}

export default CallHeader
