import React, { FC, useCallback } from 'react'
import { useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import {
  CogIcon,
  ExternalLinkIcon,
  HeadsetIcon,
  HomeIcon,
  InfoCircleIconSolid,
  LifeRingIcon,
  MoonIcon,
  SignoutIcon,
  SunIcon,
  UserIcon,
} from 'common/icons'
import { tr, Label } from 'common/i18n'
import { uiActions, authActions } from 'common/redux/features'
import { getTheme } from 'common/redux/features/ui/uiSelectors'
import { withAuth, withUiSettingsScope } from 'common/hoc'
import {
  Displayable,
  RoutePath,
  Theme,
  CANTINA_HELP_URL,
} from 'common/constants'
import { getActiveCallId } from 'common/redux/features/calls/callSelectors'
import { TestId, Size } from 'src/constants'
import ToggleButton from 'src/components/Inputs/ToggleButton'
import { CantinaLink } from 'src/components/CantinaLink'
import { MiniProfile } from 'src/components/MiniProfile'

type NavigateToPageItemProps = {
  label: string
  path: RoutePath
  icon: any
  testId: TestId
}

const NavigateToPageItem: FC<NavigateToPageItemProps> = ({
  icon: IconComponent,
  label,
  path,
  testId,
}) => {
  const dispatch = useDispatch()
  const callId = useSelector(getActiveCallId)
  const clickHandler = useCallback(() => {
    dispatch(uiActions.hide(Displayable.AppMenu))
  }, [dispatch])

  return (
    <CantinaLink
      to={path}
      className='app-menu-item justify-between group'
      onClick={clickHandler}
      data-test={testId}
    >
      <div className='flex items-center'>
        <IconComponent size='1x' fixedWidth />
        <div className='app-menu-item-label'>{label}</div>
      </div>
      {callId && (
        <div className='hidden group-hover:block mr-2'>
          <ExternalLinkIcon size='1x' className='text-contrast-m' fixedWidth />
        </div>
      )}
    </CantinaLink>
  )
}

const NavigateToSystemSettings: FC<NavigateToPageItemProps> =
  withUiSettingsScope(NavigateToPageItem)

const LogoutItem = () => {
  const dispatch = useDispatch()
  const clickHandler = useCallback(() => {
    dispatch(authActions.logout())
  }, [dispatch])

  return (
    <button
      className='app-menu-item justify-start align-right'
      onClick={clickHandler}
      data-test={TestId.AppMenuButtonLogout}
    >
      <SignoutIcon size='1x' fixedWidth />
      <div className='app-menu-item-label whitespace-nowrap'>
        {tr(Label.LOG_OUT)}
      </div>
    </button>
  )
}

const QuestionItem = () => {
  const clickHandler = () => window.open(CANTINA_HELP_URL, '_blank')

  return (
    <button
      className='app-menu-item justify-start'
      onClick={clickHandler}
      data-test={TestId.AppMenuButtonHelp}
    >
      <LifeRingIcon size='1x' fixedWidth />
      <div className='app-menu-item-label whitespace-nowrap'>
        {tr(Label.HELP)}
      </div>
    </button>
  )
}

const ThemeItem = () => {
  const dispatch = useDispatch()
  const currentTheme = useSelector(getTheme)
  const opposite = currentTheme === Theme.Light ? Theme.Dark : Theme.Light

  const clickHandler = useCallback(() => {
    dispatch(uiActions.setTheme(opposite))
  }, [dispatch, opposite])

  return (
    <div className='app-menu-item-toggle'>
      <SunIcon size='1x' fixedWidth />
      <ToggleButton
        onChange={clickHandler}
        ariaLabel={tr(Label.DARK_MODE)}
        checked={currentTheme === Theme.Dark}
        className='px-2'
        testId={TestId.AppMenuToggleTheme}
        size={Size.Medium}
      />
      <MoonIcon size='1x' fixedWidth />
    </div>
  )
}

const ProfileItem = () => {
  const dispatch = useDispatch()
  const profileHandler = useCallback(() => {
    dispatch(uiActions.show(Displayable.ProfileModal))
  }, [dispatch])

  return (
    <button
      className='app-menu-item justify-start w-full'
      onClick={profileHandler}
      data-test={TestId.AppMenuButtonAccount}
    >
      <UserIcon size='1x' fixedWidth />
      <div className='app-menu-item-label'>{tr(Label.MY_PROFILE)}</div>
    </button>
  )
}

const ChangeDevicesItem = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const changeDevicesHandler = () => {
    dispatch(uiActions.show(Displayable.DevicesModal))
  }

  // Disable button during pre-join steps
  return (
    <button
      className='app-menu-item justify-start w-full'
      disabled={pathname === RoutePath.Prejoin}
      onClick={changeDevicesHandler}
      data-test={TestId.AppMenuButtonDevices}
    >
      <HeadsetIcon size='1x' fixedWidth />
      <div className='app-menu-item-label'>{tr(Label.CHANGE_DEVICES)}</div>
    </button>
  )
}

const AboutItem = () => {
  const dispatch = useDispatch()
  const clickHandler = () => {
    dispatch(uiActions.show(Displayable.AboutModal))
  }

  return (
    <button
      className='app-menu-item justify-start w-full'
      onClick={clickHandler}
      data-test={TestId.AppMenuButtonAbout}
    >
      <InfoCircleIconSolid size='1x' fixedWidth />
      <div className='app-menu-item-label'>{tr(Label.ABOUT)}</div>
    </button>
  )
}

export const AppMenu = withAuth(() => {
  return (
    <div className='flex flex-col divide-y divide-gray-400'>
      <div className='shrink-0 py-2'>
        <MiniProfile />
      </div>
      <div className='pb-2'>
        <NavigateToPageItem
          path={RoutePath.Home}
          icon={HomeIcon}
          label={tr(Label.HOME)}
          testId={TestId.AppMenuButtonHome}
        />
        <ProfileItem />
        <NavigateToSystemSettings
          path={RoutePath.Settings}
          icon={CogIcon}
          label={tr(Label.ADMINISTRATION)}
          testId={TestId.AppMenuButtonSettings}
        />
        <ChangeDevicesItem />
        <AboutItem />
        <ThemeItem />
      </div>
      <div className='flex flex-wrap flex-1 justify-between items-end content-end'>
        <QuestionItem />
        <LogoutItem />
      </div>
    </div>
  )
})
