import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Displayable, RoomListCommandAction } from 'common/constants'
import { useDispatchRoomListCommand, useRoomSelector } from 'common/hooks'
import { SpinnerIcon } from 'common/icons'
import { tr, Label } from 'common/i18n'
import { uiActions } from 'common/redux/features'
import { getCurrentRoomId } from 'common/redux/views/lobbyView/lobbyViewSelectors'
import { isMediaModalVisible } from 'common/redux/features/ui/uiSelectors'
import { roomExists } from 'common/redux/features/rooms/roomsSelectors'
import { TestId } from 'src/constants'
import { Modal } from './Modal'
import { ScopedPlaybackControls } from 'src/components/CallSettings/PlaybackControls'

type MediaModalProps = {
  testId: TestId
}

export const MediaModal: FC<MediaModalProps> = ({ testId }) => {
  const dispatch = useDispatch()
  const showModal = useSelector(isMediaModalVisible)
  const roomId = useSelector(getCurrentRoomId)
  const closeHandler = () => dispatch(uiActions.hide(Displayable.MediaModal))
  const exists = useRoomSelector(roomExists, roomId)

  const dispatchRoomListCommand = useDispatchRoomListCommand()

  useEffect(() => {
    if (showModal && roomId) {
      dispatchRoomListCommand({
        action: RoomListCommandAction.SubscribeToInfo,
        roomId,
      })
    }

    return () => {
      if (roomId) {
        dispatchRoomListCommand({
          action: RoomListCommandAction.UnsubscribeFromInfo,
          roomId,
        })
      }
    }
  }, [dispatchRoomListCommand, showModal, roomId])

  if (!roomId) {
    return null
  }

  return (
    <Modal
      closeHandler={closeHandler}
      show={showModal}
      showCancelButton={false}
      showConfirmButton={false}
      testId={testId}
      title={tr(Label.MEDIA)}
    >
      {exists ? (
        <ScopedPlaybackControls roomId={roomId} />
      ) : (
        <div className='flex justify-center items-center p-2 gap-3'>
          <div className='text-lg my-1'>{tr(Label.LOADING)}...</div>
          <SpinnerIcon />
        </div>
      )}
    </Modal>
  )
}
