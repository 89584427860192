import React, { useState, FC } from 'react'
import { useSelector } from 'react-redux'
import Clipboard from 'react-clipboard.js'
import { getInviteURL } from 'common/redux/views/inviteView/inviteViewSelectors'
import { tr, Label } from 'common/i18n'
import { TestId } from 'src/constants'
import SubmittableInput from 'src/components/Inputs/SubmittableInput'
import InviteOption from './InviteOption'

type ByLinkProps = {}

const ByLink: FC<ByLinkProps> = () => {
  const [msg, setMsg] = useState(tr(Label.COPY))
  const inviteUrl = useSelector(getInviteURL)

  const copyLinkClickHandler = () => {
    setMsg(tr(Label.COPIED))
  }

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    return false
  }

  return (
    <InviteOption
      description={tr(Label.INVITE_BY_LINK_DESCRIPTION)}
      label={tr(Label.INVITE_BY_LINK)}
    >
      <Clipboard
        component='div'
        data-clipboard-text={inviteUrl}
        onClick={copyLinkClickHandler}
      >
        <SubmittableInput
          className='text-sm'
          currentValue={inviteUrl}
          name='inviteByLink'
          readOnly={true}
          submitButtonLabel={msg}
          submitHandler={submitHandler}
          testId={TestId.InviteInputUrl}
          type='text'
        />
      </Clipboard>
    </InviteOption>
  )
}

export default ByLink
