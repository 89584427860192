import React from 'react'
import { useProductLine } from 'common/hooks'

const SwLogoFull = () => {
  const productLine = useProductLine()

  return (
    <svg
      width='2845'
      height='366'
      viewBox='0 0 3100 366'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='sw-logo-full'
      role='img'
    >
      <g id='Group 67'>
        <path
          d='M319.3 234.315C319.3 244.615 316.5 251.415 310.9 256.915C277.5 290.215 244.1 323.482 210.7 356.715C199.1 368.215 182.1 368.815 170.6 358.315C158.5 347.215 158 328.915 169.8 316.815C184.8 301.515 200.1 286.315 215.4 271.215C233.7 253.015 252 234.815 270.5 216.715C279.4 207.915 291.3 205.615 302.3 210.615C313.5 215.715 318.9 224.595 319.3 234.315Z'
          fill='#044EF4'
        />
        <path
          d='M0.970052 129.315C1.17005 124.115 3.87005 117.515 9.37005 112.115C42.6701 78.915 75.9367 45.7484 109.17 12.615C116.07 5.71503 124.27 2.11503 133.97 3.51503C145.57 5.11503 153.57 11.615 157.37 22.715C161.17 33.815 158.57 43.815 150.37 52.115C134.47 68.215 118.37 84.215 102.37 100.215C84.8701 117.615 67.3367 134.948 49.7701 152.215C40.8701 161.015 28.9701 163.115 17.9701 158.315C7.30005 153.715 0.800052 143.515 0.970052 129.315Z'
          fill='#044EF4'
        />
        <path
          d='M23.7998 167.015C27.5998 166.815 30.6998 166.915 33.7998 166.515C41.6372 165.522 48.9027 161.889 54.3998 156.215C60.7998 149.715 67.1998 143.215 73.6998 136.515C74.8998 137.615 75.6998 138.215 76.3998 138.915L150.2 212.715C158.3 220.815 161.1 230.615 157.9 241.615C154.8 252.215 147.5 258.915 136.7 261.415C127.4 263.515 118.5 261.715 111.6 254.915C82.3998 226.015 53.4998 196.815 23.7998 167.015Z'
          fill='#F72A72'
        />
        <path
          d='M296.7 202.415C281.7 200.915 271 206.715 262 216.615C257.1 222.015 251.8 227.015 246.5 232.315C245.4 231.315 244.6 230.715 243.9 230.015C219.3 205.415 194.667 180.782 170 156.115C161.9 148.015 159.1 138.215 162.4 127.215C165.6 116.615 172.9 109.915 183.7 107.515C193.3 105.415 202.2 107.515 209.1 114.415C237.7 142.815 266.2 171.415 294.7 199.915C295.462 200.667 296.134 201.506 296.7 202.415Z'
          fill='#F72A72'
        />
        <path d='M1960.6 61.915H1952.6V253.915H1960.6V61.915Z' fill='white' />
        <text
          fill='white'
          style={{ whiteSpace: 'pre' }}
          fontFamily='Neue Einstellung'
          fontSize='255'
          fontWeight='500'
          letterSpacing='-0.02em'
        >
          <tspan x='394' y='253.125'>
            SignalWire
          </tspan>
        </text>
        <text
          fill='white'
          style={{ whiteSpace: 'pre', textTransform: 'capitalize' }}
          fontFamily='Neue Einstellung'
          fontSize='255'
          fontWeight='200'
          letterSpacing='0em'
        >
          <tspan x='2221' y='254.25'>
            {productLine}
          </tspan>
        </text>
      </g>
    </svg>
  )
}

// Width and height classes can be used to resize the logo, e.g. 'w-1/2 h-auto'
const SwLogo = ({ className }: { className: string }) => {
  return (
    <svg
      width='1700'
      height='373'
      viewBox='0 0 1700 373'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={`${className}`}
      role='img'
    >
      <g>
        <path
          d='M319.3 234.315C319.3 244.615 316.5 251.415 310.9 256.915C277.5 290.215 244.1 323.482 210.7 356.715C199.1 368.215 182.1 368.815 170.6 358.315C158.5 347.215 158 328.915 169.8 316.815C184.8 301.515 200.1 286.315 215.4 271.215C233.7 253.015 252 234.815 270.5 216.715C279.4 207.915 291.3 205.615 302.3 210.615C313.5 215.715 318.9 224.595 319.3 234.315Z'
          fill='#044EF4'
        />
        <path
          d='M0.970052 129.315C1.17005 124.115 3.87005 117.515 9.37005 112.115C42.6701 78.915 75.9367 45.7484 109.17 12.615C116.07 5.71503 124.27 2.11503 133.97 3.51503C145.57 5.11503 153.57 11.615 157.37 22.715C161.17 33.815 158.57 43.815 150.37 52.115C134.47 68.215 118.37 84.215 102.37 100.215C84.8701 117.615 67.3367 134.948 49.7701 152.215C40.8701 161.015 28.9701 163.115 17.9701 158.315C7.30005 153.715 0.800052 143.515 0.970052 129.315Z'
          fill='#044EF4'
        />
        <path
          d='M23.7998 167.015C27.5998 166.815 30.6998 166.915 33.7998 166.515C41.6372 165.522 48.9027 161.889 54.3998 156.215C60.7998 149.715 67.1998 143.215 73.6998 136.515C74.8998 137.615 75.6998 138.215 76.3998 138.915L150.2 212.715C158.3 220.815 161.1 230.615 157.9 241.615C154.8 252.215 147.5 258.915 136.7 261.415C127.4 263.515 118.5 261.715 111.6 254.915C82.3998 226.015 53.4998 196.815 23.7998 167.015Z'
          fill='#F72A72'
        />
        <path
          d='M296.7 202.415C281.7 200.915 271 206.715 262 216.615C257.1 222.015 251.8 227.015 246.5 232.315C245.4 231.315 244.6 230.715 243.9 230.015C219.3 205.415 194.667 180.782 170 156.115C161.9 148.015 159.1 138.215 162.4 127.215C165.6 116.615 172.9 109.915 183.7 107.515C193.3 105.415 202.2 107.515 209.1 114.415C237.7 142.815 266.2 171.415 294.7 199.915C295.462 200.667 296.134 201.506 296.7 202.415Z'
          fill='#F72A72'
        />
        <text
          fill='var(--fg-contrast-high)'
          style={{ whiteSpace: 'pre' }}
          fontFamily='Neue Einstellung'
          fontSize='255'
          fontWeight='500'
          letterSpacing='-0.02em'
        >
          <tspan x='394' y='253.125'>
            SignalWire
          </tspan>
        </text>
      </g>
    </svg>
  )
}

export { SwLogo, SwLogoFull }
