import React, { FC, ComponentType, useState, useCallback } from 'react'
import { ArrowUpIcon } from 'common/icons'
import { tr, Label } from 'common/i18n'
import { TOOLTIP_OFFSET_Y_BOTTOM_BAR } from 'src/hoc/withTextTooltip'
import { BasicTooltip } from './Tooltip'
import { ButtonWithTooltip } from '../Button/Button'
import { TestId } from 'src/constants'

// Modifiers for the BasicTooltip
const modifiers = [
  {
    name: 'offset',
    options: {
      offset: [0, 10],
    },
  },
  {
    name: 'preventOverflow',
    options: {
      padding: { left: 8, right: 8 },
    },
  },
]

/**
 * This HOC is just a decorator for the in-call buttons.
 * It receives the TooltipContent FC and adds a caret wrapped with a
 * BasicTooltip which opens the TooltipContent.
 */
type withTooltipWrapperProps = {}
export const withTooltipCaret =
  (
    TooltipContent: FC<{ closeHandler: () => void }>,
    tooltipTestId: TestId,
    label: Label
  ) =>
  <T extends object>(WrappedButton: ComponentType<T>) => {
    return (props: T & withTooltipWrapperProps) => {
      const [shown, setShown] = useState(false)
      const closeTooltip = useCallback(() => {
        setShown(false)
      }, [])

      if (!WrappedButton) {
        return null
      }

      return (
        <div className='tooltip-caret-wrapper flex items-start justify-center'>
          <WrappedButton {...props} />
          <BasicTooltip
            placement='top'
            trigger='click'
            modifiers={modifiers}
            tooltip={<TooltipContent closeHandler={closeTooltip} />}
            onVisibilityChange={setShown}
            tooltipShown={shown}
            tooltipClassName='in-call-tooltip'
            hideArrow={true}
          >
            <ButtonWithTooltip
              className='h-8 group'
              ariaLabel={tr(label)}
              testId={tooltipTestId}
              offsetY={TOOLTIP_OFFSET_Y_BOTTOM_BAR}
            >
              <ArrowUpIcon
                size='xs'
                className='sm:group-hover:mb-2 transition-all duration-300 ease-in'
              />
            </ButtonWithTooltip>
          </BasicTooltip>
        </div>
      )
    }
  }
