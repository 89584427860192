import React, { useState } from 'react'
import { Clipeeze } from 'common/redux/interfaces'
import { ClipeezeStatus } from 'common/constants'
import { SpinnerIcon, TimesOctagonIcon } from 'common/icons'
import { tr, Label } from 'common/i18n'

type ClipeezeClipProps = {
  className?: string
  clickHandler?: () => void
  clipeeze: Clipeeze
  testId?: string
}

export const ClipeezeClip = ({
  className = '',
  clickHandler,
  clipeeze,
  testId,
}: ClipeezeClipProps) => {
  const { description, status, thumbnail_url, preview_url } = clipeeze
  const [showPreview, setShowPreview] = useState(false)

  if (status === ClipeezeStatus.Active) {
    const clipImage = showPreview ? preview_url : thumbnail_url

    return (
      <div
        tabIndex={0}
        className={`w-24 cursor-pointer ${className}`}
        onMouseOver={() => setShowPreview(true)}
        onMouseLeave={() => setShowPreview(false)}
        onFocus={() => setShowPreview(true)}
        onBlur={() => setShowPreview(false)}
        onClick={clickHandler}
        data-test={testId}
      >
        <div className='w-full bg-sw-black'>
          <img
            src={clipImage as string}
            className='clipeeze-preview'
            alt={`${description} thumbnail`}
            loading='lazy'
            aria-label={tr(Label.CLICK_TO_PLAY)}
          />
        </div>
      </div>
    )
  }

  const statusIcon =
    status === ClipeezeStatus.Processing ? (
      <SpinnerIcon className='text-sw-blue' size='1x' spin={false} />
    ) : (
      <TimesOctagonIcon className='text-sw-red' size='1x' />
    )

  return (
    <div className='w-24 h-13 mr-4 bg-gray-200 flex flex-row justify-center items-center'>
      {statusIcon}
    </div>
  )
}
