import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getMyDevicesWarningDone,
  isDevicesModalVisible,
} from 'common/redux/features/ui/uiSelectors'
import { Displayable } from 'common/constants'
import { deviceActions, uiActions } from 'common/redux/features'
import { Label, tr } from 'common/i18n'
import { TestId } from 'src/constants'
import { DevicesPreview } from 'src/components/Devices/DevicesPreview'
import { DevicesWarning } from 'src/components/Devices/DevicesWarning'
import { Modal } from 'src/modals/Modal'

export const DevicesModal = ({ testId }: { testId: TestId }) => {
  const dispatch = useDispatch()
  const warningDone = useSelector(getMyDevicesWarningDone)
  const show = useSelector(isDevicesModalVisible)
  const closeHandler = () => dispatch(uiActions.hide(Displayable.DevicesModal))

  const confirmHandler = () => {
    dispatch(deviceActions.promoteDevices())
    closeHandler()
  }

  return (
    <Modal
      cancelHandler={closeHandler}
      closeHandler={closeHandler}
      confirmHandler={confirmHandler}
      confirmLabel={tr(Label.USE_THESE_DEVICES)}
      containerClassName='wide'
      show={show}
      showCancelButton={warningDone}
      showConfirmButton={warningDone}
      testId={testId}
      title={tr(Label.SELECT_DEVICES)}
    >
      {warningDone ? (
        // Step 2
        <DevicesPreview />
      ) : (
        // Step 1
        <DevicesWarning
          cancelHandler={closeHandler}
          onContinue={() => dispatch(uiActions.setMyDevicesWarningDone(true))}
        />
      )}
    </Modal>
  )
}
