import React, { FC, useEffect, useState, useRef, useCallback } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { useInView } from 'react-intersection-observer'
import { isMobileOnly } from 'react-device-detect'
import { RoomType } from 'common/constants'
import { useSmartRoomSelector } from 'common/hooks'
import { CantinaState } from 'common/redux/interfaces'
import {
  getRoomEmptyImages,
  getRoomPreviewImages,
  getRoomPreview,
} from 'common/redux/features/roomList/roomListSelectors'
import {
  getSubdomainURL,
  getPromoImageHost,
} from 'common/redux/features/settings/settingsSelectors'
import { staticRoomImageUrl } from 'common/services/helpers'
import { tr, Label } from 'common/i18n'
import { Size, TestId } from 'src/constants'
import { Modal } from '../../modals/Modal'
import { RoomCardButtons } from '../RoomCardButtons'
import { RoomStatusIcon } from './RoomStatusIcon'

// TODO: the className that's used between components is only used to round the
// bottom corners of preview images/videos. It might be safer to use a boolean.

type RoomPreviewProps = {
  type: string
  roomId: string
  roomName?: string
}

export const RoomPreview: FC<RoomPreviewProps> = ({
  type,
  roomId,
  roomName = '',
}) => {
  const [show, setShow] = useState(false)

  // Provide preview modal on tablet and desktop devices
  return isMobileOnly ? (
    <div className='w-full'>
      <PreviewImageLazy roomId={roomId} extension={roomName} type={type} />
    </div>
  ) : (
    <>
      <div
        onClick={() => setShow(true)}
        className='w-full cursor-zoom-in'
        data-test={TestId.RoomPreviewButtonView}
      >
        <PreviewImageLazy roomId={roomId} extension={roomName} type={type} />
      </div>
      <PreviewModal
        roomId={roomId}
        roomName={roomName}
        show={show}
        closeHandler={() => setShow(false)}
        type={type}
      />
    </>
  )
}

const PreviewImage = ({ className, roomId, type }: any) => {
  const PreviewComponent = type === RoomType.Static ? StaticPreview : Snapshot

  return (
    <div
      aria-label={tr(Label.ROOM_PREVIEW_IMAGE)}
      className='relative grid w-full aspect-video overflow-hidden'
    >
      <PreviewComponent roomId={roomId} className={className} />
      <OverlayImages roomId={roomId} type={type} className={className} />
    </div>
  )
}

const PreviewImageLazy = ({ className, roomId, extension, type }: any) => {
  // root/rootMargin needed to load image before it's scrolled into view
  // threshold is the % (0-1) that needs to be within the margin to trigger inView
  const { ref, inView } = useInView({
    root: document.querySelector('#room-lazy-container'),
    rootMargin: '200px 0px',
    threshold: 0,
    triggerOnce: type === RoomType.Static,
  })
  const PreviewComponent = type === RoomType.Static ? StaticPreview : Snapshot

  return (
    <div
      aria-label={tr(Label.ROOM_PREVIEW_IMAGE)}
      className='relative grid w-full aspect-video overflow-hidden'
      ref={ref}
    >
      {inView ? (
        <>
          <PreviewComponent roomId={roomId} className={className} />
          <OverlayImages roomId={roomId} type={type} className={className} />
        </>
      ) : (
        <StaticImage extension={extension} className={className} />
      )}
    </div>
  )
}

const PreviewModal = ({ closeHandler, roomId, roomName, show, type }: any) => {
  const beforeTitle = (
    <div className='shrink-0 self-center'>
      <RoomStatusIcon
        className='text-contrast-h mr-2'
        fixedWidth
        roomId={roomId}
      />
    </div>
  )
  const afterTitle = (
    <div className='flex flex-1 gap-1'>
      <RoomCardButtons
        extension={roomName}
        joinTestId={TestId.RoomPreviewButtonJoin}
        roomId={roomId}
      />
    </div>
  )

  return (
    <div className='hidden md:block'>
      <Modal
        afterTitle={afterTitle}
        beforeTitle={beforeTitle}
        closeHandler={closeHandler}
        containerClassName={`${Size.Large} room-preview-modal-wrapper`}
        isPreview
        show={show}
        showCancelButton={false}
        showConfirmButton={false}
        testId={TestId.RoomPreviewModal}
        title={roomName}
      >
        <PreviewImage className='rounded-b-lg' roomId={roomId} type={type} />
      </Modal>
    </div>
  )
}

const StaticImage = ({ extension, className = '' }: any) => {
  // Get URL for generic room image with room name
  const roomPreview = useSelector((state: CantinaState) => {
    return getPromoImageHost(state) + staticRoomImageUrl(extension)
  }, shallowEqual)

  return (
    <div
      className={`overlay-mask relative room-preview-snapshot ready ${className}`}
      style={{ backgroundImage: `url(${roomPreview})` }}
    />
  )
}

const StaticPreview = ({ roomId, className = '' }: any) => {
  /**
   * Keep using `getRoomPreview` here instead of `useSmartRoomSelector`
   * because StaticPreview will never be used for the current (live) room.
   */
  const roomPreview = useSelector((state: CantinaState) => {
    return getPromoImageHost(state) + getRoomPreview(state, roomId)
  }, shallowEqual)

  return (
    <div
      className={`overlay-mask relative room-preview-snapshot ready ${className}`}
      style={{ backgroundImage: `url(${roomPreview})` }}
    />
  )
}

const Snapshot = ({ roomId, className = '' }: any) => {
  /**
   * It uses `useSmartRoomSelector` because we need to render
   * the snapshot for the `current` room and we don't want
   * to wait the events from CantinaMgr but instead consume
   * the events from FS right away.
   */
  const roomPreviewPath = useSmartRoomSelector('getRoomPreview', roomId)
  const subdomainURL = useSelector(getSubdomainURL)
  const roomPreview = roomPreviewPath ? `${subdomainURL}${roomPreviewPath}` : ''

  const direction = useRef(false)
  const firstVideo = useRef<HTMLVideoElement>(null)
  const secondVideo = useRef<HTMLVideoElement>(null)

  const onLoadedData = useCallback(() => {
    if (firstVideo.current && secondVideo.current) {
      if (!direction.current) {
        firstVideo.current.classList.remove('ready')
        secondVideo.current.classList.add('ready')
      } else {
        firstVideo.current.classList.add('ready')
        secondVideo.current.classList.remove('ready')
      }
    }
  }, [])

  useEffect(() => {
    if (firstVideo.current && secondVideo.current) {
      if (direction.current) {
        secondVideo.current.src = roomPreview
        // secondVideo.current.classList.remove('ready')
      } else {
        firstVideo.current.src = roomPreview
        // firstVideo.current.classList.remove('ready')
      }
    }

    direction.current = !direction.current
  }, [roomPreview])

  if (!roomPreview) {
    return null
  }

  return (
    <>
      <video
        ref={firstVideo}
        onLoadedData={onLoadedData}
        className={`room-preview-snapshot ready ${className}`}
        autoPlay
        playsInline
        loop
        muted
      />
      <video
        ref={secondVideo}
        onLoadedData={onLoadedData}
        className={`room-preview-snapshot ${className}`}
        autoPlay
        playsInline
        loop
        muted
      />
    </>
  )
}

const OverlayImages = ({ className = '', roomId, type }: any) => {
  const selector =
    type === RoomType.Static ? getRoomEmptyImages : getRoomPreviewImages
  const images = useSelector(
    (state: CantinaState) => selector(state, roomId),
    shallowEqual
  )

  return (
    <>
      {images.map(({ id, src }) => (
        <div
          key={id}
          className={`overlay-mask w-full ${className}`}
          style={{ backgroundImage: `url(${src})` }}
        />
      ))}
    </>
  )
}
