import { useState, useEffect } from 'react'
import { RoomRole, GrantResourceType } from '../constants'
import { RailsEndpoint, RequestState } from '../rest'
import { AccessGrant } from '../rest/interfaces'
import { useInternalAPI } from './useInternalAPI'

type UseAccessGrantsData = {
  accessGrantById: {
    [userId: string]: AccessGrant
  }
  requestState: RequestState
  error: string | null
}

const fetchOptions = {
  endpoint: RailsEndpoint.AccessGrants,
  method: 'GET',
}
const updateOptions = {
  endpoint: RailsEndpoint.AccessGrants,
  method: 'PATCH',
}
const deleteOptions = {
  endpoint: RailsEndpoint.AccessGrants,
  method: 'DELETE',
}

export const useAccessGrants = (
  resourceType: GrantResourceType,
  resourceId: string
) => {
  const baseUrlTransform = (url: string) => {
    return url
      .replace(':resourceType', resourceType)
      .replace(':resourceId', resourceId)
  }

  const { invokeAPI: fetchHandler } = useInternalAPI(fetchOptions)
  const { invokeAPI: updateHandler } = useInternalAPI(updateOptions)
  const { invokeAPI: deleteHandler } = useInternalAPI(deleteOptions)

  const [data, setData] = useState<UseAccessGrantsData>({
    accessGrantById: {},
    requestState: RequestState.Loading,
    error: null,
  })

  const refresh = async () => {
    try {
      setData({
        ...data,
        error: null,
      })
      const response: AccessGrant[] = await fetchHandler({
        urlTransform: baseUrlTransform,
      })
      let accessGrantById: UseAccessGrantsData['accessGrantById'] = {}
      response.forEach((row) => {
        accessGrantById[row.id] = row
      })
      setData({
        accessGrantById,
        requestState: RequestState.Success,
        error: null,
      })
    } catch (error) {
      setData({
        ...data,
        requestState: RequestState.Error,
        error: 'Error loading permissions',
      })
    }
  }

  /**
   * Update AccessGrant grant_type field
   */
  const updateGrantType = async ({
    accessGrantId,
    grantType,
  }: {
    accessGrantId: string
    grantType: RoomRole
  }) => {
    try {
      const response: AccessGrant = await updateHandler({
        urlTransform: (url) => {
          return `${baseUrlTransform(url)}/${accessGrantId}`
        },
        body: JSON.stringify({
          grant_type: grantType,
        }),
      })

      setData({
        ...data,
        accessGrantById: {
          ...data.accessGrantById,
          [response.id]: response,
        },
      })
    } catch (error) {
      console.error('Error updating permission', error)
    }
  }

  /**
   * Update AccessGrant grant_type field
   */
  const deleteAccessGrant = async (accessGrant: AccessGrant) => {
    const { id: accessGrantId } = accessGrant
    try {
      await deleteHandler({
        urlTransform: (url) => {
          return `${baseUrlTransform(url)}/${accessGrantId}`
        },
      })

      delete data.accessGrantById[accessGrantId]
      setData({
        ...data,
        accessGrantById: {
          ...data.accessGrantById,
        },
      })
    } catch (error) {
      console.error('Error deleting permission', error)
    }
  }

  /**
   * Helper methods to expose the internal state to the components
   */
  const getAccessGrantIds = () => Object.keys(data.accessGrantById)

  /**
   * Returns the list of users applying the proper UI filters
   * filterByText: filter on the name only for now
   * filterByUserRole: filter on the grant_type
   */
  const getAccessGrants = (params: { filterByType?: string }) => {
    const { filterByType } = params
    const groups: AccessGrant[] = []
    const users: AccessGrant[] = []
    getAccessGrantIds().forEach((id) => {
      const { grant_owner_type, grant_type } = data.accessGrantById[id]
      switch (grant_owner_type) {
        case 'cantina_group': {
          if (filterByType === 'all' || grant_type === filterByType) {
            groups.push(data.accessGrantById[id])
          }
          break
        }
        case 'cantina_user': {
          if (filterByType === 'all' || grant_type === filterByType) {
            users.push(data.accessGrantById[id])
          }
          break
        }
      }
    })

    return [groups, users]
  }

  useEffect(() => {
    refresh()
  }, [])

  return {
    ...data,
    refresh,
    getAccessGrants,
    updateGrantType,
    deleteAccessGrant,
  }
}
