import { SagaIterator } from 'redux-saga'
import { take, fork, select, put, call, race, delay } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import * as watchRTC from '@testrtc/watchrtc-sdk'
import * as Relay from '../../../services/relay'
import {
  CallStateError,
  EXTENSION_SEPARATOR,
  MediaDevice,
  MixPanelEvent,
} from '../../../constants'
import { getDevices } from '../device/deviceSelectors'
import {
  getName,
  getEmail,
  getCompany,
  getAuthToken,
} from '../auth/authSelectors'
import {
  getAudioConstraints,
  isMuted,
  isVMuted,
} from '../participants/participantSelectors'
import {
  callRequest,
  callHangup,
  callAnswer,
  callToggleHold,
  callScreenShareStart,
  callScreenShareStop,
  callDTMF,
  updateDevice,
  updateMultipleDevices,
  stopLocalAudioTrack,
  restoreLocalAudioTrack,
  stopLocalVideoTrack,
  restoreLocalVideoTrack,
  callActions,
  addSecondSource,
} from '../'
import { callViewActions } from '../../views'
import {
  getCantinaBackendAPIEndpoint,
  hasWatchRTCFeatureFlag,
} from '../settings/settingsSelectors'
import {
  callLoaderBeginAction,
  callLoaderEndAction,
  mixPanelTrackAction,
} from '../../actions'
import { getCallParticipantId, getRTCPeerConfig } from './callSelectors'
import { hasStereoAudioByName } from '../rooms/roomsSelectors'
import { getRoomWebSocketURL } from '../../../rest/getRoomWebSocketURL'
import { Endpoint } from '../../../rest'
import { checkVersionWorker } from '../ui/uiSaga'
import { CallRequestPayload } from '../../interfaces'
import { cantinaTimer } from '../../../services/cantinaTimer'

// prettier-ignore
const ACTIONS = [
  callRequest.type,
  callAnswer.type,
  callToggleHold.type,
  callHangup.type,
  callScreenShareStart.type,
  callScreenShareStop.type,
  callDTMF.type,
  updateDevice.type,
  updateMultipleDevices.type,
  stopLocalAudioTrack.type,
  restoreLocalAudioTrack.type,
  stopLocalVideoTrack.type,
  restoreLocalVideoTrack.type,
  addSecondSource.type,
]

export function* worker({ type, payload }: PayloadAction<any>): SagaIterator {
  switch (type) {
    case callRequest.type: {
      const { task, cancel } = yield race({
        task: call(callRequestWorker, { type, payload }),
        cancel: take(callHangup.type),
      })

      if (cancel) {
        // Delay a bit in case on underlay RTC connection are setup
        yield delay(200)
        yield call(Relay.disconnectFromVertoFS)
      }
      break
    }
    case callAnswer.type:
      return Relay.answerCall(payload)
    case callToggleHold.type:
      return Relay.toggleHoldCall(payload)
    case stopLocalVideoTrack.type: {
      const action = {
        callId: payload,
        isSendingVideo: false,
        isReceivingVideo: true,
      }
      yield put(callActions.updateVideoFlags(action))
      return Relay.stopLocalVideoTrack(payload)
    }
    case restoreLocalVideoTrack.type: {
      const action = {
        callId: payload,
        isSendingVideo: true,
        isReceivingVideo: true,
      }
      yield put(callActions.updateVideoFlags(action))
      return Relay.restoreLocalVideoTrack(payload)
    }
    case stopLocalAudioTrack.type: {
      const action = {
        callId: payload,
        isSendingAudio: false,
        isReceivingAudio: true,
      }
      yield put(callActions.updateAudioFlags(action))
      return Relay.stopLocalAudioTrack(payload)
    }
    case restoreLocalAudioTrack.type: {
      const action = {
        callId: payload,
        isSendingAudio: true,
        isReceivingAudio: true,
      }
      yield put(callActions.updateAudioFlags(action))
      return Relay.restoreLocalAudioTrack(payload)
    }
    case callHangup.type:
      const isMain = Relay.isMainCall(payload)
      yield call(Relay.hangupCall, payload)
      if (isMain) {
        console.warn('Invoke disconnectFromVertoFS [isMain]')
        yield call(Relay.disconnectFromVertoFS)
      }
      return
    case callScreenShareStart.type: {
      const callId = payload
      const relayCall = yield call(Relay.getRelayCall, callId)
      const options = {
        video: {},
        audio: {
          echoCancellation: true,
          noiseSuppression: false,
          autoGainControl: false,
          googAutoGainControl: false,
        },
        userVariables: {
          ...(relayCall.options.userVariables || {}),
          memberCallId: relayCall.id,
          memberId: relayCall.participantId,
        },
      }
      try {
        yield call(Relay.startScreenShare, callId, options)
        yield put(mixPanelTrackAction({ event: MixPanelEvent.ScreenShare }))
        yield put(callViewActions.setScreenShareActive(true))
      } catch (error) {
        yield put(callViewActions.setScreenShareActive(false))
      }
      break
    }
    case callScreenShareStop.type:
      Relay.stopScreenShare(payload)
      yield put(callViewActions.setScreenShareActive(false))
      break
    case callDTMF.type:
      return Relay.dtmfCall(payload.callId, payload.dtmf)
    case updateDevice.type: {
      const { callId, kind, deviceId } = payload
      let constraints: MediaStreamConstraints = {}
      if (kind === 'videoinput') {
        constraints.video = {
          deviceId,
          aspectRatio: 16 / 9,
        }
      } else if (kind === 'audioinput') {
        const participantId = yield select(getCallParticipantId, callId)
        const audioConstraints: MediaTrackConstraints = yield select(
          getAudioConstraints,
          participantId
        )
        if (deviceId && deviceId !== MediaDevice.Default) {
          audioConstraints.deviceId = {
            exact: deviceId,
          }
        }
        constraints.audio = audioConstraints
      } else if (kind === 'audiooutput') {
        const speakerId =
          deviceId && deviceId !== MediaDevice.Default ? deviceId : ''
        return Relay.setAudioOutDevice(callId, speakerId)
      }
      return Relay.updateDevices(callId, constraints)
    }
    case updateMultipleDevices.type: {
      const { callId, cameraId, microphoneId, speakerId } = payload
      const participantId = yield select(getCallParticipantId, callId)
      let constraints: MediaStreamConstraints = {}
      if (cameraId && cameraId !== MediaDevice.None) {
        constraints.video = {
          deviceId: cameraId,
          aspectRatio: 16 / 9,
        }
        const vMuted = yield select(isVMuted, participantId)
        if (vMuted) {
          Relay.dtmfCall(callId, '*0')
        }
      }
      if (microphoneId && microphoneId !== MediaDevice.None) {
        const audioConstraints: MediaTrackConstraints = yield select(
          getAudioConstraints,
          participantId
        )
        if (microphoneId !== MediaDevice.Default) {
          audioConstraints.deviceId = {
            exact: microphoneId,
          }
        }
        constraints.audio = audioConstraints
        const muted = yield select(isMuted, participantId)
        if (muted) {
          Relay.dtmfCall(callId, '0')
        }
      }
      if (speakerId && speakerId !== MediaDevice.None) {
        console.debug('Call update speaker', callId, speakerId)
        const device =
          speakerId && speakerId !== MediaDevice.Default ? speakerId : ''
        Relay.setAudioOutDevice(callId, device)
      }
      return Relay.updateDevices(callId, constraints)
    }
    case addSecondSource.type: {
      const { callId, cameraId, microphoneId } = payload
      const relayCall = yield call(Relay.getRelayCall, callId)
      const options: any = {
        audio: false,
        video: false,
        userVariables: {
          ...(relayCall.options.userVariables || {}),
          memberCallId: relayCall.id,
          memberId: relayCall.participantId,
        },
      }
      if (cameraId && cameraId !== MediaDevice.None) {
        options.video = {
          deviceId: cameraId,
          aspectRatio: 16 / 9,
        }
      }
      if (microphoneId && microphoneId !== MediaDevice.None) {
        options.audio = {
          deviceId: { exact: microphoneId },
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        }
      }
      try {
        yield call(Relay.addSecondSource, callId, options)
        // yield put(mixPanelTrackAction({ event: MixPanelEvent.SecondSource }))
      } catch (error) {
        console.error('addSecondSource', error)
      }
      break
    }
    default:
      console.warn('Unhandled callSaga action', type, payload)
  }
}

export function* watchCalls(actions: string[] = ACTIONS): SagaIterator {
  while (true) {
    const action = yield take(actions)
    yield fork(worker, action)
  }
}

function* callRequestWorker({
  payload,
}: PayloadAction<CallRequestPayload>): SagaIterator {
  yield put(callLoaderBeginAction())
  yield call(checkVersionWorker)
  let { extension, hasVideo = true } = payload
  let vertoHost: string = ''
  const timer = cantinaTimer('cantina:roomReserve')
  try {
    const token = yield select(getAuthToken)
    const url = yield select(getCantinaBackendAPIEndpoint, Endpoint.RoomJoin)
    timer.start()
    const response = yield call(getRoomWebSocketURL, {
      url,
      token,
      roomName: extension,
    })
    vertoHost = response.ws_url
    console.debug('WS URL for:', extension, vertoHost)
    timer.stop()
  } catch (error) {
    console.error('Room Reserve Error', error)
    yield put(
      callActions.setError({ errorType: CallStateError.RoomReserve, error })
    )
    yield put(callLoaderEndAction())
    timer.stop()
    return
  }

  const { cameraId, microphoneId, speakerId } = yield select(getDevices)
  let audio: MediaTrackConstraints | boolean = {
    echoCancellation: true,
    // @ts-ignore
    noiseSuppression: true,
    autoGainControl: true,
  }
  if (microphoneId !== MediaDevice.None) {
    ;(audio as MediaTrackConstraints).deviceId = microphoneId
  } else {
    audio = false
    extension += `${EXTENSION_SEPARATOR}mute`
  }
  let video: MediaTrackConstraints | boolean = false
  if (hasVideo && cameraId !== MediaDevice.None) {
    video = {
      deviceId: cameraId,
      width: { ideal: 1280 },
      height: { ideal: 720 },
    }
  } else {
    extension += `${EXTENSION_SEPARATOR}vmute`
  }
  const callerName = yield select(getName)
  const callerNumber = yield select(getEmail)
  const destinationNumber = extension.trim()
  const options: any = {
    vertoHost,
    destinationNumber,
    audio,
    video,
    negotiateVideo: hasVideo,
    negotiateAudio: true,
    remoteCallerName: destinationNumber,
    remoteCallerNumber: destinationNumber,
    callerName,
    callerNumber,
    experimental: true,
    watchAudioPackets: true,
    watchAudioPacketsTimeout: 2 * 1000,
    autoApplyMediaParams: false,
    useStereo: yield select(hasStereoAudioByName, payload.extension),
    userVariables: {
      name: callerName,
      email: callerNumber,
      company: yield select(getCompany),
    },
  }
  if (![MediaDevice.Default, MediaDevice.None].includes(speakerId)) {
    options.speakerId = speakerId
  }

  const watchRTCEnabled = yield select(hasWatchRTCFeatureFlag)
  if (watchRTCEnabled) {
    /**
     * Init watchRTC SDK to track RTCPeer stats
     */
    if (process.env.REACT_APP_WATCHRTC_API_KEY) {
      watchRTC.init({
        rtcApiKey: process.env.REACT_APP_WATCHRTC_API_KEY,
        rtcRoomId: payload.extension,
        rtcPeerId: callerNumber,
        rtcTags: [Relay.getInstanceName(), destinationNumber],
      })
    } else {
      console.warn(
        'watchRTC enabled but REACT_APP_WATCHRTC_API_KEY is missing.'
      )
    }
  }

  /**
   * This allow us to test custom RTCPeer configs.
   * See __setRTCPeerConfig in "react/src/index.tsx"
   */
  const rtcConfig = yield select(getRTCPeerConfig)
  if (rtcConfig && Object.keys(rtcConfig).length) {
    options.rtcPeerConfig = rtcConfig
  }

  /**
   * This code is for testing the error reporting.
   * Basically we tweak the media constraints to force the browser
   * to fail on the gUM or we change the IceServers so we try to
   * simulate the case where the browser cannot gather candidates.
   */

  /** Set iceServers to empty array */
  // options.iceServers = []

  /** Set audio/video to wrong values */
  // options.audio = {
  //   deviceId: { exact: 'not-exists' },
  // }
  // options.video = {
  //   deviceId: { exact: 'bad-device' },
  //   width: { exact: 128011 },
  //   height: { exact: 72011 },
  // }

  try {
    yield call(Relay.makeCall, options)
  } catch (error) {
    yield put(
      callActions.setError({ errorType: CallStateError.FSConnect, error })
    )
    yield put(callLoaderEndAction())
  }
}
