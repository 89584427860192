import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { getName } from 'common/redux/features/auth/authSelectors'
import { Header } from './Header'
import NoticeableButton from 'src/components/NoticeableButton'
// import { tr, changeLanguage } from 'common/i18n'
import { tr, Label } from 'common/i18n'

const Left: FC = () => {
  const username = useSelector(getName)

  return (
    <>
      <div className='px-2'>
        <h2 className='text-xl font-bold'>
          {tr(Label.HELLO)}, {username}
        </h2>
        {/* <button onClick={() => changeLanguage('es-MX')}>ES |</button>
        <button onClick={() => changeLanguage('en-US')}>| EN</button> */}
      </div>
    </>
  )
}

const Right: FC = () => {
  return (
    <div className='hidden md:flex text-sw-white'>
      <NoticeableButton />
    </div>
  )
}

export const LobbyHeader = () => {
  return <Header left={Left} right={Right} />
}
