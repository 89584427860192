import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { getActiveCallStartedAt } from 'common/redux/features/calls/callSelectors'
import { useInterval } from 'src/effects/useInterval'

export const CallDuration: FC<any> = () => {
  const startedAt = useSelector(getActiveCallStartedAt)
  const [duration, setDuration] = useState('00:00:00')
  useInterval(() => {
    if (startedAt) {
      const duration = new Date(Date.now() - startedAt)
        .toISOString()
        .substr(11, 8)
      setDuration(duration)
    }
  }, 1000)

  return <span className='py-1 px-2 font-mono'>{duration}</span>
}
