import i18n from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'
import es_MX from './locales/es-MX/translations.json'
import en_US from './locales/en-US/translations.json'
import { isDev } from '../services/helpers'

const detectorOptions = {
  // order and from where user language should be detected
  order: [
    'querystring',
    'cookie',
    'localStorage',
    'sessionStorage',
    'navigator',
    'htmlTag',
    'path',
    'subdomain',
  ],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
}

const resources = {
  'en-US': {
    translation: en_US,
  },
  'es-MX': {
    translation: es_MX,
  },
}

export const i18nInit = (LanguageDetector?: any) => {
  // if (LanguageDetector) {
  // detect user language
  // React Web: https://github.com/i18next/i18next-browser-languageDetector
  // React Native: TODO
  // i18n.use(LanguageDetector)
  // }
  i18n
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      debug: isDev(),
      // detection: detectorOptions,
      lng: 'en-US', // required if LanguageDetector is not turned on
      fallbackLng: 'en-US',
      resources,
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
    })
}

export function tr(label: string, vars?: Object) {
  return i18n.t(label, vars)
}

export function changeLanguage(code: string) {
  i18n.changeLanguage(code)
}

export { Trans } from 'react-i18next'
export * from './labels'
