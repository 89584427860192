import React, { FC, useState } from 'react'
import { RoomCommandAction } from 'common/constants'
import { useDispatchRoomCommand } from 'common/hooks'
import { withConferencePlaybackControlsScope } from 'common/hoc'
import { tr, Label } from 'common/i18n'
import { useInputChange } from 'src/effects/useInputChange'
import SubmittableInput from '../Inputs/SubmittableInput'
import { TestId } from 'src/constants'

type SendTTSInputProps = {
  className?: string
  roomId: string
}

export const SendTTSInput: FC<SendTTSInputProps> = ({ className, roomId }) => {
  const dispatchRoomCommand = useDispatchRoomCommand()
  const [loading, setLoading] = useState(false)
  const [values, setValues, bulkUpdate] = useInputChange({ ttsMessage: '' })

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const { ttsMessage } = values
    if (ttsMessage) {
      setLoading(true)
      dispatchRoomCommand({
        roomId,
        action: RoomCommandAction.SendTTS,
        message: ttsMessage,
      })

      setTimeout(() => {
        bulkUpdate({ ttsMessage: '' })
        setLoading(false)
      }, 1000)
    }
  }

  return (
    <SubmittableInput
      canHaveError={false}
      className={className}
      currentValue={values.ttsMessage}
      inputChanged={setValues}
      label={tr(Label.TEXT_TO_SPEECH_LABEL)}
      name='ttsMessage'
      placeholder='Hey there!'
      required={true}
      submitButtonLabel={tr(Label.SEND)}
      submitHandler={submitHandler}
      submitting={loading}
      testId={TestId.MediaInputTextToSpeech}
      type='text'
    />
  )
}

// FIXME: TTS uses ConferencePlaybackControls scope for now
export const ScopedSendTTSInput: FC<SendTTSInputProps> =
  withConferencePlaybackControlsScope(SendTTSInput)
