import React from 'react'
import { isIOS } from 'react-device-detect'
import { withProductName } from 'common/hoc'
import { CenteredBox } from 'src/layout'
import { SimpleHeaderWithoutLink } from 'src/components/Header/SimpleHeader'

const NotSupported = () => (
  <svg
    className='w-full not-supported-svg'
    width='470'
    height='239'
    viewBox='0 0 470 239'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      x='1'
      y='1'
      width='468'
      height='237'
      rx='3'
      fill='white'
      stroke='#044EF4'
      strokeWidth='2'
    />
    <path
      opacity='0.4'
      d='M191.645 141.688V127.312C191.645 126.359 192.026 125.445 192.703 124.771C193.381 124.097 194.3 123.719 195.258 123.719H274.742C275.7 123.719 276.619 124.097 277.297 124.771C277.974 125.445 278.355 126.359 278.355 127.312V141.688C278.355 142.641 277.974 143.555 277.297 144.229C276.619 144.903 275.7 145.281 274.742 145.281H195.258C194.3 145.281 193.381 144.903 192.703 144.229C192.026 143.555 191.645 142.641 191.645 141.688Z'
      fill='#044EF4'
    />
    <path
      d='M235 78.7969C204.065 78.7969 179 103.729 179 134.5C179 165.271 204.065 190.203 235 190.203C265.935 190.203 291 165.271 291 134.5C291 103.729 265.935 78.7969 235 78.7969ZM274.742 145.281H195.258C194.3 145.281 193.381 144.903 192.703 144.229C192.026 143.555 191.645 142.641 191.645 141.688V127.312C191.645 126.359 192.026 125.445 192.703 124.771C193.381 124.097 194.3 123.719 195.258 123.719H274.742C275.7 123.719 276.619 124.097 277.297 124.771C277.974 125.445 278.355 126.359 278.355 127.312V141.688C278.355 142.641 277.974 143.555 277.297 144.229C276.619 144.903 275.7 145.281 274.742 145.281Z'
      fill='#044EF4'
    />
    <path
      d='M1 4C1 2.34315 2.34315 1 4 1H466C467.657 1 469 2.34315 469 4V29H1V4Z'
      fill='white'
      stroke='#044EF4'
      strokeWidth='2'
    />
    <circle cx='15.5' cy='14.5' r='4.5' fill='#C4C4C4' />
    <circle cx='27.5' cy='14.5' r='4.5' fill='#C4C4C4' />
    <circle cx='38.5' cy='14.5' r='4.5' fill='#C4C4C4' />
    <rect x='62' y='8' width='385' height='13' rx='4' fill='#C4C4C4' />
  </svg>
)

export const WebRTCNotSupported = withProductName(({ productName }) => (
  <div className='app-root sw-theme-default'>
    <div className='app-main-content'>
      <SimpleHeaderWithoutLink />
      <div className='sw-page-content'>
        <CenteredBox>
          <div className='text-center mb-6'>
            <NotSupported />
            <div className='text-contrast-h text-3xl font-neue my-6'>
              This browser is not supported
            </div>
            <div className='text-contrast-h m-auto'>
              Unfortunately {productName} is not supported in this browser.
              {isIOS
                ? 'Please visit this site with Safari.'
                : 'We currently support the latest versions of Chrome, Firefox and Safari.'}
            </div>
          </div>
        </CenteredBox>
      </div>
    </div>
  </div>
))
