import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { CantinaState } from 'common/redux/interfaces'
import { RoomCommandAction } from 'common/constants'
import { useDispatchRoomParticipantCommand } from 'common/hooks'
import { SignalAlt1Icon } from 'common/icons'
import { Label } from 'common/i18n'
import { hasLowBitrateMode } from 'common/redux/features/participants/participantSelectors'
import { ButtonFeatureToggle } from 'src/components/Inputs/ButtonFeatureToggle'
import { TestId } from 'src/constants'

type LowBitrateModeToggleProps = {
  participantId: string
}

export const LowBitrateModeToggle: FC<LowBitrateModeToggleProps> = ({
  participantId,
}) => {
  const dispatchRoomParticipantCommand = useDispatchRoomParticipantCommand(
    participantId
  )
  const lowBitrateMode = useSelector((state: CantinaState) =>
    hasLowBitrateMode(state, participantId)
  )

  return (
    <ButtonFeatureToggle
      checked={lowBitrateMode}
      Icon={SignalAlt1Icon}
      label={Label.LOW_BITRATE_MODE}
      onToggleOn={() => {
        dispatchRoomParticipantCommand({
          action: RoomCommandAction.EnableLowBitrateMode,
          participantId,
        })
      }}
      onToggleOff={() => {
        dispatchRoomParticipantCommand({
          action: RoomCommandAction.DisableLowBitrateMode,
          participantId,
        })
      }}
      testId={TestId.AudioToggleLowBitrate}
    />
  )
}
