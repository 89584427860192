import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isKnockingVisible } from 'common/redux/features/ui/uiSelectors'
import { uiActions, callDTMF } from 'common/redux/features'
import { Displayable } from 'common/constants'
import { tr, Label } from 'common/i18n'
import { TestId } from 'src/constants'
import { withActiveCall } from 'common/hoc'
import { KnockIcon } from 'common/icons'
import { Modal } from './Modal'

const KnockModal: FC = withActiveCall(({ callId }) => {
  const dispatch = useDispatch()
  const show = useSelector(isKnockingVisible)

  const closeHandler = () => {
    dispatch(uiActions.hide(Displayable.Knocking))
  }

  const toggleDeaf = () => {
    dispatch(callDTMF(callId, '#2'))
    dispatch(uiActions.hide(Displayable.Knocking))
  }

  return (
    <Modal
      byline={tr(Label.KNOCK_MODAL_BYLINE)}
      cancelLabel={tr(Label.IGNORE)}
      closeHandler={closeHandler}
      confirmHandler={toggleDeaf}
      confirmLabel={tr(Label.KNOCK_MODAL_CONFIRM)}
      testId={TestId.KnockModal}
      show={show}
      title={tr(Label.KNOCK_MODAL_TITLE)}
      TitleIcon={KnockIcon}
    />
  )
})

export default KnockModal
