import React, { FC } from 'react'
import { compose } from 'redux'
import { tr, Label } from 'common/i18n'
import { withAllowStereoAudioFeatureFlag } from 'common/hoc'
import { RoomKeys } from 'common/rest'
import { RoomComponentProps } from './RoomDetail'
import { RoomCheckbox, RoomCheckboxProps } from './RoomCheckbox'

const CHECKBOXES: { name: RoomKeys; label: Label }[] = [
  {
    name: 'auto_end',
    label: Label.AUTO_END,
  },
  {
    name: 'allow_clipeeze',
    label: Label.ALLOW_CLIPEEZE,
  },
  {
    name: 'warn',
    label: Label.WARN_BEFORE_JOIN,
  },
]

const RoomStereoAudioCheckbox: FC<RoomCheckboxProps> = compose(
  withAllowStereoAudioFeatureFlag()
)((props: RoomCheckboxProps) => {
  return <RoomCheckbox {...props} />
})

export const Options: FC<RoomComponentProps> = ({
  room,
  update,
  getErrorByName,
}) => {
  return (
    <>
      <div className='text-contrast-h text-lg font-bold mb-6'>
        {tr(Label.ROOM_OPTIONS)}
      </div>
      {CHECKBOXES.map(({ name, label }) => {
        return (
          <RoomCheckbox
            defaultChecked={Boolean(room?.[name])}
            getErrorByName={getErrorByName}
            key={name}
            label={label}
            name={name}
            update={update}
          />
        )
      })}
      <RoomStereoAudioCheckbox
        defaultChecked={Boolean(room?.stereo_audio)}
        getErrorByName={getErrorByName}
        label={Label.STEREO_AUDIO}
        name='stereo_audio'
        update={update}
      />
    </>
  )
}
