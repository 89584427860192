import React, { FC, ChangeEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import { tr, Label } from 'common/i18n'
import { useAccessGrants } from 'common/hooks'
import { RoomRole, GrantResourceType } from 'common/constants'
import { CantinaState } from 'common/redux/interfaces'
import { getDefaultRoomTemplateId } from 'common/redux/features/settings/settingsSelectors'
import { getRoomTemplateMyRoles } from 'common/redux/features/roomTemplates/roomTemplatesSelectors'
import { Size } from 'src/constants'
import { SelectGroup } from 'src/components/SelectGroup/SelectGroup'
import { RequestStateSwitcher } from '../RequestStateSwitcher'
import { UserData } from '../UserData'
import { GroupData } from '../GroupData'
import { CreateNewAccessGrantButton } from '../CreateNewAccessGrantButton'
import { AccessGrantItem } from '../AccessGrantItem'
import { canManageRoomTemplate } from '../utils'

const SHOW_ALL = 'all'
const showOptions = [
  { id: SHOW_ALL, label: 'All' },
  { id: RoomRole.Inviteable, label: 'Inviteable' },
  { id: RoomRole.Visitor, label: 'Visitors' },
  { id: RoomRole.Attendee, label: 'Attendees' },
  { id: RoomRole.Moderator, label: 'Moderators' },
  { id: RoomRole.Manager, label: 'Managers' },
]

export const Permissions: FC = () => {
  const baseRoomTemplateId = useSelector(getDefaultRoomTemplateId) as string
  const roomTemplateMyRoles = useSelector((state: CantinaState) =>
    getRoomTemplateMyRoles(state, baseRoomTemplateId)
  )
  const hasPermission = canManageRoomTemplate(roomTemplateMyRoles)
  const {
    getAccessGrants,
    requestState,
    refresh,
    error,
    updateGrantType,
    deleteAccessGrant,
  } = useAccessGrants(GrantResourceType.RoomTemplate, baseRoomTemplateId)
  const [filterByType, setFilterByType] = useState(SHOW_ALL)
  const [groups, users] = getAccessGrants({ filterByType })
  // Do not exclude current user and group ids
  const userIds: string[] = [] // users.map(({ grant_owner_id }) => grant_owner_id)
  const groupIds: string[] = [] // groups.map(({ grant_owner_id }) => grant_owner_id)

  return (
    <>
      <div className='text-contrast-h text-lg font-bold mb-6'>
        {tr(Label.PERMISSIONS)}
      </div>
      <RequestStateSwitcher
        requestState={requestState}
        refresh={refresh}
        error={error}
      >
        <div className='flex items-center justify-between mb-4'>
          <SelectGroup
            canHaveError={false}
            className='flex-1 mr-4'
            horizontalLabel
            label='Show'
            name='auto-shuffle'
            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
              setFilterByType(event.target.value)
            }
            options={showOptions}
            selectedId={filterByType}
          />
          <CreateNewAccessGrantButton
            resourceType={GrantResourceType.RoomTemplate}
            resourceId={baseRoomTemplateId}
            refresh={refresh}
            userIds={userIds}
            groupIds={groupIds}
          />
        </div>
        <div className='pt-4 pb-3'>
          {!users?.length && !groups?.length && (
            <p className='text-base py-2'>
              {tr(Label.ACCESS_GRANTS_NO_RESULTS)}
            </p>
          )}
          {users.map((accessGrant) => {
            return (
              <AccessGrantItem
                key={accessGrant.id}
                accessGrant={accessGrant}
                readOnly={!hasPermission}
                updateGrantType={updateGrantType}
                deleteAccessGrant={deleteAccessGrant}
                roleType='room_template_role'
              >
                <UserData
                  userId={accessGrant.grant_owner_id}
                  size={Size.Small}
                />
              </AccessGrantItem>
            )
          })}
          {groups.map((accessGrant) => {
            return (
              <AccessGrantItem
                key={accessGrant.id}
                accessGrant={accessGrant}
                readOnly={!hasPermission}
                updateGrantType={updateGrantType}
                deleteAccessGrant={deleteAccessGrant}
                roleType='room_template_role'
              >
                <GroupData
                  groupId={accessGrant.grant_owner_id}
                  className='ml-2'
                />
              </AccessGrantItem>
            )
          })}
        </div>
      </RequestStateSwitcher>
    </>
  )
}
