import React, { FC } from 'react'
import { compose } from 'redux'
import { useSelector } from 'react-redux'
import { CantinaState } from 'common/redux/interfaces'
import {
  isDeaf,
  isMyself,
} from 'common/redux/features/participants/participantSelectors'
import { withCurrentParticipant, withActiveCall } from 'common/hoc'
import { Scope, RoomCommandAction } from 'common/constants'
import { DnDIcon } from 'common/icons'
import { tr, Label } from 'common/i18n'
import { TestId } from 'src/constants'
import { TOOLTIP_OFFSET_Y_BOTTOM_BAR } from 'src/hoc/withTextTooltip'
import {
  ParticipantCommandButton,
  ParticipantButtonProps,
  InCallParticipantButtonProps,
} from './index'

const ParticipantDnDButton: FC<ParticipantButtonProps> = ({
  participantId,
  scope,
  ...rest
}) => {
  let buttonActivated = false
  let buttonScope = scope ?? Scope.ConferenceDeaf
  let buttonLabel = tr(Label.DO_NOT_DISTURB_ON)
  const buttonAction = {
    participantId,
    action: RoomCommandAction.DeafParticipant,
  }
  const dndEnabled = useSelector((state: CantinaState) =>
    isDeaf(state, participantId)
  )
  const myself = useSelector((state: CantinaState) =>
    isMyself(state, participantId)
  )
  const isOtherUserInDND = dndEnabled && !myself

  if (dndEnabled) {
    buttonActivated = true
    buttonScope = scope ?? Scope.ConferenceUndeaf
    buttonLabel = tr(Label.DND_OFF)
    buttonAction.action = RoomCommandAction.UndeafParticipant
  }

  if (isOtherUserInDND) {
    buttonLabel = tr(Label.DND_UNAVAILABLE)
  }

  return (
    <ParticipantCommandButton
      action={buttonAction}
      activated={buttonActivated}
      disabled={isOtherUserInDND}
      Icon={DnDIcon}
      label={buttonLabel}
      participantId={participantId}
      scope={buttonScope}
      {...rest}
    />
  )
}

const InCallParticipantDnDButton = compose<FC<InCallParticipantButtonProps>>(
  withCurrentParticipant,
  withActiveCall
)((props: ParticipantButtonProps) => (
  <ParticipantDnDButton
    scope={Scope.ConferenceDeafSelf}
    testId={TestId.BottomBarButtonDnd}
    offsetY={TOOLTIP_OFFSET_Y_BOTTOM_BAR}
    titleSecondary={props.disabled ? '' : 'D'}
    {...props}
  />
))

export { ParticipantDnDButton, InCallParticipantDnDButton }
