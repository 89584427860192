import React, { FC } from 'react'
import { compose } from 'redux'
import { tr, Label } from 'common/i18n'
import {
  withAllowStereoAudioFeatureFlag,
  withCustomAppLabelFeatureFlag,
  withCustomCompanyLabelFeatureFlag,
  withCustomNameLabelFeatureFlag,
} from 'common/hoc'
import type { BannerDisplayOption } from 'common/services/interfaces'
import { useRoomTemplateField } from 'common/hooks'
import { RoomTemplateKeys } from 'common/rest'
import { SelectGroup } from 'src/components/SelectGroup/SelectGroup'
import { RoomTemplateCheckbox } from './RoomTemplateCheckbox'
import { SettingsInputGroup } from './SettingsInputGroup'

const BANNER_DISPLAY_VALUES: { id: BannerDisplayOption; label: string }[] = [
  {
    id: 'none',
    label: 'Show Nothing',
  },
  {
    id: 'email',
    label: 'Email Address',
  },
  {
    id: 'company',
    label: 'Company Name',
  },
]

const CHECKBOXES: { name: RoomTemplateKeys; label: Label }[] = [
  {
    name: 'auto_end',
    label: Label.AUTO_END,
  },
  {
    name: 'allow_clipeeze',
    label: Label.ALLOW_CLIPEEZE,
  },
  {
    name: 'warn',
    label: Label.WARN_BEFORE_JOIN,
  },
  {
    name: 'chat_disabled',
    label: Label.DISABLE_CHAT,
  },
  {
    name: 'chat_one_way',
    label: Label.ONE_WAY_CHAT,
  },
  {
    name: 'adhoc_rooms_public',
    label: Label.ADHOC_ROOMS_PUBLIC,
  },
]

export const Options: FC = () => {
  return (
    <>
      <div className='text-contrast-h text-lg font-bold mb-6'>
        {tr(Label.OPTIONS)}
      </div>
      {CHECKBOXES.map(({ name, label }) => {
        return <RoomTemplateCheckbox key={name} name={name} label={label} />
      })}
      <RoomTemplateStereoAudioCheckbox />
      <AutoSaveBannerDisplayOption />
      <SettingsCustomAppLabelInput />
      <SettingsCustomNameLabelInput />
      <SettingsCustomCompanyLabelInput />
    </>
  )
}

// prettier-ignore
const SettingsCustomAppLabelInput = compose(
  withCustomAppLabelFeatureFlag()
)(() => {
  return (
    <SettingsInputGroup
      name='custom_app_label'
      label={Label.CUSTOM_APP_LABEL}
    />
  )
})

// prettier-ignore
const SettingsCustomNameLabelInput = compose(
  withCustomNameLabelFeatureFlag()
)(() => {
  return (
    <SettingsInputGroup
      name='custom_name_label'
      label={Label.CUSTOM_NAME_LABEL}
    />
  )
})

// prettier-ignore
const SettingsCustomCompanyLabelInput = compose(
  withCustomCompanyLabelFeatureFlag()
)(() => {
  return (
    <SettingsInputGroup
      name='custom_company_label'
      label={Label.CUSTOM_COMPANY_LABEL}
    />
  )
})

// prettier-ignore
const RoomTemplateStereoAudioCheckbox = compose(
  withAllowStereoAudioFeatureFlag()
)(() => {
  return <RoomTemplateCheckbox name='stereo_audio' label={Label.STEREO_AUDIO} />
})

const AutoSaveBannerDisplayOption = () => {
  const { reduxValue, update, iconState } = useRoomTemplateField(
    'banner_display_option'
  )

  return (
    <SelectGroup
      name='banner_display_option'
      label={tr(Label.BANNER_DISPLAY_OPTION)}
      labelIconState={iconState}
      onChange={(event) => update(event.target.value)}
      defaultSelectedId={reduxValue as string}
      options={BANNER_DISPLAY_VALUES}
    />
  )
}
