import React from 'react'
import { useSelector } from 'react-redux'
import { useRoomSelector } from 'common/hooks'
import { CantinaState } from 'common/redux/interfaces'
import { getCallRoomId } from 'common/redux/features/calls/callSelectors'
import {
  hasFilesFullScreen,
  hasMohPlaying,
} from 'common/redux/features/rooms/roomsSelectors'
import { isPipEnabled } from 'common/redux/views/callView/callViewSelectors'
import { ParticipantLayers } from './ParticipantLayers'
import { EmptyLayers } from './EmptyLayers'
import { MediaLayers } from './MediaLayers'
import { StopFullScreenPlaybackLayer } from './StopFullScreenPlaybackLayer'
import { StopMohLayer } from './StopMohLayer'

export const Layers = ({ callId }: { callId: string }) => {
  const roomId = useSelector((state: CantinaState) =>
    getCallRoomId(state, callId)
  )
  const mohPlaying = useSelector((state: CantinaState) =>
    hasMohPlaying(state, roomId)
  )
  const filesFullScreen = useRoomSelector(hasFilesFullScreen, roomId)
  const isPip = useSelector(isPipEnabled)

  return (
    <div>
      {!filesFullScreen && !isPip && (
        <ParticipantLayers callId={callId} roomId={roomId} />
      )}
      <EmptyLayers callId={callId} />
      <MediaLayers callId={callId} roomId={roomId} />
      {filesFullScreen && <StopFullScreenPlaybackLayer roomId={roomId} />}
      {mohPlaying && <StopMohLayer callId={callId} />}
    </div>
  )
}
