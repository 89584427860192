import React, { FC, useEffect, useState, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useCameraPreview,
  useCameras,
  useChangeDevice,
  useMicrophonePreview,
  useMicrophones,
  useSpeakerPreview,
  useSpeakers,
} from 'common/hooks'
import { deviceActions } from 'common/redux/features'
import { getDeviceErrors } from 'common/redux/features/calls/callSelectors'
import { tr, Label } from 'common/i18n'
import { SoundFile } from 'common/constants'
import {
  CameraOnIcon,
  MicrophoneOnIcon,
  PlayIcon,
  StopIcon,
  VolumeUpIcon,
} from 'common/icons'
import { SelectGroup } from 'src/components/SelectGroup/SelectGroup'
import { CurrentVideoPreview } from 'src/components/VideoPreview'
import { CurrentAudioPreview } from 'src/components/AudioPreview'
import { ButtonWithTooltip } from '../Button/Button'
import { TestId } from '../../constants'
import { audioFiles } from '../../services/SoundCueService'

type UpdateHandler = (event: ChangeEvent<HTMLSelectElement>) => void

type DevicesPreviewProps = {
  hideSpeakers?: boolean
  updateFn?: UpdateHandler
}

const soundCue = audioFiles[SoundFile.Ringer]

export const DevicesPreview: FC<DevicesPreviewProps> = ({
  hideSpeakers = false,
  updateFn,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(deviceActions.clearPreviewDevices())
    }
  }, [dispatch])

  return (
    <div className='flex flex-col gap-6 sm:gap-4 sm:flex-row'>
      <div className='sm:w-1/2 sm:border-r sm:pr-4 border-gray-400'>
        <div className='mb-2'>
          <CurrentVideoPreview />
        </div>
        <CurrentAudioPreview />
      </div>
      <div className='flex flex-col gap-3 justify-center sm:w-1/2 sm:pl-2'>
        <CameraSelectGroup updateFn={updateFn} />
        <MicrophoneSelectGroup updateFn={updateFn} />
        {!hideSpeakers && <SpeakerSelectGroup updateFn={updateFn} />}
      </div>
    </div>
  )
}

type SelectGroupProps = {
  updateFn?: UpdateHandler
}

const CameraSelectGroup: FC<SelectGroupProps> = ({ updateFn }) => {
  const { devices: cameras } = useCameras({
    injectDefaultDevice: true,
    injectDisabledDevice: true,
  })

  // Set the preview value in redux
  const changePreviewDevices = useChangeDevice(
    cameras,
    deviceActions.previewCameraChanged
  )
  const [selectedId] = useCameraPreview()
  const disabled = cameras.length === 0
  const deviceErrors = useSelector(getDeviceErrors)
  const [initialError, setInitialError] = useState(
    deviceErrors?.video ? tr(Label.DEVICE_CONNECTION_ERROR) : ''
  )

  const changeHandler = (event: any) => {
    setInitialError('')
    changePreviewDevices(event)
    updateFn && updateFn(event)
  }

  return (
    <SelectGroup
      disabled={disabled}
      error={initialError}
      IconLabel={CameraOnIcon}
      label={tr(Label.CAMERA)}
      name='camera'
      onChange={changeHandler}
      options={cameras}
      selectedId={selectedId}
      testId={TestId.VideoDeviceDropdown}
    />
  )
}

const MicrophoneSelectGroup = ({ updateFn }: { updateFn?: UpdateHandler }) => {
  const { devices: microphones } = useMicrophones({
    injectDefaultDevice: true,
    injectDisabledDevice: true,
  })
  // Set the preview value in redux
  const changePreviewDevices = useChangeDevice(
    microphones,
    deviceActions.previewMicrophoneChanged
  )
  const [selectedId] = useMicrophonePreview()
  const disabled = microphones.length === 0

  const deviceErrors = useSelector(getDeviceErrors)
  const [initialError, setInitialError] = useState(
    deviceErrors?.audio ? tr(Label.DEVICE_CONNECTION_ERROR) : ''
  )

  const changeHandler = (event: any) => {
    setInitialError('')
    changePreviewDevices(event)
    updateFn && updateFn(event)
  }

  return (
    <SelectGroup
      disabled={disabled}
      error={initialError}
      IconLabel={MicrophoneOnIcon}
      label={tr(Label.MICROPHONE)}
      name='microphone'
      onChange={changeHandler}
      options={microphones}
      selectedId={selectedId}
      testId={TestId.AudioDeviceDropdown}
    />
  )
}

const SpeakerSelectGroup = ({ updateFn }: { updateFn?: UpdateHandler }) => {
  const { devices: speakers } = useSpeakers({
    injectDefaultDevice: true,
  })
  // Set the preview value in redux
  const changePreviewDevices = useChangeDevice(
    speakers,
    deviceActions.previewSpeakerChanged
  )
  const [selectedId] = useSpeakerPreview()

  const changeHandler = (event: any) => {
    changePreviewDevices(event)
    updateFn && updateFn(event)
  }

  // Only show input field if there are speakers to choose from
  return speakers?.length ? (
    <div className='sm:flex sm:items-start sm:gap-1'>
      <SelectGroup
        className='sm:grow'
        IconLabel={VolumeUpIcon}
        label={tr(Label.SPEAKER)}
        name='speaker'
        onChange={changeHandler}
        options={speakers}
        selectedId={selectedId}
        testId={TestId.SpeakerDeviceDropdown}
      />
      <AudioTestButton />
    </div>
  ) : null
}

const AudioTestButton = () => {
  const [isPlaying, setIsPlaying] = useState(false)
  const IconComponent = isPlaying ? StopIcon : PlayIcon

  const soundCueEnded = () => {
    setIsPlaying(false)
  }

  const toggleSoundCue = () => {
    if (isPlaying) {
      // Stop sound effect and reset to the beginning of the audio track
      soundCue.pause()
      soundCue.load()
      setIsPlaying(false)
    } else {
      soundCue.play()
      setIsPlaying(true)
    }
  }

  useEffect(() => {
    soundCue.addEventListener('ended', soundCueEnded)

    return () => {
      soundCue.removeEventListener('ended', soundCueEnded)
      soundCue.pause()
    }
  }, [])

  return (
    <ButtonWithTooltip
      ariaLabel={tr(Label.PLAY_AUDIO_TEST)}
      onClick={toggleSoundCue}
      className='h-10 sm:px-3 mt-2 sm:mt-0 sw-btn-primary sw-btn-transparent w-full sm:w-auto'
    >
      <span className='sm:hidden mr-2'>{tr(Label.PLAY_AUDIO_TEST)}</span>
      <IconComponent />
    </ButtonWithTooltip>
  )
}
