import React, { FC, memo } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
// @ts-expect-error
import AutoSizer from 'react-virtualized-auto-sizer'
// @ts-expect-error
import { FixedSizeList as ReactWindowList } from 'react-window'
import { useDebounce, useLiveArrayBootstrap } from 'common/hooks'
import { CantinaState } from 'common/redux/interfaces'
import { callViewActions } from 'common/redux/views'
import { getParticipantTestIdSuffix } from 'src/services/Helpers'
import {
  getRoomParticipantListIds,
  getParticipantListFilter,
} from 'common/redux/views/callView/callViewSelectors'
import { hasScope } from 'common/redux/features/scopes/scopesSelectors'
import { Scope } from 'common/constants'
import { tr, Label } from 'common/i18n'
import { TestId, Size } from 'src/constants'
import { SecondaryButton } from '../Button/Button'
import { ParticipantListRowActions } from './ParticipantListRowActions'
import { ParticipantListRowDetails } from './ParticipantListRowDetails'
import { ParticipantListRowStatus } from './ParticipantListRowStatus'

interface ParticipantListProps {
  roomId: string
}

/**
 * Debounce the re-render of ParticipantList using
 * useDebounce hook with 100ms delay
 */
export const ParticipantList: FC<ParticipantListProps> = ({ roomId }) => {
  useLiveArrayBootstrap({ roomId })

  const participantIds: string[] = useSelector(
    (state: CantinaState) => getRoomParticipantListIds(state, roomId),
    shallowEqual
  )

  const delayedParticipantIds = useDebounce(participantIds, 100)

  if (!delayedParticipantIds.length) {
    return <NoParticipants />
  }

  return <ParticipantListUI participantIds={delayedParticipantIds} />
}

/**
 * Use react-window to show only
 * visible rows instead of the whole list
 */
const ParticipantListUI: FC<{ participantIds: string[] }> = memo(
  ({ participantIds }) => {
    return (
      <AutoSizer disableWidth>
        {({ height, width }: any) => {
          return (
            <ReactWindowList
              className='participant-list'
              height={height}
              width={width}
              itemCount={participantIds.length}
              itemKey={(index: number) => participantIds[index]}
              itemSize={63}
              overscanCount={50}
            >
              {({ index, style }: any) => {
                return (
                  <div style={style}>
                    <ParticipantRow
                      className='px-2'
                      participantId={participantIds[index]}
                      testIdSuffix={getParticipantTestIdSuffix({
                        rowIndex: index,
                      })}
                    />
                  </div>
                )
              }}
            </ReactWindowList>
          )
        }}
      </AutoSizer>
    )
  }
)

interface ParticipantRowProps {
  className?: string
  participantId: string
  testIdSuffix?: string
}

export const ParticipantRow: FC<ParticipantRowProps> = ({
  className,
  participantId,
  testIdSuffix,
}) => {
  const hasControlsScope = useSelector((state: CantinaState) =>
    hasScope(state, Scope.ConferenceLayerControls)
  )

  return (
    <div
      className={`participant-box partId-${participantId} ${className}`}
      data-test={`${TestId.ParticipantDetails}${testIdSuffix}`}
    >
      <div className='flex flex-nowrap items-center py-3'>
        <div className='shrink' style={{ minWidth: '36px' }}>
          <ParticipantListRowDetails
            participantId={participantId}
            className='mr-1'
            testId={TestId.ParticipantDetails}
            size={Size.Small}
          />
        </div>
        {hasControlsScope ? (
          <ParticipantListRowActions
            participantId={participantId}
            testIdSuffix={`${testIdSuffix}`}
          />
        ) : (
          <ParticipantListRowStatus participantId={participantId} />
        )}
      </div>
    </div>
  )
}

const NoParticipants = () => {
  const dispatch = useDispatch()
  const clearFilter = () => {
    dispatch(callViewActions.setParticipantListFilter({ filter: '' }))
  }
  const filter = useSelector(getParticipantListFilter)
  return (
    <div className='flex items-center justify-between w-full px-4 py-2 mb-3'>
      <div className='text-sm my-1'>{tr(Label.NO_PARTICIPANTS_FOUND)}</div>
      {filter && (
        <SecondaryButton
          className='p-2 text-xs'
          onClick={clearFilter}
          testId={TestId.ParticipantsButtonClearFilter}
        >
          {tr(Label.CLEAR_FILTER)}
        </SecondaryButton>
      )}
    </div>
  )
}
