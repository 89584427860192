import React, {
  ChangeEvent,
  ComponentType,
  FC,
  useEffect,
  useState,
  useRef,
} from 'react'
import { compose } from 'redux'
import { tr, Label } from 'common/i18n'
import { ImageIcon, PlusSquareIcon, TrashIcon } from 'common/icons'
import { useRoomTemplateField, useSettingsField } from 'common/hooks'
import { withCustomHeaderLogoFeatureFlag } from 'common/hoc'
import { checkValidHttpsURL, genMd5 } from 'common/services/helpers'
import { LabelIconState } from 'common/constants'
import { RoomTemplateKeys, SettingsKeys } from 'common/rest'
import { InfoTooltip } from 'src/components/Tooltip'
import { IconState } from 'src/components/IconState'
import { useRoomAutoSave } from 'src/effects/useRoomAutoSave'
import { InputGroup } from 'src/components/InputGroup/InputGroup'
import { RoomComponentProps } from './Rooms/RoomDetail'

export type Field = {
  id: string
  info: Label
  infoValues?: {}
  inputLabel: Label
  label: Label
  type: 'string' | 'color' | 'number'
  validateInput?: (value: any) => boolean
  errorLabel?: Label
  errorLabelValues?: {}
  isImage?: boolean
  inputMax?: number
  inputMin?: number
}

const BORDER_WIDTH_MIN = 0
const BORDER_WIDTH_MAX = 10

const FIELDS: { [id: string]: Field } = {
  custom_preview: {
    id: 'custom_preview',
    info: Label.APPEARANCE_TOOLTIP_PREVIEW,
    inputLabel: Label.ADD_CUSTOM_IMAGE_URL,
    label: Label.OCCUPIED_ROOM_PREVIEW,
    type: 'string',
    isImage: true,
    validateInput: checkValidHttpsURL,
  },
  header_logo: {
    id: 'header_logo',
    info: Label.APPEARANCE_TOOLTIP_HEADER_LOGO,
    inputLabel: Label.ADD_CUSTOM_IMAGE_URL,
    label: Label.HEADER_LOGO,
    type: 'string',
    isImage: true,
    validateInput: checkValidHttpsURL,
  },
  custom_canvas: {
    id: 'custom_canvas',
    info: Label.APPEARANCE_TOOLTIP_CANVAS,
    inputLabel: Label.ADD_CUSTOM_IMAGE_URL,
    label: Label.CANVAS,
    type: 'string',
    isImage: true,
    validateInput: checkValidHttpsURL,
  },
  custom_empty: {
    id: 'custom_empty',
    info: Label.APPEARANCE_TOOLTIP_EMPTY,
    inputLabel: Label.ADD_CUSTOM_IMAGE_URL,
    label: Label.EMPTY,
    type: 'string',
    isImage: true,
    validateInput: checkValidHttpsURL,
  },
  custom_alone: {
    id: 'custom_alone',
    info: Label.APPEARANCE_TOOLTIP_SINGLE_USER,
    inputLabel: Label.ADD_CUSTOM_IMAGE_URL,
    label: Label.SINGLE_USER,
    type: 'string',
    isImage: true,
    validateInput: checkValidHttpsURL,
  },
  custom_l3_color: {
    id: 'custom_l3_color',
    info: Label.APPEARANCE_TOOLTIP_BANNER_TEXT_COLOR,
    inputLabel: Label.CLICK_TO_CHOOSE_A_COLOR,
    label: Label.BANNER_TEXT_COLOR,
    type: 'color',
  },
  custom_l3_img: {
    id: 'custom_l3_img',
    info: Label.APPEARANCE_TOOLTIP_BANNER_LOGO,
    inputLabel: Label.ADD_CUSTOM_IMAGE_URL,
    label: Label.BANNER_LOGO,
    type: 'string',
    isImage: true,
    validateInput: checkValidHttpsURL,
  },
  custom_splash: {
    id: 'custom_splash',
    info: Label.APPEARANCE_TOOLTIP_SPLASH,
    inputLabel: Label.ADD_CUSTOM_IMAGE_URL,
    label: Label.SPLASH,
    type: 'string',
    isImage: true,
    validateInput: checkValidHttpsURL,
  },
  custom_mute: {
    id: 'custom_mute',
    info: Label.APPEARANCE_TOOLTIP_MUTE,
    inputLabel: Label.ADD_CUSTOM_IMAGE_URL,
    label: Label.MUTE,
    type: 'string',
    isImage: true,
    validateInput: checkValidHttpsURL,
  },
  custom_border_color: {
    id: 'custom_border_color',
    info: Label.APPEARANCE_TOOLTIP_BORDER_COLOR,
    inputLabel: Label.CLICK_TO_CHOOSE_A_COLOR,
    label: Label.BORDER_COLOR,
    type: 'color',
  },
  custom_border: {
    id: 'custom_border',
    info: Label.APPEARANCE_TOOLTIP_BORDER_WIDTH,
    infoValues: { min: BORDER_WIDTH_MIN, max: BORDER_WIDTH_MAX },
    inputLabel: Label.WIDTH_IN_PIXELS,
    label: Label.BORDER_WIDTH,
    type: 'number',
    validateInput: (value) =>
      value >= BORDER_WIDTH_MIN && value <= BORDER_WIDTH_MAX,
    errorLabel: Label.MUST_BE_BETWEEN,
    errorLabelValues: { min: BORDER_WIDTH_MIN, max: BORDER_WIDTH_MAX },
    inputMin: BORDER_WIDTH_MIN,
    inputMax: BORDER_WIDTH_MAX,
  },
  custom_border_spacing: {
    id: 'custom_border_spacing',
    info: Label.APPEARANCE_TOOLTIP_BORDER_SPACING,
    infoValues: { min: BORDER_WIDTH_MIN, max: BORDER_WIDTH_MAX },
    inputLabel: Label.WIDTH_IN_PIXELS,
    label: Label.BORDER_SPACING,
    type: 'number',
    validateInput: (value) =>
      value >= BORDER_WIDTH_MIN && value <= BORDER_WIDTH_MAX,
    errorLabel: Label.MUST_BE_BETWEEN,
    errorLabelValues: { min: BORDER_WIDTH_MIN, max: BORDER_WIDTH_MAX },
    inputMin: BORDER_WIDTH_MIN,
    inputMax: BORDER_WIDTH_MAX,
  },
}

// Rooms -> Appearance
export const RoomAppearance: FC<RoomComponentProps> = ({
  room,
  update,
  getErrorByName,
}) => {
  return (
    <>
      <div className='text-contrast-h text-lg font-bold mb-6'>
        {tr(Label.APPEARANCE)}
      </div>
      <div className='divide-y divide-contrast-m'>
        <RoomCustomSplash
          update={update}
          value={room.custom_splash ?? []}
          getErrorByName={getErrorByName}
        />
        <RoomCustomEmpty
          update={update}
          value={room.custom_empty ?? []}
          getErrorByName={getErrorByName}
        />
        <RoomCustomPreview
          update={update}
          value={room.custom_preview ?? []}
          getErrorByName={getErrorByName}
        />
        <RoomCustomCanvas
          update={update}
          value={room.custom_canvas ?? []}
          getErrorByName={getErrorByName}
        />
        <RoomCustomAlone
          update={update}
          value={room.custom_alone ?? []}
          getErrorByName={getErrorByName}
        />
        <RoomCustomL3Color
          update={update}
          value={room.custom_l3_color}
          getErrorByName={getErrorByName}
        />
        <RoomCustomL3Img
          update={update}
          value={room.custom_l3_img || []}
          getErrorByName={getErrorByName}
        />
        <RoomCustomMute
          update={update}
          value={room.custom_mute ?? []}
          getErrorByName={getErrorByName}
        />
        <RoomCustomBorderColor
          update={update}
          value={room.custom_border_color}
          getErrorByName={getErrorByName}
        />
        <RoomCustomBorder
          update={update}
          value={room.custom_border ?? 0}
          getErrorByName={getErrorByName}
        />
        <RoomCustomBorderSpacing
          update={update}
          value={room.custom_border_spacing ?? 0}
          getErrorByName={getErrorByName}
        />
      </div>
    </>
  )
}

// Global -> Appearance
export const RoomTemplateAppearance: FC<RoomComponentProps> = () => {
  return (
    <>
      <div className='text-contrast-h text-lg font-bold mb-6'>
        {tr(Label.APPEARANCE)}
      </div>
      <div className='divide-y divide-contrast-m'>
        <SettingsHeaderLogo />
        <RoomTemplateCustomPreview />
        <RoomTemplateCustomCanvas />
        <RoomTemplateCustomEmpty />
        <RoomTemplateCustomAlone />
        <RoomTemplateCustomL3Color />
        <RoomTemplateCustomL3Img />
        <RoomTemplateCustomSplash />
        <RoomTemplateCustomMute />
        <RoomTemplateCustomBorderColor />
        <RoomTemplateCustomBorder />
        <RoomTemplateCustomBorderSpacing />
      </div>
    </>
  )
}

type ArrayCustomFieldProps = {
  update: (params: any) => Promise<void>
  value: number | string | string[]
  getErrorByName: RoomComponentProps['getErrorByName']
}
type WrapperComponentProps = {
  fieldName: string
  value: string | number | boolean | string[]
  updateHandler: (name: string, value: any) => void
  getErrorByName: RoomComponentProps['getErrorByName']
  iconState: LabelIconState
}
const withRoomAutoSave =
  (fieldName: string) =>
  <T extends ArrayCustomFieldProps>(
    WrappedComponent: ComponentType<WrapperComponentProps>
  ) => {
    return (props: T) => {
      // Custom Logic Here...
      const { currentValue, freeformHandler, iconState } = useRoomAutoSave({
        defaultValue: props.value,
        updateRoomHandler: props.update,
      })

      return (
        <WrappedComponent
          fieldName={fieldName}
          iconState={iconState}
          value={currentValue}
          updateHandler={freeformHandler}
          getErrorByName={props.getErrorByName}
        />
      )
    }
  }

const withRoomTemplateAutoSave =
  (fieldName: RoomTemplateKeys) =>
  (WrappedComponent: ComponentType<WrapperComponentProps>) => {
    return () => {
      const { reduxValue, update, iconState } = useRoomTemplateField(fieldName)
      const getErrorByName = () => ''
      const updateHandler = (_: string, value: any) => update(value)

      return (
        <WrappedComponent
          fieldName={fieldName}
          iconState={iconState}
          // @ts-expect-error
          value={reduxValue}
          updateHandler={updateHandler}
          getErrorByName={getErrorByName}
        />
      )
    }
  }

const withSettingsAutoSave =
  (fieldName: SettingsKeys) =>
  (WrappedComponent: ComponentType<WrapperComponentProps>) => {
    return () => {
      const { reduxValue, update, iconState } = useSettingsField(fieldName)
      const getErrorByName = () => ''
      const updateHandler = (_: string, value: any) => update(value)

      return (
        <WrappedComponent
          fieldName={fieldName}
          iconState={iconState}
          // @ts-expect-error
          value={reduxValue}
          updateHandler={updateHandler}
          getErrorByName={getErrorByName}
        />
      )
    }
  }

const ArrayField: FC<WrapperComponentProps> = ({
  fieldName,
  value = [], // the saved value from the server
  updateHandler,
  getErrorByName,
  iconState,
}) => {
  // This check is for an old DB
  if (!Array.isArray(value)) {
    value = value && typeof value === 'string' ? [value] : []
  }
  const [errors, setErrors] = useState<string[]>([])
  const [showEmpty, setShowEmpty] = useState(!(value as string[])?.length)
  const newHtmlName = `${fieldName}-new`
  const {
    label,
    inputLabel,
    info,
    type: inputType,
    validateInput,
    errorLabel = Label.INVALID_HTTPS_URL,
  } = FIELDS[fieldName]

  useEffect(() => {
    setShowEmpty(!(value as string[])?.length)
  }, [value])

  const blurHandler = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const newValue = target.value
    const [, idx] = target.name.split('-')
    const isNew = idx === 'new'
    const index = parseInt(idx)

    // Clear out any errors
    setErrors([...errors].filter((x) => x !== target.name))

    // Bail out if the input is empty or is the same value
    // @ts-expect-error
    if (!newValue?.length || (!isNew && value[index] === newValue)) {
      return
    }

    // Bail out if this is a dupe
    if ((value as string[]).includes(newValue)) {
      // TODO: change the error label here
      return
    }

    // Set an error if the input is not valid
    if (validateInput && !validateInput(newValue)) {
      setErrors([...errors, target.name])
      return
    }

    // Update the array
    const newArr = [...(value as string[])]
    if (isNew) {
      newArr.push(newValue)
    } else {
      newArr.splice(index, 1, newValue)
    }
    updateHandler(fieldName, newArr)
  }

  const deleteHandler = (index: number) => {
    // @ts-expect-error
    if (value[index]) {
      const newArr = [...(value as string[])]
      newArr.splice(index, 1)
      updateHandler(fieldName, newArr)
    }
  }

  return (
    <div className='mt-4 pt-4'>
      <div className='font-bold mb-2'>
        {tr(label)}
        <InfoTooltip title={tr(info)} />
        <IconState state={iconState} />
      </div>
      {(value as string[])?.map((url, index) => {
        const htmlName = `${fieldName}-${index}`
        const errorString = errors.includes(htmlName) ? tr(errorLabel) : null

        return (
          <div key={genMd5(url)} className='grid grid-cols-4 gap-2 h-20 mb-4'>
            <div className='col-span-3'>
              <InputGroup
                defaultValue={url}
                // @ts-expect-error
                error={errorString ?? getErrorByName(fieldName)}
                inputClassName={inputType === 'color' ? 'p-0 h-10' : ''}
                label={tr(inputLabel)}
                name={htmlName}
                onBlur={blurHandler}
                placeholder='https://'
                type={inputType ?? 'string'}
                wrapperClassName=''
              />
            </div>
            <div className='col-span-1'>
              <ImagePreview
                index={index}
                src={url}
                deleteHandler={deleteHandler}
              />
            </div>
          </div>
        )
      })}
      {showEmpty && (
        <div className='grid grid-cols-4 gap-2 h-20 mb-4'>
          <div className='col-span-3'>
            <InputGroup
              error={
                errors.includes(newHtmlName)
                  ? tr(Label.INVALID_HTTPS_URL)
                  : undefined
              }
              inputClassName={inputType === 'color' ? 'p-0 h-10' : ''}
              label={tr(inputLabel)}
              name={newHtmlName}
              onBlur={blurHandler}
              placeholder='https://'
              type={inputType ?? 'string'}
              wrapperClassName=''
            />
          </div>
          <div className='col-span-1'>
            <ImagePreview index={-1} deleteHandler={deleteHandler} />
          </div>
        </div>
      )}
      <span
        className={`text-sw-red text-sm pt-2 ${showEmpty ? 'sw-disabled' : ''}`}
        onClick={() => (showEmpty ? null : setShowEmpty(true))}
        role='button'
      >
        <PlusSquareIcon fixedWidth className='rct-icon rct-icon-expand-all' />
        {tr(Label.ADD_ANOTHER_IMAGE)}
      </span>
    </div>
  )
}

const SingleField: FC<WrapperComponentProps> = ({
  fieldName,
  value = '',
  updateHandler,
  getErrorByName,
  iconState,
}) => {
  const [error, setError] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const {
    label,
    inputLabel,
    info,
    infoValues,
    type: inputType,
    isImage = false,
    validateInput,
    errorLabel = Label.INVALID_HTTPS_URL,
    errorLabelValues = {},
    inputMax,
    inputMin,
  } = FIELDS[fieldName]

  // Keep input field value synced with Redux state
  useEffect(() => {
    if (inputRef.current && inputType === 'string') {
      inputRef.current.value = value as string
    }
  }, [inputType, value])

  const blurHandler = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const newValue = target.value

    // Clear out any errors
    setError('')

    // Bail out if the input is empty or is the same value
    if (value === newValue) {
      return
    }
    const final = inputType === 'number' ? parseInt(newValue) : newValue
    // Set an error if the input is not valid
    if (validateInput && !validateInput(final)) {
      setError(tr(errorLabel, errorLabelValues))
      return
    }
    updateHandler(fieldName, final)
  }

  const deleteHandler = () => {
    updateHandler(fieldName, '')
  }

  return (
    <div className='mt-4 pt-4'>
      <div className='font-bold mb-2'>
        {tr(label)}
        <InfoTooltip title={tr(info, infoValues)} />
        <IconState state={iconState} />
      </div>
      <div className='grid grid-cols-4 gap-2 h-20 mb-4'>
        <div className='col-span-3'>
          <InputGroup
            defaultValue={value as string}
            inputRef={inputRef}
            // @ts-expect-error
            error={error ?? getErrorByName(fieldName)}
            inputClassName={inputType === 'color' ? 'p-0 h-10' : ''}
            label={tr(inputLabel)}
            name={fieldName}
            onBlur={blurHandler}
            placeholder={inputType === 'number' ? '0' : 'http://'}
            type={inputType ?? 'string'}
            max={inputMax}
            min={inputMin}
            wrapperClassName=''
          />
        </div>
        {isImage && (
          <div className='col-span-1'>
            <ImagePreview
              index={0}
              src={value as string}
              deleteHandler={deleteHandler}
            />
          </div>
        )}
      </div>
    </div>
  )
}

type ImagePreviewProps = {
  index: number
  src?: string
  deleteHandler: (index: number) => void
}
const ImagePreview = ({ index, src, deleteHandler }: ImagePreviewProps) => {
  if (src) {
    return (
      <div
        style={{ backgroundImage: `url(${src})` }}
        className='rounded thumbnail h-20 group'
        onClick={() => deleteHandler(index)}
      >
        <div className='thumbnail-overlay rounded flex items-center justify-center'>
          <TrashIcon
            className='text-sw-red cursor-pointer hidden group-hover:flex'
            size='lg'
          />
        </div>
      </div>
    )
  }

  return (
    <div className='flex flex-col items-center border-dashed rounded border-2 border-sw-red text-sm p-4 text-center h-20 text-sw-red'>
      <ImageIcon size='lg' className='mb-2' />
      {tr(Label.THUMBNAIL)}
    </div>
  )
}

// Room Array Fields
const RoomCustomPreview = withRoomAutoSave('custom_preview')(ArrayField)
const RoomCustomCanvas = withRoomAutoSave('custom_canvas')(ArrayField)
const RoomCustomEmpty = withRoomAutoSave('custom_empty')(ArrayField)
const RoomCustomAlone = withRoomAutoSave('custom_alone')(ArrayField)
const RoomCustomSplash = withRoomAutoSave('custom_splash')(ArrayField)
const RoomCustomMute = withRoomAutoSave('custom_mute')(ArrayField)
// Room Single Fields
const RoomCustomL3Color = withRoomAutoSave('custom_l3_color')(SingleField)
const RoomCustomL3Img = withRoomAutoSave('custom_l3_img')(ArrayField)
const RoomCustomBorderColor = withRoomAutoSave('custom_border_color')(
  SingleField
)
const RoomCustomBorderSpacing = withRoomAutoSave('custom_border_spacing')(
  SingleField
)
const RoomCustomBorder = withRoomAutoSave('custom_border')(SingleField)

// Room Templates Array Fields
const RoomTemplateCustomPreview =
  withRoomTemplateAutoSave('custom_preview')(ArrayField)
const RoomTemplateCustomCanvas =
  withRoomTemplateAutoSave('custom_canvas')(ArrayField)
const RoomTemplateCustomEmpty =
  withRoomTemplateAutoSave('custom_empty')(ArrayField)
const RoomTemplateCustomAlone =
  withRoomTemplateAutoSave('custom_alone')(ArrayField)
const RoomTemplateCustomSplash =
  withRoomTemplateAutoSave('custom_splash')(ArrayField)
const RoomTemplateCustomMute =
  withRoomTemplateAutoSave('custom_mute')(ArrayField)
// Room Templates Single Fields
const RoomTemplateCustomL3Color =
  withRoomTemplateAutoSave('custom_l3_color')(SingleField)
const RoomTemplateCustomL3Img =
  withRoomTemplateAutoSave('custom_l3_img')(ArrayField)
const RoomTemplateCustomBorderColor = withRoomTemplateAutoSave(
  'custom_border_color'
)(SingleField)
const RoomTemplateCustomBorder =
  withRoomTemplateAutoSave('custom_border')(SingleField)
const RoomTemplateCustomBorderSpacing = withRoomTemplateAutoSave(
  'custom_border_spacing'
)(SingleField)

// Header Logo
const SettingsHeaderLogo = compose<FC<any>>(
  withCustomHeaderLogoFeatureFlag(),
  withSettingsAutoSave('header_logo')
)(SingleField)
