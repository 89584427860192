import React, { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { authActions } from 'common/redux/features'
import { SpinnerIcon } from 'common/icons'
import { tr, Label } from 'common/i18n'
import { useCantinaBackendAPI, useCantinaId } from 'common/hooks'
import { Endpoint } from 'common/rest'
import { AuthProvider, PasswordInputLength, RoutePath } from 'common/constants'
import { getErrorMessage } from 'common/redux/features/auth/authSelectors'
import { getJWTExpiresIn } from 'common/services/helpers'
import { CenteredBox } from 'src/layout'
import { TestId } from 'src/constants'
import { getSearchParams } from 'src/services/Helpers'
import { PrimaryButton } from 'src/components/Button/Button'
import { PasswordConfirmation } from 'src/components/PasswordConfirmation/PasswordConfirmation'
import { useInputChange } from 'src/effects/useInputChange'
import SimpleHeader from 'src/components/Header/SimpleHeader'

const pwdResetConfirmOptions = {
  endpoint: Endpoint.PasswordResetsConfirm,
  method: 'POST',
}

const loginOptions = {
  endpoint: Endpoint.Login,
  method: 'POST',
}

const PasswordSet: FC = () => {
  const { invokeAPI: resetPasswordConfirmHandler } = useCantinaBackendAPI(
    pwdResetConfirmOptions
  )
  const { invokeAPI: loginHandler } = useCantinaBackendAPI(loginOptions)
  const dispatch = useDispatch()
  const history = useHistory()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useInputChange()
  const errorMessage = useSelector(getErrorMessage)
  const { email, reset_password_digest } = getSearchParams()
  const cantinaId = useCantinaId()

  useEffect(() => {
    if (errorMessage) {
      setLoading(false)
      setError(errorMessage)
    }

    return () => {
      if (errorMessage) {
        dispatch(authActions.clearErrorMessage())
      }
    }
  }, [dispatch, errorMessage])

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setError('')
    const { password, confirmPassword } = values

    if (
      password &&
      password.length >= PasswordInputLength.Min &&
      password === confirmPassword
    ) {
      setLoading(true)
      try {
        await resetPasswordConfirmHandler({
          body: JSON.stringify({
            cantinaId,
            email,
            password,
            digest: reset_password_digest,
          }),
        })

        const response = await loginHandler({
          credentials: 'include',
          body: JSON.stringify({
            cantinaId,
            email,
            password,
            provider: AuthProvider.Local,
            expiresIn: getJWTExpiresIn(),
          }),
        })
        dispatch(authActions.loginSuccess(response))
        history.push(RoutePath.Home)
      } catch (error) {
        let label = tr(Label.PASSWORD_CREATION_FAILED)

        if (
          // @ts-expect-error
          error?.body?.errors?.some((x) =>
            x.startsWith('Reset password digest')
          )
        ) {
          label = tr(Label.PASSWORD_RESET_TOKEN_EXPIRED)
        }
        setError(label)
        setLoading(false)
      }
    } else if (
      password.length > 0 &&
      password.length < PasswordInputLength.Min
    ) {
      setError('Password should be at least 8 characters.')
    } else if (password !== confirmPassword) {
      setError('Passwords do not match!')
    }
    return false
  }

  return (
    <>
      <SimpleHeader />
      <CenteredBox>
        <form
          onSubmit={submitHandler}
          autoComplete='off'
          data-test={TestId.SetNewPasswordForm}
        >
          <div className='text-center text-3xl font-neue'>
            {tr(Label.NEW_PASSWORD)}
          </div>
          <div className='text-sm text-contrast-h pb-4 text-center'>
            {tr(Label.CHOOSE_NEW_PASSWORD)}
          </div>
          <PasswordConfirmation
            onPasswordChange={setValues}
            onConfirmPasswordChange={setValues}
            error={error}
          />
          <PrimaryButton
            type='submit'
            className='w-full'
            testId={TestId.SetPasswordButtonSubmit}
          >
            {loading ? <SpinnerIcon /> : tr(Label.CONTINUE)}
          </PrimaryButton>
        </form>
      </CenteredBox>
    </>
  )
}

export default PasswordSet
