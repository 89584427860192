import React, { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getErrorMessage } from 'common/redux/features/auth/authSelectors'
import { SpinnerIcon } from 'common/icons'
import { Endpoint, UserLookupResponse } from 'common/rest'
import { PasswordInputLength, RoutePath, AuthProvider } from 'common/constants'
import { useCantinaBackendAPI } from 'common/hooks'
import { getJWTExpiresIn } from 'common/services/helpers'
import { authActions } from 'common/redux/features'
import { tr, Label } from 'common/i18n'
import { TestId } from 'src/constants'
import { InputGroup } from 'src/components/InputGroup/InputGroup'
import { useInputChange } from 'src/effects/useInputChange'
import { PrimaryButton } from 'src/components/Button/Button'
import { PrivacyPolicyLink } from 'src/components/PrivacyPolicyLink'
import { SigninNotification } from 'src/components/SigninNotification'
import { LoginHeader } from './LoginHeader'

const loginOptions = {
  endpoint: Endpoint.Login,
  method: 'POST',
}

type AuthProviderComponentProps = { cantinaId: string } & UserLookupResponse

export const LocalProvider: FC<AuthProviderComponentProps> = ({
  cantinaId,
  email,
  name,
}) => {
  const { invokeAPI: loginHandler } = useCantinaBackendAPI(loginOptions)
  const dispatch = useDispatch()
  const errorMessage = useSelector(getErrorMessage)
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useInputChange({
    password: '',
  })
  const [error, setError] = useState('')
  useEffect(() => {
    if (errorMessage) {
      setLoading(false)
      setError(errorMessage)
    }

    return () => {
      if (errorMessage) {
        dispatch(authActions.clearErrorMessage())
      }
    }
  }, [dispatch, errorMessage])

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const { password = '' } = values
    if (email && password) {
      setLoading(true)
      setError('')
      try {
        const response = await loginHandler({
          credentials: 'include',
          body: JSON.stringify({
            cantinaId,
            email,
            password,
            provider: AuthProvider.Local,
            expiresIn: getJWTExpiresIn(),
          }),
        })
        dispatch(authActions.loginSuccess(response))
      } catch (error) {
        setLoading(false)
        setError('Invalid Password')
      }
    }
    return false
  }

  return (
    <>
      <LoginHeader email={email} name={name} />
      <div className='text-sm text-contrast-h pb-4 text-center'>
        {tr(Label.ACCOUNT_FOUND_INSTRUX)}
      </div>
      <form
        onSubmit={submitHandler}
        autoComplete='off'
        data-test={TestId.LoginRegisteredForm}
      >
        <InputGroup
          autoFocus
          error={error}
          label='Password'
          maxLength={PasswordInputLength.Max}
          minLength={PasswordInputLength.Min}
          name='password'
          onChange={setValues}
          required
          type='password'
          testId={TestId.LoginRegisteredInputPassword}
        />
        <PrimaryButton
          className='w-full mb-2 px-16'
          disabled={loading}
          testId={TestId.LoginRegisteredButtonLogin}
          type='submit'
        >
          {loading ? <SpinnerIcon /> : tr(Label.LOG_IN)}
        </PrimaryButton>
      </form>
      <Link
        to={{
          pathname: RoutePath.PasswordForgot,
          state: {
            savedEmail: email,
          },
        }}
        className='block w-full md:w-auto my-3 text-sm underline text-sw-red'
        data-test={TestId.LoginRegisteredButtonForgot}
      >
        {tr(Label.FORGOT_PASSWORD)}
      </Link>
      <PrivacyPolicyLink />
      <SigninNotification refPage={RoutePath.Login} />
    </>
  )
}
