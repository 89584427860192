import React, { FC } from 'react'
import { tr, Label } from 'common/i18n'
import { RoomCommandAction, Scope } from 'common/constants'
import { LeaveIcon } from 'common/icons'
import { ParticipantCommandButton, ParticipantButtonProps } from './index'

export const ParticipantKickButton: FC<ParticipantButtonProps> = ({
  participantId,
  ...rest
}) => {
  const action = {
    participantId,
    action: RoomCommandAction.KickParticipant,
  }

  // Set activated=true for red icon
  return (
    <ParticipantCommandButton
      action={action}
      activated
      confirm
      Icon={LeaveIcon}
      label={tr(Label.REMOVE_PARTICIPANT)}
      participantId={participantId}
      scope={Scope.ConferenceKick}
      {...rest}
    />
  )
}
