import React, { FC } from 'react'
import { Label } from 'common/i18n'
import { LabelIconState } from 'common/constants'
import { RoomTemplateKeys } from 'common/rest'
import { useRoomAutoSave } from 'src/effects/useRoomAutoSave'
import { TestId } from 'src/constants'
import { PINInputGroup } from '../InputGroup/PINInputGroup'

// Use this component to set the Room-level PIN

type PINInputRoomProps = {
  className?: string
  defaultValue: string | null
  label: Label
  labelIconState?: LabelIconState
  name: RoomTemplateKeys
  testId: TestId
  update: (params: any) => void
}

export const PINInputRoom: FC<PINInputRoomProps> = ({
  className,
  defaultValue,
  label,
  name,
  testId,
  update,
}) => {
  const { freeformHandler, iconState } = useRoomAutoSave({
    defaultValue,
    updateRoomHandler: update,
  })

  return (
    <PINInputGroup
      className={className}
      clearButtonFunction={() => freeformHandler(name, '')}
      defaultValue={defaultValue}
      label={label}
      labelIconState={iconState}
      name={name}
      testId={testId}
      updateFunction={(val) => freeformHandler(name, val)}
    />
  )
}
