import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit'
import { SettingsState } from '../../interfaces'
import { BootstrapResponse, Settings } from '../../../rest'

export const initialSettingsState: SettingsState = Object.freeze({
  cantinaId: null,
  internalAPIHost: '',
  promoImageHost: '',
  cantinaBackendHost: '',
  companyId: null,
  projectId: null,
  style: '',
  connectionSettings: {},
  featureFlags: [],
  defaultRoomTemplateId: null,
  domains: [],
  created_at: '',
  updated_at: '',
  skipPrejoinPage: false,
  flipLocalVideo: false,
  header_logo: null,
  lobby_header: null,
  lobby_footer: null,
  sign_in_notification_body: null,
  typeFormId: 'cLQhVEJC',
  custom_app_label: null,
  custom_company_label: null,
  custom_name_label: null,
  version: null,
})

const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialSettingsState,
  reducers: {
    bootstrapRequest: (state, action: PayloadAction<{ hostname: string }>) => {
      state.projectId = null
    },
    bootstrapSuccess: (
      state,
      { payload }: PayloadAction<BootstrapResponse>
    ) => {
      state.cantinaId = payload.cantinaId
      state.internalAPIHost = payload.internalAPIHost
      state.promoImageHost = payload.promoImageHost
      state.cantinaBackendHost = payload.cantinaBackendHost
      state.companyId = payload.companyId
      state.projectId = payload.projectId
      state.style = payload.style
      state.connectionSettings = payload.connectionSettings
      state.lobby_header = payload.lobbyHeader
      state.lobby_footer = payload.lobbyFooter
      state.header_logo = payload.headerLogo
      state.featureFlags = Array.isArray(payload?.featureFlags)
        ? payload.featureFlags
        : []
      state.defaultRoomTemplateId = payload.defaultRoomTemplateId
      state.sign_in_notification_body = payload.signInNotificationBody
      state.domains = payload.domains
      state.created_at = payload.createdAt
      state.updated_at = payload.updatedAt
      state.custom_app_label = payload.customAppLabel
      state.custom_name_label = payload.customNameLabel
      state.custom_company_label = payload.customCompanyLabel
    },
    bootstrapFailure: () => initialSettingsState,
    updateSettingsSuccess: (state, { payload }: PayloadAction<Settings>) => {
      state.header_logo = payload.header_logo
      state.lobby_header = payload.lobby_header
      state.lobby_footer = payload.lobby_footer
      state.sign_in_notification_body = payload.sign_in_notification_body
      state.custom_app_label = payload.custom_app_label
      state.custom_name_label = payload.custom_name_label
      state.custom_company_label = payload.custom_company_label
      state.created_at = payload.created_at
      state.updated_at = payload.updated_at
    },
    updateVersion: (state, { payload }: PayloadAction<string>) => {
      state.version = payload
    },
  },
})

export const updateSettingsRequest = createAction<Partial<Settings>>(
  'settings/updateSettingsRequest'
)
export const checkVersion = createAction('settings/checkVersion')

// prettier-ignore
export const {
  actions: settingsActions,
  reducer: settingsReducer
} = settingsSlice
