import { RoomRole } from 'common/constants'

export const canManageRoom = (myRoles: RoomRole[]) => {
  if (myRoles?.length) {
    return (
      myRoles.includes(RoomRole.Manager) ||
      myRoles.includes(RoomRole.Configurator)
    )
  }
  return false
}

export const canManageRoomTemplate = canManageRoom
