// @ts-nocheck
import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { tr, Label } from 'common/i18n'
import { TestId } from 'src/constants'
import { PictureInPictureIcon, PictureInPictureIconActive } from 'common/icons'
import { callViewActions } from 'common/redux/views'
import { Button, ButtonWithTooltip } from '../Button/Button'
import { MCU_ELEMENT_CLASS } from '../Mcu/MCU'

type PictureInPictureButtonProps = {
  className?: string
  clickHandler?: () => void
  disabled?: boolean
  labelClassName?: string
  showLabel?: boolean
}

const DEFAULT_SELECTOR = `video.${MCU_ELEMENT_CLASS}`

// Export this function from here so we can contain all the PIP
// logic in one place and within /react so we have access to document
export const pipHangupHandler = () => {
  if (document.exitPictureInPicture && document.pictureInPictureElement) {
    document.exitPictureInPicture()
  } else {
    const video = document.querySelector(DEFAULT_SELECTOR)
    video?.webkitSetPresentationMode?.('inline')
  }
}

export const PictureInPictureButton: FC<PictureInPictureButtonProps> = ({
  className = '',
  clickHandler,
  disabled = false,
  labelClassName = '',
  showLabel,
}) => {
  const dispatch = useDispatch()
  const [video, setVideo] = useState(() => {
    return document.querySelector(DEFAULT_SELECTOR)
  })
  const initialActive =
    !!document.pictureInPictureElement ||
    video?.webkitPresentationMode === 'picture-in-picture'
  const [active, setActive] = useState(initialActive)

  const isNormalBrowser = document.pictureInPictureEnabled === true
  const isWebKit =
    video?.webkitSupportsPresentationMode &&
    typeof video.webkitSetPresentationMode === 'function'

  useEffect(() => {
    const pipActive = () => setActive(true)
    const pipInactive = () => setActive(false)
    const pipStateWebkit = () =>
      setActive(video.webkitPresentationMode === 'picture-in-picture')

    // Get the video element after the DOM has rendered
    const video = document.querySelector(DEFAULT_SELECTOR)

    if (video) {
      setVideo(video)

      if (isNormalBrowser) {
        video.addEventListener('enterpictureinpicture', pipActive)
        video.addEventListener('leavepictureinpicture', pipInactive)
      }
      if (isWebKit) {
        video.addEventListener('webkitpresentationmodechanged', pipStateWebkit)
      }

      return () => {
        if (isNormalBrowser) {
          video.removeEventListener('enterpictureinpicture', pipActive)
          video.removeEventListener('leavepictureinpicture', pipInactive)
        }
        if (isWebKit) {
          video.removeEventListener(
            'webkitpresentationmodechanged',
            pipStateWebkit
          )
        }
      }
    }
  }, [isNormalBrowser, isWebKit])

  // If we don't have the video element or PiP is not supported
  if (!(video && (isNormalBrowser || isWebKit))) {
    return null
  }

  const action = () => {
    try {
      if (isNormalBrowser) {
        if (document.pictureInPictureElement) {
          document.exitPictureInPicture()
          dispatch(callViewActions.setPictureInPicture(false))
        } else {
          video.requestPictureInPicture()
          dispatch(callViewActions.setPictureInPicture(true))
        }
      } else if (isWebKit) {
        const hasPip = video.webkitPresentationMode === 'picture-in-picture'
        video.webkitSetPresentationMode(
          hasPip ? 'inline' : 'picture-in-picture'
        )
        dispatch(callViewActions.setPictureInPicture(!hasPip))
      }
    } catch (error) {
      console.error('PictureInPicture', error)
    }
  }

  const buttonLabel = active
    ? `${tr(Label.EXIT)} ${tr(Label.PICTURE_IN_PICTURE)}`
    : tr(Label.PICTURE_IN_PICTURE)
  const Icon = active ? PictureInPictureIconActive : PictureInPictureIcon

  return showLabel ? (
    <Button
      className={`sw-btn-icon-toggle ${className}`}
      disabled={disabled}
      testId={TestId.VideoIconPictureInPicture}
      onClick={() => {
        action()
        if (clickHandler) {
          clickHandler()
        }
      }}
    >
      <Icon
        size='lg'
        fixedWidth
        className='svg-inline--fa fa-fw fa-lg text-contrast-h stroke-current fill-current opacity-100'
      />
      <span className={labelClassName}>{buttonLabel}</span>
    </Button>
  ) : (
    <ButtonWithTooltip
      ariaLabel={buttonLabel}
      className='px-2'
      disabled={disabled}
      testId={TestId.VideoIconPictureInPicture}
      onClick={action}
    >
      <Icon size='lg' fixedWidth className='fill-current stroke-current' />
    </ButtonWithTooltip>
  )
}
