import React, { FC } from 'react'
import { SpinnerIcon, CheckIcon } from 'common/icons'
import { LabelIconState } from 'common/constants'

export type IconStateProps = {
  state: LabelIconState
}

export const IconState: FC<IconStateProps> = ({ state }) => {
  if (state === LabelIconState.Loading) {
    return <SpinnerIcon fixedWidth className='ml-3' />
  }
  if (state === LabelIconState.Success) {
    return <CheckIcon fixedWidth className='ml-3 text-sw-success' />
  }
  return null
}
