import { formatDistanceToNow, intervalToDuration } from 'date-fns'

export const getSearchParams = () => {
  const paramsMap = new URLSearchParams(window.location.search)
  const paramsObj: { [key: string]: string } = {}

  paramsMap.forEach(function (value, key) {
    paramsObj[key] = value
  })

  return paramsObj
}

export const instanceName = () => {
  const name = window.location.hostname.split('.')[0]
  return name[0].toUpperCase() + name.slice(1)
}

export const isWebRTCSupported = () => {
  return (
    typeof navigator === 'object' &&
    typeof navigator.mediaDevices === 'object' &&
    typeof navigator.mediaDevices.getUserMedia === 'function' &&
    typeof RTCPeerConnection === 'function'
  )
}

export const dateFormatDistance = (date: Date) => {
  return formatDistanceToNow(date, { addSuffix: true })
}

export const durationBetweenDates = (start: Date, end: Date) => {
  const time = intervalToDuration({ end, start })
  const hours = `${time.hours}`.padStart(2, '0')
  const minutes = `${time.minutes}`.padStart(2, '0')
  const seconds = `${time.seconds}`.padStart(2, '0')

  return `${hours}:${minutes}:${seconds}`
}

export const getParticipantTestIdSuffix = ({
  groupParticipantTestId,
  rowIndex,
  zoneIndex,
}: {
  groupParticipantTestId?: string
  rowIndex: number
  zoneIndex?: number
}) => {
  let testIdSuffix = ''

  switch (true) {
    // @ts-expect-error
    case !!`${zoneIndex}` && zoneIndex > -1:
      testIdSuffix = `_p${rowIndex}_z${zoneIndex}`
      break
    case !!groupParticipantTestId?.length:
      testIdSuffix = `_p${rowIndex}_g_${groupParticipantTestId}`
      break
    case !!`${rowIndex}`:
      testIdSuffix = `_p${rowIndex}`
      break
  }

  return testIdSuffix
}

export const isElementTruncated = (e: HTMLElement) => {
  return e.offsetWidth < e.scrollWidth
}
