import { createSlice, PayloadAction, isAnyOf } from '@reduxjs/toolkit'
import { CallViewState, ParticipantKeys } from '../../interfaces'
import {
  roomJoinedAction,
  roomLeftAction,
} from '../../features/rooms/roomsSlice'
import { callActions } from '../../features'

export const initialCallViewState: CallViewState = Object.freeze({
  callSettings: false,
  callSettingsTabIndex: 0,
  chat: false,
  clipeeze: false,
  clipeezeLoop: false,
  defaultPlaybackVolume: null,
  participantIdsToTransfer: [],
  participantList: false,
  participantListFilter: '',
  participantModalFilter: '',
  participantListGroups: {},
  pictureInPicture: false, // Hide the MCU when true
  ptt: false,
  recentlyPlayedVideos: [],
  roomNavigator: false,
  roomNavigatorAutoFocus: true,
  roomNavigatorFilter: '',
  screenShareActive: false,
  showPostCallSurvey: false,
})

const clearHandler = (state: CallViewState) => {
  state.participantIdsToTransfer = []
  state.roomNavigatorFilter = ''
  state.screenShareActive = false
  state.defaultPlaybackVolume = null
  state.callSettings = false
  state.callSettingsTabIndex = 0
  state.participantListFilter = ''
  state.participantModalFilter = ''
  state.ptt = false
  state.participantListGroups = {}
}

const callViewSlice = createSlice({
  name: 'callView',
  initialState: initialCallViewState,
  reducers: {
    closeDrawer: (state) => {
      state.clipeeze = false
      state.roomNavigator = false
      state.chat = false
      state.participantList = false
    },
    openClipeeze: (state) => {
      state.clipeeze = true
      state.roomNavigator = false
      state.chat = false
      state.participantList = false
    },
    closeClipeeze: (state) => {
      state.clipeeze = false
    },
    toggleClipeeze: (state) => {
      state.clipeeze = !state.clipeeze
      if (state.clipeeze) {
        state.roomNavigator = false
        state.chat = false
        state.participantList = false
      }
    },
    setClipeezeLoop: (state, { payload }: PayloadAction<boolean>) => {
      state.clipeezeLoop = payload
    },
    enablePtt: (state) => {
      state.ptt = true
    },
    disablePtt: (state) => {
      state.ptt = false
    },
    showPostCallSurvey: (state) => {
      state.showPostCallSurvey = true
    },
    hidePostCallSurvey: (state) => {
      state.showPostCallSurvey = false
    },
    openRoomNavigator: (state) => {
      state.roomNavigator = true
      state.clipeeze = false
      state.chat = false
      state.participantList = false
      state.roomNavigatorAutoFocus = false
    },
    closeRoomNavigator: (state) => {
      state.roomNavigator = false
    },
    toggleRoomNavigator: (state) => {
      state.roomNavigator = !state.roomNavigator
      if (state.roomNavigator) {
        state.clipeeze = false
        state.chat = false
        state.participantList = false
        state.roomNavigatorAutoFocus = true
      }
    },
    openChat: (state) => {
      state.chat = true
      state.clipeeze = false
      state.roomNavigator = false
      state.participantList = false
    },
    closeChat: (state) => {
      state.chat = false
    },
    toggleChat: (state) => {
      state.chat = !state.chat
      if (state.chat) {
        state.clipeeze = false
        state.roomNavigator = false
        state.participantList = false
      }
    },
    openParticipantList: (state) => {
      state.participantList = true
      state.clipeeze = false
      state.roomNavigator = false
      state.chat = false
    },
    closeParticipantList: (state) => {
      state.participantList = false
    },
    toggleParticipantList: (state) => {
      state.participantList = !state.participantList
      if (state.participantList) {
        state.clipeeze = false
        state.roomNavigator = false
        state.chat = false
      }
    },
    setRoomNavigatorFilter: (
      state,
      { payload }: PayloadAction<{ filter: string }>
    ) => {
      state.roomNavigatorFilter = payload.filter.trim()
    },
    clearRoomNavigatorFilter: (state) => {
      state.roomNavigatorFilter = ''
    },
    openCallSettings: (state) => {
      state.callSettings = true
    },
    closeCallSettings: (state) => {
      state.callSettings = false
    },
    toggleCallSettings: (state) => {
      state.callSettings = !state.callSettings
    },
    setCallSettingsTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.callSettingsTabIndex = payload
    },
    addParticipantIdsToTransfer: (
      state,
      { payload = [] }: PayloadAction<string[]>
    ) => {
      payload.forEach((participantId) => {
        if (!state.participantIdsToTransfer.includes(participantId)) {
          state.participantIdsToTransfer.push(participantId)
        }
      })
    },
    removeParticipantIdsToTransfer: (
      state,
      { payload = [] }: PayloadAction<string[]>
    ) => {
      state.participantIdsToTransfer = state.participantIdsToTransfer.filter(
        (participantId) => {
          return !payload.includes(participantId)
        }
      )
    },
    setParticipantIdsToTransfer: (
      state,
      { payload = [] }: PayloadAction<string[]>
    ) => {
      state.participantIdsToTransfer = payload
    },
    clearParticipantIdsToTransfer: (state) => {
      state.participantIdsToTransfer = []
    },
    setScreenShareActive: (
      state,
      { payload = false }: PayloadAction<boolean>
    ) => {
      state.screenShareActive = payload
    },
    setDefaultPlaybackVolume: (
      state,
      { payload = null }: PayloadAction<number | null>
    ) => {
      state.defaultPlaybackVolume = payload
    },
    setParticipantListFilter: (
      state,
      { payload }: PayloadAction<{ filter: string }>
    ) => {
      state.participantListFilter = payload.filter.trim()
    },
    setParticipantModalFilter: (
      state,
      { payload }: PayloadAction<{ filter: string }>
    ) => {
      state.participantModalFilter = payload.filter.trim()
    },
    updateGroupsList: (state, { payload }: PayloadAction<ParticipantKeys>) => {
      state.participantListGroups[payload] =
        !state.participantListGroups[payload]
    },
    setRecentlyPlayedVideos: (state, { payload }: PayloadAction<string>) => {
      if (!state.recentlyPlayedVideos.includes(payload)) {
        state.recentlyPlayedVideos.unshift(payload)

        if (state.recentlyPlayedVideos?.length > 10) {
          state.recentlyPlayedVideos.pop()
        }
      }
    },
    shiftRecentlyPlayedVideos: (state) => {
      if (state.recentlyPlayedVideos?.length) {
        state.recentlyPlayedVideos.shift()
      }
    },
    setPictureInPicture: (state, { payload }: PayloadAction<boolean>) => {
      state.pictureInPicture = payload
    },
  },
  extraReducers: (builder) => {
    const shouldClear = isAnyOf(
      roomJoinedAction,
      roomLeftAction,
      callActions.destroy
    )
    builder.addMatcher(shouldClear, clearHandler)
  },
})

// prettier-ignore
export const {
  actions: callViewActions,
  reducer: callViewReducer,
} = callViewSlice
