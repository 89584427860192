import React, { FC, FormEvent, useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { CantinaState } from 'common/src/redux/interfaces'
import { RoomRole, GrantResourceType, GrantOwnerType } from 'common/constants'
import { useCreateAccessGrant } from 'common/hooks'
import { RequestState } from 'common/rest'
import { tr, Label } from 'common/i18n'
import { getUserAutoCompleteItems } from 'common/redux/features/users/usersSelectors'
import { getGroupAutoCompleteItems } from 'common/redux/features/groups/groupsSelectors'
import { PrimaryButton } from 'src/components/Button/Button'
import { useInputChange } from 'src/effects/useInputChange'
import { Modal } from 'src/modals/Modal'
import { AutoComplete, AutoCompleteItemType } from 'src/components/AutoComplete'
import { RolesPicker } from './RolesDropdown'
import { AutoCompleteGroupData } from './GroupData'
import { AutoCompleteUserData } from './UserData'
import { TestId } from 'src/constants'

export const CreateNewAccessGrantButton = ({
  resourceType,
  resourceId,
  userIds,
  groupIds,
  refresh,
}: any) => {
  const [modalShow, setModalShow] = useState(false)
  const toggleModal = () => setModalShow(!modalShow)

  return (
    <>
      <PrimaryButton onClick={toggleModal}>
        {tr(Label.ADD_PERMISSION)}
      </PrimaryButton>
      {/* It is important to unmount this component when not in use because
        NewAccessGrantModal has a useEffect that will otherwise be called infinitely */}
      {modalShow && (
        <NewAccessGrantModal
          closeHandler={toggleModal}
          excludeGroupIds={groupIds}
          excludeUserIds={userIds}
          refresh={refresh}
          resourceId={resourceId}
          resourceType={resourceType}
          show={modalShow}
        />
      )}
    </>
  )
}

type NewAccessGrantModalProps = {
  closeHandler: () => void
  excludeGroupIds: string[]
  excludeUserIds: string[]
  refresh: () => Promise<void>
  resourceId: string
  resourceType: GrantResourceType
  show: boolean
}

const NewAccessGrantModal: FC<NewAccessGrantModalProps> = ({
  closeHandler,
  excludeGroupIds = [],
  excludeUserIds = [],
  refresh,
  resourceId,
  resourceType,
  show,
}) => {
  const { error, requestState, create } = useCreateAccessGrant(
    resourceType,
    resourceId
  )
  const [values, setValues] = useInputChange({
    grant_type: RoomRole.Inviteable,
  })
  const [selectedItems, setSelectedItems] = useState<AutoCompleteItemType[]>([])

  const userItems = useSelector((state: CantinaState) =>
    getUserAutoCompleteItems(state, excludeUserIds)
  )
  const groupItems = useSelector((state: CantinaState) =>
    getGroupAutoCompleteItems(state, excludeGroupIds)
  )
  const items = groupItems.concat(userItems)

  useEffect(() => {
    if (requestState === RequestState.Success) {
      refresh()
      closeHandler()
    }
  }, [requestState, closeHandler, refresh])

  const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    // selectedItems is a mix of User and Group.
    // Prepare an array to create the access grants.
    const paramList = selectedItems.map((item) => ({
      grant_owner_type: item.type as GrantOwnerType,
      grant_owner_id: item.id,
      grant_type: values.grant_type,
    }))
    if (paramList?.length) {
      await create(paramList)
    }
    return false
  }

  const roleType =
    resourceType === GrantResourceType.Room ? 'room_role' : 'room_template_role'

  // AutoComplete will use this function
  // to know which component to render in the list
  const getItemComponent = useCallback((type: string) => {
    return type === GrantOwnerType.User
      ? AutoCompleteUserData
      : AutoCompleteGroupData
  }, [])

  return (
    <Modal
      autoComplete='off'
      closeHandler={closeHandler}
      confirmHandler={submitHandler}
      disabled={!selectedItems.length || requestState === RequestState.Loading}
      isForm
      show={show}
      testId={TestId.SystemSettingsAccessGrantForm}
      title={tr(Label.ADD_PERMISSION)}
    >
      <RolesPicker
        roleType={roleType}
        name='grant_type'
        backendValue={values.grant_type}
        changeHandler={setValues}
        gridView
        className='mb-4'
      />
      <AutoComplete
        items={items}
        selectedItems={selectedItems}
        selectedItemsChangeHandler={setSelectedItems}
        getItemComponent={getItemComponent}
        inputPlaceholder={Label.SEARCH_USERS_OR_GROUPS}
        inputLabel={Label.SELECT_USERS_OR_GROUPS}
        testId={TestId.AutoCompleteAccessGrant}
      />
      <div className={`sw-error-hint ${error ? 'visible' : 'invisible'}`}>
        {error}
      </div>
    </Modal>
  )
}
