import React, { FC, MouseEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateDevice } from 'common/redux/features'
import { getCameraId } from 'common/redux/features/device/deviceSelectors'
import { withActiveCall } from 'common/hoc'
import { useCameras } from 'common/hooks'
import { tr, Label } from 'common/i18n'

import { CameraFeed } from 'src/components/VideoPreview'
import { TestId } from 'src/constants'
import { isFirefox } from 'react-device-detect'

export const CameraList = withActiveCall(({ callId }) => {
  const dispatch = useDispatch()
  const { devices } = useCameras()
  const currentCamId = useSelector(getCameraId)

  if (!devices.length) {
    return null
  }

  const clickHandler = (event: MouseEvent<HTMLDivElement>) => {
    const { camera: deviceId } = event.currentTarget.dataset
    if (deviceId) {
      dispatch(updateDevice({ callId, kind: 'videoinput', deviceId }))
    }
  }

  return (
    <>
      <div className='block-highlight text-sm p-2 font-semibold'>
        {tr(Label.CAMERA)}
      </div>
      <div className='switch-device-wrapper' aria-label='Camera List'>
        {devices.map((device, index) => (
          <Camera
            key={device.id}
            cameraId={device.id}
            label={device.label}
            active={currentCamId === device.id}
            clickHandler={clickHandler}
            testId={`${TestId.VideoButtonCamera}_${index}`}
          />
        ))}
      </div>
    </>
  )
})

type CameraProps = {
  active?: boolean
  cameraId: string
  label: string | null
  clickHandler: (event: MouseEvent<HTMLDivElement>) => void
  testId: TestId | string
}

const Camera: FC<CameraProps> = ({
  cameraId,
  label,
  active = false,
  clickHandler,
  testId,
}) => {
  const [deviceError, setDeviceError] = useState(false)

  if (deviceError && isFirefox) {
    return null
  }

  return (
    <div
      className='device flex items-center p-2 cursor-pointer text-sm'
      data-camera={cameraId}
      onClick={clickHandler}
      title='Use this webcam'
      data-test={testId}
    >
      <div className='mr-2 w-1/4'>
        <CameraFeed
          cameraId={cameraId}
          setDeviceError={setDeviceError}
          className='text-xs'
        />
      </div>
      <div
        className='flex-1 overflow-hidden truncate font-medium'
        aria-label={tr(Label.CAMERA)}
      >
        {label}
      </div>
      {active && (
        <div className='badge badge-sw-red text-xs text-white font-medium'>
          {tr(Label.SELECTED)}
        </div>
      )}
    </div>
  )
}
