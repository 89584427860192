import React, { FC, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { isFirefox } from 'react-device-detect'
import { requestPermissions } from 'common/services/relay'
import { deviceActions } from 'common/redux/features'
import { withProductName } from 'common/hoc'
import {
  hasVideoEnabled,
  hasAudioEnabled,
} from 'common/redux/features/device/deviceSelectors'
import { tr, Label } from 'common/i18n'
import { toastError, toastInfo } from 'src/services/ToastService'
import { Checkbox } from 'src/components/Inputs/Checkbox'
import { ButtonGroup } from 'src/components/Button/ButtonGroup'
import { PrimaryButton, SecondaryButton } from 'src/components/Button/Button'
import { PermissionSvg } from './PermissionSvg'

export const DevicesWarning: FC<{
  cancelHandler?: () => void
  isPrejoin?: boolean
  onContinue?: any
  setAlerted?: any
}> = withProductName(
  ({
    cancelHandler: cancel,
    isPrejoin,
    productName,
    onContinue,
    setAlerted,
  }) => {
    const dispatch = useDispatch()
    const audio = useSelector(hasAudioEnabled)
    const video = useSelector(hasVideoEnabled)
    const history = useHistory()
    const cancelHandler = () => {
      if (cancel) {
        // DevicesModal just closes the modal
        cancel()
      } else {
        // Prejoin sends you back to the lobby
        history.goBack()
      }
    }

    const audioHandler = ({ target }: ChangeEvent<HTMLInputElement>) => {
      const action = target.checked
        ? deviceActions.enableAudio()
        : deviceActions.disableMicrophone()
      dispatch(action)
    }

    const videoHandler = ({ target }: ChangeEvent<HTMLInputElement>) => {
      const action = target.checked
        ? deviceActions.enableVideo()
        : deviceActions.disableVideo()
      dispatch(action)
    }

    const confirmHandler = async () => {
      if (audio || video) {
        const constraints = { audio, video }
        console.debug('Ask for', constraints)
        await requestPermissions(constraints)
          .catch((error) => {
            // TODO: mixpanel event ?
            if (error.name === 'NotAllowedError') {
              toastError(tr(Label.DEVICES_ERROR_NOT_ALLOWED), {
                autoClose: false,
              })
            } else {
              toastInfo(tr(Label.DEVICES_ERROR_DEFAULT), { autoClose: false })
            }
          })
          .finally(() => {
            setAlerted(true)
          })
      } else {
        // Proceed to the next step even if both boxes are unchecked
        setAlerted(true)
      }

      if (onContinue) {
        onContinue({ audio, video })
      }
    }

    return (
      <>
        <div className='flex items-start md:items-center landscape:items-start justify-center m-2 sm:m-0'>
          <div
            className={`block-primary flex flex-col w-full ${
              isPrejoin ? 'p-6 sm:w-5/6 rounded-lg shadow-lg max-w-6xl' : ''
            }`}
          >
            <div className='sm:flex sm:flex-row gap-6 w-full text-center'>
              <div className='sm:w-1/2 sm:border-r border-gray-400 sm:pr-6 pb-6 sm:pb-0'>
                <p className='text-lg font-bold my-2'>
                  {tr(`${tr(Label.ALLOW)} ${tr(Label.DEVICES)}`)}
                </p>
                <p className='text-contrast-m'>
                  {tr(Label.PREJOIN_INSTRUX_1, { productName })}
                </p>
                <div className='inline-block text-left'>
                  <Checkbox
                    checked={video}
                    label={`${tr(Label.ALLOW)} ${tr(Label.CAMERA)}`}
                    labelClassName='text-base'
                    name='video'
                    onChange={videoHandler}
                    wrapperClassName='mt-4'
                  />
                  <Checkbox
                    checked={audio}
                    label={`${tr(Label.ALLOW)} ${tr(Label.MICROPHONE)}`}
                    labelClassName='text-base'
                    name='audio'
                    onChange={audioHandler}
                    wrapperClassName='mt-2'
                  />
                </div>
              </div>
              <div className='hidden sm:block sm:w-1/2'>
                <p className='text-lg font-bold my-2'>
                  {tr(Label.CHECK_BROWSER_PERMISSIONS)}
                </p>
                <p
                  className='text-base text-contrast-m'
                  data-i18n='[html]content.body'
                >
                  {tr(Label.PREJOIN_INSTRUX_2, { productName })}
                </p>
                <div className='flex justify-center mt-4'>
                  <PermissionSvg />
                </div>
                {isFirefox && (
                  <p className='text-sw-red font-bold mt-4'>
                    {tr(Label.PREJOIN_INSTRUX_FIREFOX)}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='text-contrast-m text-center mx-4 my-6'>
          {tr(Label.PREJOIN_INSTRUX_3, { productName })}
        </div>
        <ButtonGroup className='mx-2'>
          <SecondaryButton onClick={cancelHandler} transparent>
            {tr(Label.CANCEL)}
          </SecondaryButton>
          <PrimaryButton onClick={onContinue ?? confirmHandler}>
            {tr(Label.CONTINUE)}
          </PrimaryButton>
        </ButtonGroup>
      </>
    )
  }
)
