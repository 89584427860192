import React, { FC } from 'react'
import { compose } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { useCantinaBackendAPI, useCantinaId } from 'common/hooks'
import { tr, Label } from 'common/i18n'
import { withHybridFlag, withGrantViewAllRoomsFeatureFlag } from 'common/hoc'
import { UserType } from 'common/constants'
import { EyeIcon } from 'common/icons'
import { Endpoint } from 'common/rest'
import { CantinaState } from 'common/redux/interfaces'
import { participantActions } from 'common/redux/features'
import {
  isMyself,
  getCantinaUserId,
  getCantinaUserType,
  hasSpecialVisibility,
} from 'common/redux/features/participants/participantSelectors'
import { TestId } from 'src/constants'
import { ButtonFeatureToggle } from 'src/components/Inputs/ButtonFeatureToggle'
import { toastError } from 'src/services/ToastService'

const grantOptions = {
  endpoint: Endpoint.SpecialVisibility,
  method: 'POST',
}
const revokeOptions = {
  endpoint: Endpoint.SpecialVisibility,
  method: 'DELETE',
}

type GrantSpecialVisibilityToggleProps = {
  participantId: string
}

const GrantSpecialVisibilityToggle: FC<GrantSpecialVisibilityToggleProps> = ({
  participantId,
}) => {
  const { invokeAPI: grantHandler } = useCantinaBackendAPI(grantOptions)
  const { invokeAPI: revokeHandler } = useCantinaBackendAPI(revokeOptions)
  const dispatch = useDispatch()
  const cantinaId = useCantinaId()
  const myself = useSelector((state: CantinaState) =>
    isMyself(state, participantId)
  )
  const checked = useSelector((state: CantinaState) =>
    hasSpecialVisibility(state, participantId)
  )
  const userId = useSelector((state: CantinaState) =>
    getCantinaUserId(state, participantId)
  ) as string
  const userType = useSelector((state: CantinaState) =>
    getCantinaUserType(state, participantId)
  ) as UserType
  if (myself) {
    return null
  }

  const changeHandler = async (
    restApiFn: typeof grantHandler | typeof revokeHandler
  ) => {
    await restApiFn({
      body: JSON.stringify({
        cantinaId,
        userId,
        userType,
      }),
    })
  }

  const onToggleOn = async () => {
    try {
      await changeHandler(grantHandler)
      dispatch(
        participantActions.update({
          id: participantId,
          specialVisibility: true,
        })
      )
    } catch (error) {
      toastError(tr(Label.SPECIAL_VISIBILITY_ERROR))
    }
  }

  const onToggleOff = async () => {
    try {
      await changeHandler(revokeHandler)
      dispatch(
        participantActions.update({
          id: participantId,
          specialVisibility: false,
        })
      )
    } catch (error) {
      toastError(tr(Label.SPECIAL_VISIBILITY_ERROR))
    }
  }

  return (
    <ButtonFeatureToggle
      checked={Boolean(checked)}
      Icon={EyeIcon}
      label={Label.GRANT_VISIBILITY}
      onToggleOn={onToggleOn}
      onToggleOff={onToggleOff}
      testId={TestId.ParticipantToggleGrantModerator}
    />
  )
}

export const ScopedGrantSpecialVisibilityToggle = compose<
  FC<GrantSpecialVisibilityToggleProps>
>(
  withHybridFlag,
  withGrantViewAllRoomsFeatureFlag()
)(GrantSpecialVisibilityToggle)
