import React, { FC } from 'react'
import { compose } from 'redux'
import Clipboard from 'react-clipboard.js'
import { useRoomSelector } from 'common/hooks'
import { CopyIcon } from 'common/icons'
import { tr, Label } from 'common/i18n'
import { withHideInviteButtonFeatureFlag } from 'common/hoc'
import { roomInvitationURL } from 'common/services/helpers'
import { getRoomName } from 'common/redux/features/roomList/roomListSelectors'
import { toastSuccess } from 'src/services/ToastService'
import { withTextTooltip } from 'src/hoc/withTextTooltip'
import { FontAwesomeSizeProp } from 'common/icons/FontAwesomeIcon'

type RoomCopyInviteLinkButtonProps = {
  className?: string
  iconSize?: FontAwesomeSizeProp
  roomName: string
}

export const RoomCopyInviteLinkButton: FC<RoomCopyInviteLinkButtonProps> =
  withTextTooltip()(({ className, iconSize = 'lg', roomName }) => {
    const clipboardHandler = (e: MouseEvent) => {
      e.preventDefault()
      toastSuccess(tr(Label.COPY_INVITE_LINK_SUCCESS))
    }

    return (
      <Clipboard
        data-clipboard-text={roomInvitationURL(roomName)}
        onClick={clipboardHandler}
        className={className}
        button-aria-label={tr(Label.COPY_INVITE_LINK)}
      >
        <CopyIcon size={iconSize} />
      </Clipboard>
    )
  })

type LobbyRoomCopyInviteLinkButtonProps = {
  roomId: string
}
const LobbyRoomCopyInviteLinkButtonFunction: FC<LobbyRoomCopyInviteLinkButtonProps> =
  ({ roomId }) => {
    const roomName = useRoomSelector(getRoomName, roomId) as string

    return <RoomCopyInviteLinkButton roomName={roomName} />
  }

export const LobbyRoomCopyInviteLinkButton: FC<LobbyRoomCopyInviteLinkButtonProps> =
  compose(withHideInviteButtonFeatureFlag({ show: false }))(
    LobbyRoomCopyInviteLinkButtonFunction
  )
