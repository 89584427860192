import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isSidebarInvitationModalVisible } from 'common/redux/features/ui/uiSelectors'
import { getZoneInvitationList } from 'common/redux/features/roomZones/roomZonesSelectors'
import { uiActions } from 'common/redux/features'
import { Displayable, RoomCommandAction } from 'common/constants'
import { useDispatchRoomCommand } from 'common/hooks'
import {
  getActiveCallRoomId,
  getMyParticipantId,
} from 'common/redux/features/calls/callSelectors'
import { tr, Label } from 'common/i18n'
import { SidebarIcon } from 'common/icons'
import { TestId } from 'src/constants'
import { Modal } from '../Modal'
import ActionListFragment from '../ActionListFragment'

type SidebarInvitationModalProps = {}

export const SidebarInvitationModal: FC<SidebarInvitationModalProps> = () => {
  const dispatch = useDispatch()
  const dispatchRoomCommand = useDispatchRoomCommand()
  const show = useSelector(isSidebarInvitationModalVisible)
  const roomId = useSelector(getActiveCallRoomId)
  const participantId = useSelector(getMyParticipantId)
  const zoneInvitationList = useSelector(getZoneInvitationList)

  if (!show) {
    return null
  }

  const close = (forceClose?: boolean) => {
    if (zoneInvitationList?.length <= 1 || forceClose) {
      dispatch(uiActions.hide(Displayable.SidebarInvitationModal))
    }
  }

  const declineHandler = (zoneName: string) => {
    const declinedInvitation = zoneInvitationList.find(
      (inv) => inv.zoneName === zoneName
    )

    if (declinedInvitation) {
      dispatchRoomCommand({
        action: RoomCommandAction.ZoneInvitationDeclined,
        participantId,
        roomId,
        zoneCreatorId: declinedInvitation.sendingParticipantId,
        zoneName: declinedInvitation.zoneName,
      })
    }

    close()
  }

  const declineAllHandler = () => {
    // This is a band-aid that allows for re-invites
    zoneInvitationList.forEach((inv) => {
      dispatchRoomCommand({
        action: RoomCommandAction.ZoneInvitationDeclined,
        participantId,
        roomId,
        zoneCreatorId: inv.sendingParticipantId,
        zoneName: inv.zoneName,
      })
    })
    close(true)
  }

  const confirmHandler = (zoneName: string) => {
    dispatchRoomCommand({
      action: RoomCommandAction.ZoneJoin,
      participantId,
      roomId,
      zoneName,
    })
    close(true)
  }

  return (
    <Modal
      byline={tr(Label.SIDEBAR_INVITATION_BYLINE)}
      cancelLabel={tr(Label.DECLINE)}
      closeHandler={declineAllHandler}
      testId={TestId.SidebarModalInvitation}
      show={show}
      showCancelButton={false}
      showConfirmButton={false}
      title={tr(Label.SIDEBAR_INVITATION)}
      TitleIcon={SidebarIcon}
    >
      {zoneInvitationList.map((inv, i) => {
        const { zoneName } = inv

        return (
          <ActionListFragment
            key={`${zoneName}-${i}`}
            confirmHandler={() => confirmHandler(zoneName)}
            confirmLabel={tr(Label.JOIN)}
            confirmTestId={`${TestId.SidebarModalInvitationConfirmButton}_${i}`}
            declineHandler={() => declineHandler(zoneName)}
            declineLabel={tr(Label.DECLINE)}
            declineTestId={`${TestId.SidebarModalInvitationDeclineButton}_${i}`}
          >
            <div className='pb-2 font-bold'>{zoneName}</div>
          </ActionListFragment>
        )
      })}
    </Modal>
  )
}
