import React, { FC } from 'react'
import { tr, Label } from 'common/i18n'
import { RoomComponentProps } from './RoomDetail'
import { RoomInputGroup } from './RoomInputGroup'

export const RoomSignalWire: FC<RoomComponentProps> = ({
  room,
  update,
  getErrorByName,
}) => {
  return (
    <>
      <div className='text-contrast-h text-lg font-bold mb-6'>
        {tr(Label.SIGNALWIRE_INTEGRATION)}
      </div>
      <RoomInputGroup
        update={update}
        getErrorByName={getErrorByName}
        name='call_user'
        label={Label.CALL_USER}
        defaultValue={room?.call_user}
      />
      <RoomInputGroup
        update={update}
        getErrorByName={getErrorByName}
        name='text_user'
        label={Label.TEXT_USER}
        defaultValue={room?.text_user}
      />
    </>
  )
}
