import React, { FC, useEffect } from 'react'
import { SlidersIcon } from 'common/icons'
import { useDispatchRoomListCommand } from 'common/hooks'
import { RoomListCommandAction } from 'common/constants'
import { tr, Label } from 'common/i18n'
import { ButtonWithTooltip } from '../Button/Button'
import { BasicTooltip } from '../Tooltip'
import { RoomSettingsLobby } from '../CallSettings/RoomSettingsLobby'
import { TOOLTIP_OFFSET_Y_ROOM_CARD } from 'src/hoc/withTextTooltip'

const modifiers = [
  {
    name: 'offset',
    options: {
      offset: [0, TOOLTIP_OFFSET_Y_ROOM_CARD],
    },
  },
]

type RoomControlsButtonProps = {
  roomId: string
  roomName: string
}

export const RoomControlsButton: FC<RoomControlsButtonProps> = ({
  roomId,
  roomName,
}) => {
  return (
    <BasicTooltip
      contentAreaClassName='overflow-y-auto rounded-md'
      modifiers={modifiers}
      placement='right'
      tooltip={<TooltipContent roomId={roomId} roomName={roomName} />}
      tooltipClassName='menu-tooltip max-h-none'
      trigger='click'
    >
      <ButtonWithTooltip
        ariaLabel={tr(Label.ROOM_CONTROLS)}
        className='sw-btn-primary p-1'
        offsetY={TOOLTIP_OFFSET_Y_ROOM_CARD}
      >
        <SlidersIcon fixedWidth />
      </ButtonWithTooltip>
    </BasicTooltip>
  )
}

const TooltipContent: FC<RoomControlsButtonProps> = ({ roomId, roomName }) => {
  const dispatchRoomListCommand = useDispatchRoomListCommand()

  useEffect(() => {
    dispatchRoomListCommand({
      action: RoomListCommandAction.SubscribeToInfo,
      roomId,
    })

    return () => {
      dispatchRoomListCommand({
        action: RoomListCommandAction.UnsubscribeFromInfo,
        roomId,
      })
    }
  }, [dispatchRoomListCommand, roomId])

  return <RoomSettingsLobby roomId={roomId} roomName={roomName} />
}
