import React from 'react'
import { htmlClean } from 'common/services/helpers'

/**
 * @see https://github.com/apostrophecms/sanitize-html/issues/547
 */
export const RenderHTML = ({ text }: { text: string | null }) => {
  if (text) {
    return (
      <div
        className='embedded-html'
        dangerouslySetInnerHTML={{ __html: htmlClean(text) }}
      ></div>
    )
  }
  return null
}
