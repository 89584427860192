import React, { FC } from 'react'
import { useSelector } from 'react-redux'
// import { Link } from 'react-router-dom'
// import { RoutePath } from 'common/constants'
import {
  getUserName,
  getUserEmail,
} from 'common/redux/features/users/usersSelectors'
import { gravatarUrl } from 'common/services/helpers'
import { CantinaState } from 'common/src/redux/interfaces'
import { UserProfile } from 'src/components/UserProfile'
import { Size } from 'src/constants'

type UserDataProps = {
  avatarClassNames?: string
  badges?: React.ReactNode
  nameClassNames?: string
  showEmail?: boolean
  size?: Size
  userId: string
}

const UserData: FC<UserDataProps> = ({ badges, size, userId }) => {
  const name = useSelector((state: CantinaState) => getUserName(state, userId))
  const email = useSelector((state: CantinaState) =>
    getUserEmail(state, userId)
  )
  const avatar = gravatarUrl(email, name)

  // TODO: Enable Link when UserProfile page ready
  // const profileUrl = RoutePath.SettingsUser.replace(':username', userId)
  // <Link to={profileUrl} title={tr(Label.EDIT)}></Link>
  return (
    <UserProfile
      avatar={avatar}
      badges={badges}
      name={name}
      nameClassName='font-bold'
      size={size}
      subtitle={email}
      wrapperClassName='truncate pr-4'
    ></UserProfile>
  )
}

/**
 * This is just a name-only version that uses `id` (instead of userId).
 */
const AutoCompleteUserData = ({ id }: any) => {
  const name = useSelector((state: CantinaState) => getUserName(state, id))
  const email = useSelector((state: CantinaState) => getUserEmail(state, id))
  const avatar = gravatarUrl(email, name)

  return (
    <UserProfile
      avatar={avatar}
      name={name}
      nameClassName='text-sm font-medium'
      size={Size.Small}
      wrapperClassName='truncate'
    />
  )
}

export { UserData, AutoCompleteUserData }
