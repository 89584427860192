import React, { FC, MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deviceActions, updateDevice } from 'common/redux/features'
import { getSpeakerId } from 'common/redux/features/device/deviceSelectors'
import { withActiveCall } from 'common/hoc'
import { TestId } from 'src/constants'
import { useSpeakers } from 'common/hooks'

export const SpeakerList = withActiveCall(({ callId }) => {
  const dispatch = useDispatch()
  const clickHandler = (event: MouseEvent<HTMLDivElement>) => {
    const { speaker: deviceId, label } = event.currentTarget.dataset
    if (deviceId) {
      dispatch(updateDevice({ callId, kind: 'audiooutput', deviceId }))
      dispatch(deviceActions.speakerChanged({ deviceId, label }))
    }
  }
  const { devices } = useSpeakers()
  const currentSpeakerId = useSelector(getSpeakerId)

  if (!devices.length) {
    return null
  }

  return (
    <>
      <div className='block-highlight text-sm p-2 font-semibold'>Speakers</div>
      <div className='switch-device-wrapper' aria-label='Speaker List'>
        {devices.map((device, index) => (
          <Speaker
            key={device.id}
            speakerId={device.id}
            label={device.label}
            active={currentSpeakerId === device.id}
            clickHandler={clickHandler}
            testId={`${TestId.AudioButtonSpeakers}_${index}`}
          />
        ))}
      </div>
    </>
  )
})

type SpeakerProps = {
  label: string | null
  speakerId: string
  active?: boolean
  clickHandler: (event: MouseEvent<HTMLDivElement>) => void
  testId: TestId | string
}

const Speaker: FC<SpeakerProps> = ({
  speakerId,
  label,
  active = false,
  clickHandler,
  testId,
}) => {
  return (
    <div
      className='device flex items-center p-2 cursor-pointer text-sm'
      data-speaker={speakerId}
      data-label={label}
      onClick={clickHandler}
      title='Use this speaker'
      data-test={testId}
    >
      <div
        className='flex-1 overflow-hidden pl-4 truncate font-medium'
        aria-label='Speaker'
      >
        {label}
      </div>
      {active && (
        <div className='badge badge-sw-red text-xs text-white font-medium'>
          Selected
        </div>
      )}
    </div>
  )
}
