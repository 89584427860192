import React, { ChangeEvent, FC } from 'react'
import { LabelIconState } from 'common/src/constants'
import { TestId } from 'src/constants'
import { IconState } from 'src/components/IconState'

type TextAreaGroupProps = {
  className?: string
  error?: string
  label: string
  labelIconState?: LabelIconState
  name: string
  onBlur?: (event: ChangeEvent<HTMLTextAreaElement>) => void
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void
  placeholder?: string
  required?: boolean
  testId?: TestId
  value?: string
}

export const TextAreaGroup: FC<TextAreaGroupProps> = ({
  className = 'mb-4',
  error = null,
  label,
  labelIconState,
  name,
  onBlur,
  onChange,
  placeholder = '',
  required = false,
  testId,
  value,
}) => {
  const classNames = error ? 'sw-input-error' : ''
  return (
    <div className={className}>
      <label htmlFor={name}>
        {`${label}${required ? '\u00A0*' : ''}`}
        {labelIconState && <IconState state={labelIconState} />}
      </label>
      <textarea
        autoComplete={name}
        className={`sw-input ${classNames}`}
        data-test={testId}
        id={name}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        rows={5}
        value={value}
      ></textarea>
      <div
        className={`sw-error-hint ${error ? 'visible' : 'invisible'}`}
        data-test={TestId.MessageError}
      >
        {error}
      </div>
    </div>
  )
}
