import React, { memo, FC } from 'react'
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  Link,
  RouteProps,
} from 'react-router-dom'
import {
  ArrowLeftIcon,
  CameraOnIcon,
  CogIcon,
  FilmIcon,
  ThIcon,
  UserIcon,
  UsersIcon,
} from 'common/icons'
import { RoutePath, Scope } from 'common/constants'
import { useScopeSelector, useFetchUsers, useFetchGroups } from 'common/hooks'
import { tr, Label } from 'common/i18n'
import ScopedRoute from 'src/routes/ScopedRoute'
import { Header } from 'src/components/Header/Header'
import { DevicesModal } from 'src/modals/DevicesModal'
import { TestId } from 'src/constants'
import { Global } from './Global'
import { RoomList } from './Rooms/RoomList'
import { RoomDetail } from './Rooms/RoomDetail'
import { UserList } from './UserList'
import { GroupList } from './GroupList'
import { Group } from './Group'
import { ClipeezeList } from './Clipeeze'
import { RoomRecordingList } from './Recordings/RoomRecordingList'
import { RoomRecordingDetail } from './Recordings/RoomRecordingDetail'

type TabProps = {
  path: RouteProps['path']
  label: string
  navigateTo: RoutePath
  IconComponent: any
  requiredScope: Scope
}

const TABS: TabProps[] = [
  {
    path: RoutePath.SettingsGlobal,
    label: Label.GLOBAL,
    navigateTo: RoutePath.SettingsGlobal,
    IconComponent: CogIcon,
    requiredScope: Scope.UiSettingsGlobal,
  },
  {
    path: [
      RoutePath.SettingsUserList,
      RoutePath.SettingsAddUser,
      RoutePath.SettingsUser,
    ],
    label: Label.USERS,
    navigateTo: RoutePath.SettingsUserList,
    IconComponent: UserIcon,
    requiredScope: Scope.UiSettingsUsers,
  },
  {
    path: [RoutePath.SettingsGroupList, RoutePath.SettingsGroup],
    label: Label.GROUPS,
    navigateTo: RoutePath.SettingsGroupList,
    IconComponent: UsersIcon,
    requiredScope: Scope.UiSettingsGroups,
  },
  {
    path: [RoutePath.SettingsRoomList, RoutePath.SettingsRoom],
    label: Label.ROOMS,
    navigateTo: RoutePath.SettingsRoomList,
    IconComponent: ThIcon,
    requiredScope: Scope.UiSettingsRooms,
  },
  {
    path: RoutePath.SettingsRecordingList,
    label: Label.RECORDINGS,
    navigateTo: RoutePath.SettingsRecordingList,
    IconComponent: CameraOnIcon,
    requiredScope: Scope.UiSettingsRecordings,
  },
  {
    path: RoutePath.SettingsClipeeze,
    label: Label.CLIPEEZE,
    navigateTo: RoutePath.SettingsClipeeze,
    IconComponent: FilmIcon,
    requiredScope: Scope.UiSettingsClipeeze,
  },
]

const HomeLink: FC = () => {
  return (
    <a href='/' className='text-sw-red'>
      <ArrowLeftIcon className='mx-2' />
      {tr(Label.HOME)}
    </a>
  )
}

const SettingsPage: FC = () => {
  useFetchUsers()
  useFetchGroups()

  return (
    <>
      <Header left={HomeLink} />
      <div className='sw-page-content'>
        <div className='h-full flex flex-col justify-start'>
          <div className='block-primary shrink-0 h-24 px-4 flex flex-wrap justify-center'>
            <div className='grow self-center text-center font-neue font-bold text-contrast-h text-3xl whitespace-nowrap'>
              {tr(Label.ADMINISTRATION)}
            </div>
            <div className='flex grow max-w-full self-end justify-center'>
              <div className='shrink overflow-x-auto'>
                <div className='flex text-base'>
                  {TABS.map((props) => {
                    return <TabWithScope key={props.navigateTo} {...props} />
                  })}
                </div>
              </div>
            </div>
          </div>
          <Content />
        </div>
      </div>
      <DevicesModal testId={TestId.DevicesModalSystemSettings} />
    </>
  )
}

export default SettingsPage

const Tab: FC<TabProps> = memo(({ path, label, navigateTo, IconComponent }) => {
  const match = useRouteMatch({ path, strict: true, sensitive: true })
  const className = match
    ? 'text-sw-selected border-sw-selected'
    : 'border-primary cursor-pointer rounded-lg hover:bg-contrast-l'

  return (
    <Link
      to={navigateTo}
      className={`flex items-center mx-1 py-2 px-2 border-b-4 whitespace-nowrap ${className}`}
    >
      <IconComponent fixedWidth className='mr-2' />
      {tr(label)}
    </Link>
  )
})

const TabWithScope: FC<TabProps> = (props) => {
  const hasScope = useScopeSelector(props.requiredScope)
  return hasScope ? <Tab {...props} /> : null
}

const Content = () => {
  const hasScope = useScopeSelector(Scope.UiSettingsGlobal)
  const defaultRoute = hasScope
    ? RoutePath.SettingsGlobal
    : RoutePath.SettingsUserList
  const redirectTo = RoutePath.Home

  return (
    <div className='w-full max-w-screen-lg mx-auto px-4 pb-10'>
      <Switch>
        <Route
          exact
          path={RoutePath.Settings}
          render={() => <Redirect to={defaultRoute} />}
        />
        <ScopedRoute
          path={RoutePath.SettingsGlobal}
          redirectTo={redirectTo}
          scope={Scope.UiSettingsGlobal}
        >
          <Global />
        </ScopedRoute>

        <ScopedRoute
          exact
          path={RoutePath.SettingsRoomList}
          redirectTo={redirectTo}
          scope={Scope.UiSettingsRooms}
        >
          <RoomList />
        </ScopedRoute>
        <ScopedRoute
          path={RoutePath.SettingsRoom}
          redirectTo={redirectTo}
          scope={Scope.UiSettingsRooms}
        >
          <RoomDetail />
        </ScopedRoute>

        <ScopedRoute
          exact
          path={RoutePath.SettingsUserList}
          redirectTo={redirectTo}
          scope={Scope.UiSettingsUsers}
        >
          <UserList />
        </ScopedRoute>
        <ScopedRoute
          exact
          path={[RoutePath.SettingsUser]}
          redirectTo={redirectTo}
          scope={Scope.UiSettingsUsers}
        >
          <h1>User Item</h1>
        </ScopedRoute>

        <ScopedRoute
          exact
          path={RoutePath.SettingsGroupList}
          redirectTo={redirectTo}
          scope={Scope.UiSettingsGroups}
        >
          <GroupList />
        </ScopedRoute>
        <ScopedRoute
          exact
          path={RoutePath.SettingsGroup}
          redirectTo={redirectTo}
          scope={Scope.UiSettingsGroups}
        >
          <Group />
        </ScopedRoute>

        <ScopedRoute
          exact
          path={RoutePath.SettingsRecordingList}
          redirectTo={redirectTo}
          scope={Scope.UiSettingsRecordings}
        >
          <RoomRecordingList />
        </ScopedRoute>
        <ScopedRoute
          exact
          path={RoutePath.SettingsRecordingDetail}
          redirectTo={redirectTo}
          scope={Scope.UiSettingsRecordings}
        >
          <RoomRecordingDetail />
        </ScopedRoute>

        <ScopedRoute
          exact
          path={RoutePath.SettingsClipeeze}
          redirectTo={redirectTo}
          scope={Scope.UiSettingsClipeeze}
        >
          <ClipeezeList />
        </ScopedRoute>
      </Switch>
    </div>
  )
}
