import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { UsersIcon } from 'common/icons'
import { CantinaState } from 'common/redux/interfaces'
import { getGroupName } from 'common/redux/features/groups/groupsSelectors'

type GroupDataProps = {
  groupId: string
  className?: string
}

export const GroupData: FC<GroupDataProps> = ({ groupId, className = '' }) => {
  const groupName = useSelector((state: CantinaState) =>
    getGroupName(state, groupId)
  )

  return (
    <>
      <UsersIcon fixedWidth className={`mr-3 ${className}`} />
      {groupName}
    </>
  )
}

/**
 * This is just a proxy to GroupData when used
 * in the AutoComplete component that uses Items
 * with `id` (instead of groupId).
 */
export const AutoCompleteGroupData = ({ id }: any) => {
  return <GroupData groupId={id} />
}
