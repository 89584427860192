import React, { useState, FC, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CheckboxTree from 'react-checkbox-tree'
import {
  CheckSquareIcon,
  PlusSquareIcon,
  MinusSquareIcon,
  MinusSquareSolidIcon,
  SquareIcon,
} from 'common/icons'
import { tr, Label } from 'common/i18n'
import {
  useDispatchRoomCommand,
  useSmartRoomSelector,
  useLiveArrayBootstrap,
} from 'common/hooks'
import { RoomCommandAction } from 'common/constants'
import { withConferenceTransferScope } from 'common/hoc'
import { loadVertoParticipants } from 'common/services/relay'
import { callViewActions } from 'common/redux/views'
import { getActiveCallRoomId } from 'common/redux/features/calls/callSelectors'
import {
  getTransferListNodes,
  getParticipantIdsToTransfer,
  getCurrentRoomTransferListNodes,
} from 'common/redux/views/callView/callViewSelectors'
import { TestId } from 'src/constants'
import {
  PrimaryButton,
  SecondaryButton,
  TransparentPrimaryButton,
} from 'src/components/Button/Button'

import 'react-checkbox-tree/lib/react-checkbox-tree.css'

enum TransferAction {
  Invite = 'invite',
  Transfer = 'transfer',
}

type TransferListButtonProps = {
  onOpen: any
  onClose: any
  action?: TransferAction
  roomId?: string
  testId: TestId
}

const TransferListButton: FC<TransferListButtonProps> =
  withConferenceTransferScope(
    ({
      onOpen,
      onClose,
      action = TransferAction.Transfer,
      roomId = '',
      testId,
    }) => {
      const [opened, setOpened] = useState(false)
      const isRoomLocked = useSmartRoomSelector('isLocked', roomId)
      const isExistingRoomLocked = Boolean(roomId && isRoomLocked)

      const clickHandler = () => {
        setOpened(!opened)
        opened ? onClose() : onOpen()
      }

      return (
        <TransparentPrimaryButton
          className='sw-btn-xs capitalize'
          onClick={clickHandler}
          testId={testId}
          disabled={isExistingRoomLocked}
        >
          {action}
        </TransparentPrimaryButton>
      )
    }
  )

type TransferListProps = {
  action?: TransferAction
}

const CurrentRoomTransferList: FC<TransferListProps> =
  withConferenceTransferScope(({ action = TransferAction.Transfer }) => {
    useEffect(() => {
      loadVertoParticipants()
    }, [])

    const nodes = useSelector(getCurrentRoomTransferListNodes)

    return <CheckList nodes={nodes} action={action} />
  })

const TransferList: FC<TransferListProps> = withConferenceTransferScope(
  ({ action = TransferAction.Transfer }) => {
    const roomId = useSelector(getActiveCallRoomId)
    const nodes = useSelector(getTransferListNodes)
    useLiveArrayBootstrap({ roomId })

    return (
      <CheckList nodes={nodes} initialExpanded={[roomId]} action={action} />
    )
  }
)

type CheckListProps = {
  nodes: any[]
  initialExpanded?: any[]
  action: TransferAction
}

const CheckboxTreeIcons = {
  check: <CheckSquareIcon fixedWidth className='rct-icon rct-icon-check' />,
  uncheck: <SquareIcon fixedWidth className='rct-icon rct-icon-uncheck' />,
  halfCheck: (
    <MinusSquareSolidIcon fixedWidth className='rct-icon rct-icon-half-check' />
  ),
  expandClose: (
    <PlusSquareIcon
      fixedWidth
      size='lg'
      className='rct-icon rct-icon-expand-close'
    />
  ),
  expandOpen: (
    <MinusSquareIcon
      fixedWidth
      size='lg'
      className='rct-icon rct-icon-expand-open'
    />
  ),
  expandAll: (
    <PlusSquareIcon fixedWidth className='rct-icon rct-icon-expand-all' />
  ),
  collapseAll: (
    <MinusSquareIcon fixedWidth className='rct-icon rct-icon-collapse-all' />
  ),
}

const CheckList: FC<CheckListProps> = ({
  nodes,
  action,
  initialExpanded = [],
}) => {
  const dispatch = useDispatch()
  const checked = useSelector(getParticipantIdsToTransfer)
  const [expanded, setExpanded] = useState(initialExpanded)
  const clickHandler = useCallback(
    (node) => {
      if (!node.isLeaf) {
        return
      }
      if (node.checked) {
        dispatch(callViewActions.removeParticipantIdsToTransfer([node.value]))
      } else {
        dispatch(callViewActions.addParticipantIdsToTransfer([node.value]))
      }
    },
    [dispatch]
  )

  const checkHandler = useCallback(
    (value) => {
      dispatch(callViewActions.setParticipantIdsToTransfer(value))
    },
    [dispatch]
  )

  return (
    <div className='overflow-hidden h-auto'>
      <p className='text-sm py-2'>
        {tr(Label.SELECT_PEOPLE_YOU_WANT_TO, { action })}
      </p>
      <div className='room-transfer-box-wrapper'>
        {nodes.length ? (
          <CheckboxTree
            icons={CheckboxTreeIcons}
            iconsClass='fa5'
            nodes={nodes}
            checked={checked}
            expanded={expanded}
            showNodeIcon={false}
            showExpandAll={true}
            expandOnClick={true}
            onClick={clickHandler}
            onCheck={checkHandler}
            onExpand={setExpanded}
          />
        ) : (
          <p className='text-sm'>{tr(Label.NO_USERS_AVAILABLE)}</p>
        )}
      </div>
    </div>
  )
}

type TransferActionsProps = {
  extension: string
  onCancel: () => void
}

const TransferActions: FC<TransferActionsProps> = withConferenceTransferScope(
  ({ extension, onCancel }) => {
    const roomId = useSelector(getActiveCallRoomId)
    const dispatchRoomCommand = useDispatchRoomCommand()
    const completeHandler = () => {
      dispatchRoomCommand({
        roomId,
        action: RoomCommandAction.RoomNavigatorTransfer,
        extension,
      })
      onCancel()
    }

    return (
      <div className='flex justify-between w-full mt-2'>
        <SecondaryButton
          className='sw-btn-xs'
          onClick={onCancel}
          testId={TestId.RoomNavigatorButtonCancel}
        >
          {tr(Label.CANCEL)}
        </SecondaryButton>
        <PrimaryButton
          className='sw-btn-xs'
          onClick={completeHandler}
          testId={TestId.RoomNavigatorButtonCompleteTransfer}
        >
          {tr(Label.COMPLETE_TRANSFER)}
        </PrimaryButton>
      </div>
    )
  }
)

export {
  TransferAction,
  TransferListButton,
  CurrentRoomTransferList,
  TransferList,
  TransferActions,
}
