import React, { Suspense, lazy } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CloseIcon } from 'common/icons'
import { tr, Label } from 'common/i18n'
import { TestId } from 'src/constants'
import { callViewActions } from 'common/redux/views'
import { isCallSettingsShown } from 'common/redux/views/callView/callViewSelectors'
import { lazyLoadHandler } from 'common/services/helpers'
import { ErrorBoundary, LoadingMsg, LoadingFailure } from 'src/ErrorBoundary'

const CallSettings = lazy(() =>
  // @ts-ignore
  lazyLoadHandler(() => import('src/components/CallSettings/CallSettings'))
)

export const BottomDrawer = () => {
  const dispatch = useDispatch()

  const isShown = useSelector(isCallSettingsShown)
  const className = isShown ? 'opened' : ''
  const onCloseDrawer = () => {
    dispatch(callViewActions.closeCallSettings())
  }

  return (
    <div
      className={`relative block-primary in-call-bottom-drawer overflow-hidden ${className}`}
      aria-label='Settings Panel'
    >
      <div className='relative h-full w-full'>
        {isShown && (
          // @ts-ignore
          <ErrorBoundary fallback={LoadingFailure()}>
            <Suspense fallback={LoadingMsg()}>
              <CallSettings />
            </Suspense>
          </ErrorBoundary>
        )}
        <button
          aria-label={tr(Label.CLOSE)}
          className='sw-btn-close absolute top-0 right-0 mx-2 my-1'
          onClick={onCloseDrawer}
          data-test={TestId.DrawerButtonClose}
        >
          <CloseIcon size='lg' />
        </button>
      </div>
    </div>
  )
}
