import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faCog,
  faEllipsisH,
  faTachometerSlowest,
  faTachometerFastest,
  faPaintBrushAlt,
  faHeadset,
  faMegaphone,
  faFilmAlt,
  faHome,
  faRecordVinyl,
  faTh,
  faStop,
  faPlay,
  faPause,
  faStepForward,
  faStepBackward,
  faVolumeUp,
  faVolumeDown,
  faMicrophone,
  faMicrophoneSlash,
  faVideo,
  faVideoSlash,
  faDesktop,
  faHandPaper,
  faCaretUp,
  faCheckSquare,
  faMinusSquare,
  faPhone,
  faUser,
  faShareSquare,
  faSignOutAlt,
  faQuestion,
  faGripHorizontal,
  faUserFriends,
  faKey,
  faEnvelope,
  faImage,
  faLock,
  faSpinner,
  faExclamationTriangle,
  faExclamationCircle,
  faCheckCircle,
  faSearch,
  faUserPlus,
  faInfoCircle,
  faTimesOctagon,
  faPaperPlane,
  faTrashAlt,
  faPencilAlt,
  faPlusSquare,
  faExpand,
  faExpandAlt,
  faCompressWide,
  faBackspace,
  faEyeSlash,
  faEye,
  faRepeat,
  faArrows,
  faExternalLink,
  faFileVideo,
  faDownload,
  faSquare,
  faDoNotEnter,
  faWalkieTalkie,
  faCompass,
  faBars,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faPlus,
  faMinus,
  faCheck,
  faWrench,
  faTools,
  faPalette,
  faUsers,
  faMoon,
  faSun,
  faTimesCircle,
  faArrowLeft,
  faArrowRight,
  faWaveformPath,
  faSignalStream,
  faUserTag,
  faWaveSquare,
  faUserSlash,
  faSlidersH,
  faLayerPlus,
  faCopy,
  faPlayCircle,
  faVolumeSlash,
  faSignal,
  faLifeRing,
} from '@fortawesome/pro-solid-svg-icons'

import {
  faInfoCircle as faInfoCircleRegular,
  faEllipsisV,
} from '@fortawesome/pro-regular-svg-icons'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import {
  faObjectGroup,
  faCommentsAlt,
  faSignalAlt1,
} from '@fortawesome/pro-duotone-svg-icons'

library.add(
  faUser,
  faUsers,
  faPhone,
  faMicrophone,
  faMicrophoneSlash,
  faVideo,
  faVideoSlash,
  faHome,
  faSignOutAlt,
  faSpinner,
  faEnvelope,
  faExclamationTriangle,
  faExclamationCircle,
  faCheckCircle,
  faSearch,
  faCog,
  faHandPaper,
  faDoNotEnter,
  faGripHorizontal,
  faBars,
  faCommentsAlt,
  faFilmAlt,
  faUserFriends,
  faEllipsisH,
  faUserPlus,
  faDesktop,
  faTimes,
  faCopy,
  faPaintBrushAlt,
  faQuestion,
  faHeadset,
  faInfoCircle,
  faInfoCircleRegular,
  faCaretUp,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faTimesOctagon,
  faPaperPlane,
  faPencilAlt,
  faTrashAlt,
  faPlusSquare,
  faMinusSquare,
  faStop,
  faPlay,
  faPause,
  faStepForward,
  faStepBackward,
  faSignalAlt1,
  faSignal,
  faVolumeUp,
  faVolumeDown,
  faRecordVinyl,
  faObjectGroup,
  faExpand,
  faExpandAlt,
  faCompressWide,
  faBackspace,
  faEyeSlash,
  faEye,
  faRepeat,
  faArrows,
  faExternalLink,
  faFileVideo,
  faDownload,
  faTh,
  faCompass,
  faSquare,
  faCheckSquare,
  faMegaphone,
  faWalkieTalkie,
  faTachometerSlowest,
  faTachometerFastest,
  faShareSquare,
  faPlus,
  faMinus,
  faKey,
  faEllipsisV,
  faCheck,
  faImage,
  faLock,
  faWrench,
  faTools,
  faPalette,
  faMoon,
  faSun,
  faTimesCircle,
  faArrowLeft,
  faArrowRight,
  faWaveformPath,
  faSignalStream,
  faUserTag,
  faWaveSquare,
  faUserSlash,
  faLayerPlus,
  faSlidersH,
  faPlayCircle,
  faVolumeSlash,
  faLifeRing
)
