import { useCallback } from 'react'
import { useHistory, generatePath } from 'react-router-dom'
import { RoutePath } from 'common/constants'

type UseHistoryPushToRoomParams = {
  roomName: string
  hasVideo?: boolean
  force?: boolean
}

export const useHistoryPushToRoom = () => {
  const history = useHistory()

  const pushHandler = useCallback(
    ({
      roomName,
      hasVideo = true,
      force = false,
    }: UseHistoryPushToRoomParams) => {
      console.debug(
        'Navigate to:',
        JSON.stringify(
          {
            roomName,
            path: generatePath(RoutePath.Room, {
              room: roomName,
            }),
          },
          null,
          2
        )
      )
      history.push(
        generatePath(RoutePath.Room, {
          room: roomName,
        }),
        {
          extension: roomName,
          hasVideo,
          force,
        }
      )
    },
    [history]
  )

  return { pushHandler }
}
