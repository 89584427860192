import React, { FC, useState, useMemo } from 'react'
import { RoomType } from 'common/constants'
import { useRoomSelector } from 'common/hooks'
import { makeRoomCardSelector } from 'common/redux/features/roomList/roomListSelectors'
import { TestId } from 'src/constants'
import { RoomParticipants } from './RoomParticipants'
import { RoomPreview } from '../Rooms/RoomPreview'
import {
  TransferAction,
  TransferActions,
  CurrentRoomTransferList,
  TransferListButton,
} from './TransferList'
import { RoomData } from './RoomData'

type RoomProps = {
  roomId: string
  testId: TestId | string
}

export const CurrentRoomCard: FC<RoomProps> = ({ roomId, testId }) => {
  const selector = useMemo(makeRoomCardSelector, [])
  const { roomName, roomDescription } = useRoomSelector(selector, roomId)
  const [transferListOpened, setTransferListOpened] = useState(false)

  if (transferListOpened) {
    return (
      <div className='room-nav-box' data-test={testId}>
        <RoomData
          roomId={roomId}
          name={roomName}
          description={roomDescription}
        />
        <CurrentRoomTransferList action={TransferAction.Invite} />
        <TransferActions
          extension={roomName}
          onCancel={() => setTransferListOpened(false)}
        />
      </div>
    )
  }

  return (
    <div className='room-nav-box' data-test={testId}>
      <RoomData roomId={roomId} name={roomName} description={roomDescription} />
      <RoomPreview type={RoomType.Live} roomId={roomId} roomName={roomName} />
      <div className='flex justify-between items-center p-1'>
        <RoomParticipants roomId={roomId} />
        <TransferListButton
          action={TransferAction.Invite}
          onOpen={() => setTransferListOpened(true)}
          onClose={() => setTransferListOpened(false)}
          roomId={roomId}
          testId={TestId.RoomNavigatorButtonTransfer}
        />
      </div>
    </div>
  )
}
