import React, { ChangeEvent, FC } from 'react'
import { TestId, Size } from 'src/constants'

type ToggleButtonProps = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  checked?: boolean
  className?: string
  ariaLabel?: string
  testId: TestId
  size: Size
}

const ToggleButton: FC<ToggleButtonProps> = ({
  className = '',
  onChange,
  checked = false,
  ariaLabel,
  testId,
  size = Size.Small,
  children,
}) => {
  return (
    <label className={`toggle-${size} ${className}`}>
      {children}
      <div className='relative cursor-pointer'>
        <input
          type='checkbox'
          className='hidden'
          onChange={onChange}
          checked={checked}
          aria-label={ariaLabel}
          data-test={testId}
        />
        <div className='toggle-line'></div>
        <div className='toggle-dot'></div>
      </div>
    </label>
  )
}

export default ToggleButton
