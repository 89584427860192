import { PayloadAction } from '@reduxjs/toolkit'
import { SagaIterator } from 'redux-saga'
import { take, call, fork, select } from 'redux-saga/effects'
import { mixPanelIdentifyAction, mixPanelTrackAction } from '../../actions'
import { MixPanelEvent } from '../../../constants'
import { getTimezoneData } from '../../../services/helpers'
import { getUserClientDetails } from '../../../services/relay'
import { appMixPanel } from '../../mixPanel'
import { RoomCommandPayload } from '../../interfaces'
import { callViewActions } from '../../views'
import { settingsActions } from '../../features'
import { getName, getEmail, getUsername } from '../auth/authSelectors'
import { getRoomName } from '../rooms/roomsSelectors'
import { getEmail as getParticipantEmail } from '../participants/participantSelectors'
import { getActiveCallRoomId } from '../calls/callSelectors'
import { hasMixPanelFeatureFlag } from '../settings/settingsSelectors'

// prettier-ignore
export let ACTIONS: string[] = [
  mixPanelIdentifyAction.type,
  mixPanelTrackAction.type,
  callViewActions.enablePtt.type,
  callViewActions.disablePtt.type,
]

export function* worker({ type, payload }: PayloadAction<any>): SagaIterator {
  switch (type) {
    case mixPanelIdentifyAction.type: {
      const name = yield select(getName)
      const email = yield select(getEmail)
      const username = yield select(getUsername)
      const params = {
        cloudApp: 1,
        name,
        emailAddress: email,
        userName: username,
        ...payload,
        ...getTimezoneData(),
        ...getUserClientDetails(),
      }
      const userId = username || email
      return appMixPanel.identify(userId, params)
    }
    case mixPanelTrackAction.type: {
      const { event, params = {} } = payload
      params.emailAddress = yield select(getEmail)
      return appMixPanel.track(event, params)
    }
    case callViewActions.enablePtt.type:
    case callViewActions.disablePtt.type: {
      const event =
        type === callViewActions.enablePtt.type
          ? MixPanelEvent.EnablePushToTalk
          : MixPanelEvent.DisablePushToTalk
      const roomId = yield select(getActiveCallRoomId)
      const params = {
        roomName: yield select(getRoomName, roomId),
        emailAddress: yield select(getEmail),
      }
      return appMixPanel.track(event, params)
    }
    default:
      console.warn('Unhandled mixPanel action', type, payload)
  }
}

export function* watchMixPanel(actions: string[] = ACTIONS): SagaIterator {
  // Wait for bootstrapSuccess action to have the FF in the state
  yield take(settingsActions.bootstrapSuccess)
  const hasMixPanel = yield select(hasMixPanelFeatureFlag)
  if (!hasMixPanel) {
    console.debug('Disable MixPanel Tracking')
    return
  }

  yield call(appMixPanel.init)
  while (true) {
    const action = yield take(actions)
    yield fork(worker, action)
  }
}

export function* trackRoomCommandWorker({
  payload,
}: PayloadAction<RoomCommandPayload>): SagaIterator {
  try {
    const hasMixPanel = yield select(hasMixPanelFeatureFlag)
    if (!hasMixPanel) {
      return
    }
    const { action, participantId, roomId, uiGroup = '' } = payload
    // Ignore for now
    if (!participantId) {
      return
    }
    const params = {
      affectedUser: yield select(getParticipantEmail, participantId),
      initiatingUser: yield select(getEmail),
      roomName: yield select(getRoomName, roomId),
      // uiGroup: '', // enum value from the UI
    }

    return appMixPanel.track(action, params)
  } catch (error) {
    console.warn('Track RoomCommand Error', error)
  }
}
