import React, { FC, useRef, useEffect, memo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import {
  getHTMLVideoElement,
  doReinviteWithRelayOnly,
  askVideoKeyFrame,
} from 'common/services/relay'
import { CantinaState } from 'common/redux/interfaces'
import { getCallInboundVideoTrackIds } from 'common/redux/features/calls/callSelectors'

// Class name used for the MCU element
export const MCU_ELEMENT_CLASS = 'mcu-video'

type MCUProps = {
  callId: string
  className?: string
}

export const MCU: FC<MCUProps> = memo(({ callId, className = 'absolute' }) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const restartIceTimeout = useRef<ReturnType<typeof setTimeout>>()
  const keyframeCheckTimeout = useRef<ReturnType<typeof setTimeout>>()
  const videoTrackIds = useSelector(
    (state: CantinaState) => getCallInboundVideoTrackIds(state, callId),
    shallowEqual
  )

  useEffect(() => {
    const videoElement = getHTMLVideoElement(callId)

    const doRestartIceIfPaused = () => {
      if (videoElement && videoElement.paused === true) {
        console.debug(
          'Video still not playing - try with relay candidates only'
        )
        doReinviteWithRelayOnly(callId)
      }
    }

    const triggerPlayIfPaused = () => {
      if (videoElement && videoElement.paused) {
        videoElement
          .play()
          .catch((error) => console.warn('MCU cannot play', error))
      }
    }

    const checkVideoIsPlaying = () => {
      if (videoElement && videoElement.paused === false) {
        return
      }
      console.debug('- Ask video keyFrame')
      askVideoKeyFrame(callId)
      clearTimeoutRefs()
      keyframeCheckTimeout.current = setTimeout(() => {
        checkVideoIsPlaying()
      }, 1000)
    }

    const clearTimeoutRefs = () => {
      keyframeCheckTimeout.current && clearTimeout(keyframeCheckTimeout.current)
      restartIceTimeout.current && clearTimeout(restartIceTimeout.current)
    }

    if (videoElement) {
      // @ts-ignore
      videoElement.playsInline = true
      videoElement.classList.add(MCU_ELEMENT_CLASS)
      videoElement.addEventListener('play', clearTimeoutRefs)
      videoElement.addEventListener('playing', clearTimeoutRefs)
      videoElement.addEventListener('canplay', triggerPlayIfPaused)
      videoElement.addEventListener('canplaythrough', triggerPlayIfPaused)
      keyframeCheckTimeout.current = setTimeout(
        () => checkVideoIsPlaying(),
        1500
      )
      restartIceTimeout.current = setTimeout(() => doRestartIceIfPaused(), 3000)

      if (wrapperRef.current) {
        wrapperRef.current.appendChild(videoElement)
      }
      triggerPlayIfPaused()
    }

    return () => {
      clearTimeoutRefs()
    }
  }, [videoTrackIds, callId])

  return <div id='mcu-wrapper' ref={wrapperRef} className={className} />
})
