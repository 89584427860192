import React, { FC, useState, useEffect } from 'react'
import { tr, Label } from 'common/i18n'
import { PlusIcon, MinusIcon } from 'common/icons'
import { AUDIO_MAX_VOLUME, AUDIO_MIN_VOLUME } from 'common/constants'
import { useRoomTemplateField, useDebounce } from 'common/hooks'
import RangeSlider from 'src/components/Inputs/RangeSlider'
import { RoomTemplateInputGroup } from './RoomTemplateInputGroup'
import { SettingsTinyMCE } from './SettingsTinyMCE'

export const AdvancedOptions: FC = () => {
  return (
    <>
      <div className='text-contrast-h text-lg font-bold mb-6'>
        {tr(Label.ADVANCED_OPTIONS)}
      </div>
      <RoomTemplateInputGroup
        name='default_flags'
        label={Label.DEFAULT_FLAGS}
      />
      <AutoSaveDefaultPlayVolume />
      <SettingsTinyMCE
        name='sign_in_notification_body'
        label={Label.SIGN_IN_NOTIFICATION}
      />
    </>
  )
}

const AutoSaveDefaultPlayVolume = () => {
  const { reduxValue, iconState, update } = useRoomTemplateField(
    'default_play_volume'
  )
  const [sliderValue, setSliderValue] = useState((reduxValue as number) ?? 0)
  const debouncedValue = useDebounce(sliderValue, 500)

  useEffect(() => {
    update(debouncedValue)
  }, [update, debouncedValue])

  return (
    <RangeSlider
      className='text-base mb-3'
      currentValue={sliderValue}
      label='Default File Playback Volume'
      labelIconState={iconState}
      LowerIcon={MinusIcon}
      maxValue={AUDIO_MAX_VOLUME}
      minValue={AUDIO_MIN_VOLUME}
      onValueChange={setSliderValue}
      step={1}
      UpperIcon={PlusIcon}
    />
  )
}
