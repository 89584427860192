import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { uiActions } from 'common/redux/features'
import { getVersion } from 'common/redux/features/settings/settingsSelectors'
import { isAboutModalVisible } from 'common/redux/features/ui/uiSelectors'
import { Displayable } from 'common/constants'
import { tr, Label } from 'common/i18n'
import { TestId } from 'src/constants'
import { Modal } from './Modal'
import { SwLogo } from '../layout/SwLogo'
import { Copyright } from '../components/Copyright/Copyright'

export const AboutModal: FC = () => {
  const dispatch = useDispatch()
  const show = useSelector(isAboutModalVisible)
  const version = useSelector(getVersion)

  const closeHandler = () => {
    dispatch(uiActions.hide(Displayable.AboutModal))
  }

  return (
    <Modal
      closeHandler={closeHandler}
      testId={TestId.AboutModal}
      show={show}
      showCancelButton={false}
      showConfirmButton={false}
      title=''
    >
      <div className='pb-6 text-center'>
        <SwLogo className='pb-4 inline-block w-1/2 h-auto' />
        {!!version && (
          <p className='pb-4 text-sm leading-snug'>
            {tr(Label.VERSION)}
            <br />
            {version}
          </p>
        )}
        <Copyright className='text-sm whitespace-nowrap' />
      </div>
    </Modal>
  )
}
