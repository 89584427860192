import { FC } from 'react'
import { InfoCircleIcon } from 'common/icons'
import { withTextTooltip } from 'src/hoc/withTextTooltip'

type InfoTooltipProps = {
  title: string
}

export const InfoTooltip: FC<InfoTooltipProps> = ({ title }) => {
  return withTextTooltip({
    className: 'max-w-xs font-normal leading-tight whitespace-normal p-3',
    title,
  })(InfoCircleIcon)({ className: 'mx-2 cursor-help' })
}
