import React, { FC, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useLocation } from 'react-router'
import { RoutePath } from 'common/constants'
import { tr, Label } from 'common/i18n'
import { deviceActions } from 'common/redux/features'
import { stripFlags } from 'common/services/helpers'
import { CallRequestPayload } from 'common/redux/interfaces'
import {
  hasDeviceError as hasDeviceErrorSelector,
  getLastTriedCall,
} from 'common/redux/features/calls/callSelectors'
import SimpleHeader from 'src/components/Header/SimpleHeader'
import { DevicesWarning } from 'src/components/Devices/DevicesWarning'
import { useHistoryPushToRoom } from 'src/effects/useHistoryPushToRoom'
import { PermissionPage } from './PermissionPage'

export const PrejoinPage: FC = () => {
  const dispatch = useDispatch()
  const { pushHandler } = useHistoryPushToRoom()
  const { state = { extension: 'test' } } = useLocation<CallRequestPayload>()
  const [alerted, setAlerted] = useState(false)
  const hasDeviceError = useSelector(hasDeviceErrorSelector)
  const lastTriedCall = useSelector(getLastTriedCall)

  let callParams: CallRequestPayload
  if (hasDeviceError && lastTriedCall) {
    callParams = lastTriedCall
  } else {
    callParams = {
      ...state,
      force: true,
    }
  }

  const joinHandler = () => {
    dispatch(deviceActions.promoteDevices())

    pushHandler({
      ...callParams,
      roomName: callParams.extension,
    })
  }

  if (!state) {
    return <Redirect to={RoutePath.Home} />
  }

  return (
    <>
      <SimpleHeader />
      <div className='sw-page-content'>
        <div className='text-center text-contrast-h mt-2 sm:mt-12 mb-4 sm:mb-6'>
          <p>{tr(Label.YOU_ARE_JOINING)}</p>
          <p className='text-3xl mt-2 font-bold font-neue'>
            {decodeURIComponent(stripFlags(callParams.extension))}
          </p>
        </div>
        {alerted || (hasDeviceError && lastTriedCall) ? (
          // Step 2
          <PermissionPage joinHandler={joinHandler} />
        ) : (
          // Step 1
          <DevicesWarning isPrejoin setAlerted={setAlerted} />
        )}
      </div>
    </>
  )
}
