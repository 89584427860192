import React, { FC, useState, useEffect, ChangeEvent } from 'react'
import { Link } from 'react-router-dom'
import { useFetchGroups, useDeleteGroup, useDebounce } from 'common/hooks'
import { TimesCircleIcon } from 'common/icons'
import { RoutePath, GroupRole } from 'common/constants'
import { tr, Label } from 'common/i18n'
import { SEARCH_DEBOUNCE_DELAY } from 'src/constants'
import { ButtonWithConfirmation } from 'src/components/Button/Button'
import { RequestStateSwitcher } from './RequestStateSwitcher'
import { CreateNewGroupButton } from './CreateNewGroupButton'
import { GroupData } from './GroupData'
import { toastSuccess, toastError } from 'src/services/ToastService'
import { SearchInput } from 'src/components/Inputs/SearchInput'

export const GroupList: FC = () => {
  const { getGroups, requestState, refresh, error } = useFetchGroups({
    refreshOnMount: false,
  })
  const [filter, setFilter] = useState('')
  const delayedFilter = useDebounce(
    filter,
    SEARCH_DEBOUNCE_DELAY
  )?.toLowerCase()
  const filteredGroups = getGroups({ filterByText: delayedFilter })

  return (
    <>
      <div className='administration-section-title'>
        <div className='font-neue font-bold text-contrast-h text-2xl'>
          {tr(Label.GROUPS)}
        </div>
        <CreateNewGroupButton />
      </div>
      <div className='block-primary administration-section-content'>
        <RequestStateSwitcher
          requestState={requestState}
          refresh={refresh}
          error={error}
        >
          <div className='flex items-center justify-between mb-4'>
            <SearchInput
              className='text-sm mr-4 '
              clearInputFunction={() => setFilter('')}
              currentValue={filter}
              inputChanged={(event: ChangeEvent<HTMLInputElement>) => {
                setFilter(event.target.value)
              }}
              name='groupFilter'
              placeholder='Search'
            />
          </div>
          {!filteredGroups?.length && (
            <p className='text-base py-2'>No groups found.</p>
          )}
          {filteredGroups.map((group) => {
            return (
              <GroupItem
                key={group.id}
                refresh={refresh}
                groupId={group.id}
                myRole={group?.myRole}
              />
            )
          })}
        </RequestStateSwitcher>
      </div>
    </>
  )
}

type GroupItemProps = {
  groupId: string
  refresh: () => Promise<any>
  myRole?: GroupRole
}
const GroupItem: FC<GroupItemProps> = ({ groupId, myRole, refresh }) => {
  const editUrl = RoutePath.SettingsGroup.replace(':groupId', groupId)
  const canDelete = myRole === GroupRole.Maintainer

  return (
    <Link
      to={editUrl}
      className='administration-section-item-title group h-12 hover:shadow-md'
    >
      <span className='group-hover:text-sw-selected w-3/4'>
        <GroupData groupId={groupId} />
      </span>
      <div className='item-actions justify-center gap-3'>
        {canDelete && <DeleteGroupButton groupId={groupId} refresh={refresh} />}
      </div>
    </Link>
  )
}

const DeleteGroupButton: FC<{
  groupId: string
  refresh: () => Promise<any>
}> = ({ groupId, refresh }) => {
  const { deleteGroup, error } = useDeleteGroup(groupId)

  const removeHandler = async () => {
    await deleteGroup()
    toastSuccess(tr(Label.SUCCESSFUL_DELETE, { item: tr(Label.GROUP) }))
    await refresh()
  }

  useEffect(() => {
    if (error) {
      toastError(error)
    }
  }, [error])

  return (
    <ButtonWithConfirmation
      ariaLabel={tr(Label.REMOVE)}
      className='text-sw-red'
      onClick={removeHandler}
      singleUse
    >
      <TimesCircleIcon />
    </ButtonWithConfirmation>
  )
}
