import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Widget } from '@typeform/embed-react'
import { tr, Label } from 'common/i18n'
import { callViewActions } from 'common/redux/views/callView/callViewSlice'
import { Modal } from 'src/modals/Modal'
import { TestId } from 'src/constants'
import {
  getCompany,
  getEmail,
  getName,
} from 'common/redux/features/auth/authSelectors'
import { instanceName } from '../services/Helpers'

export const PostCallHangupSurvey = ({
  show,
  typeFormId,
}: {
  show: boolean
  typeFormId?: string | null
}) => {
  const dispatch = useDispatch()
  const closeHandler = () => {
    dispatch(callViewActions.hidePostCallSurvey())
  }
  const hidden = {
    hiddenname: useSelector(getName),
    hiddenemail: useSelector(getEmail),
    hiddeninstance: instanceName(),
    hiddencompany: useSelector(getCompany),
  }
  if (!typeFormId) {
    console.error('Invalid typeFormId')
    closeHandler()
    return null
  }

  // min-height for the iframe is set in typeform.css
  return (
    <Modal
      closeHandler={closeHandler}
      isPreview
      show={show}
      showCancelButton={false}
      showConfirmButton={false}
      testId={TestId.PostCallSurvey}
      title={tr(Label.SURVEY)}
      wrapperClassName='post-call-survey-modal-wrapper'
    >
      <div className='self-center m-auto w-full h-3/5 sm:h-2/5 sm:max-w-md md:max-w-4xl'>
        <Widget
          className='typeform-widget h-full sm:h-auto'
          style={{ minHeight: '500px' }}
          hidden={hidden}
          id={typeFormId}
          onSubmit={closeHandler}
          onClose={closeHandler}
        />
      </div>
    </Modal>
  )
}
