import { createSelector } from 'reselect'
import { CantinaState } from '../../interfaces'
import { ProductLine, FeatureFlag, CURRENT_HOSTNAME } from '../../../constants'
import { capitalize, isDev } from '../../../services/helpers'
import { RailsEndpoint, Endpoint, Settings } from '../../../rest'
import { tr, Label } from '../../../i18n'

export const getCantinaId = ({ settings }: CantinaState) => settings.cantinaId
export const getInternalAPIHost = ({ settings }: CantinaState) => {
  return settings?.internalAPIHost ?? ''
}
export const getCantinaManagerHost = ({ settings }: CantinaState) => {
  // FIXME:
  const internalAPIHost = settings?.internalAPIHost ?? ''
  if (internalAPIHost && internalAPIHost.includes('swire.io')) {
    return 'relay.swire.io'
  }
  return 'relay.signalwire.com'
}
export const getCantinaBackendHost = ({ settings }: CantinaState) => {
  /**
   * Fallback to ENV variable since in `DEV` we don't have a valid domain
   * for the `/bootstrap` request.
   * In DEV: the request to bootstrap will use REACT_APP_CANTINA_BACKEND from ENV
   * In PROD: the request to bootstrap will use <empty-string> falling back to
   * a relative path to the FQDN since the ENV variable is not set.
   */
  return (
    settings?.cantinaBackendHost ||
    (process.env?.REACT_APP_CANTINA_BACKEND ?? '')
  )
}
export const getPromoImageHost = ({ settings }: CantinaState) => {
  // Fallback to empty so the browser will use the domain
  return settings?.promoImageHost ?? ''
}
export const getProjectId = ({ settings }: CantinaState) => settings.projectId
export const getVertoHost = ({ settings }: CantinaState) => {
  // TODO: check if still used
  if (isDev() && process.env.REACT_APP_FORCE_HOSTNAME) {
    return `wss://${process.env.REACT_APP_FORCE_HOSTNAME}/freeswitch`
  }
  const url = settings?.connectionSettings?.wsURL
  return url === 'auto' ? `wss://${window.location.hostname}/freeswitch` : url
}
export const getIceServers = ({ settings }: CantinaState) => {
  const turn = settings?.connectionSettings?.turnServer || null
  return turn ? [turn] : null
}
export const getAppStyle = ({ settings }: CantinaState) => {
  return settings.style || ProductLine.Work
}
export const getDefaultRoomTemplateId = ({ settings }: CantinaState) => {
  return settings.defaultRoomTemplateId
}
export const getUpdatedAt = ({ settings }: CantinaState) => {
  return settings.updated_at
}
export const getLobbyHeader = ({ settings }: CantinaState) => {
  return settings.lobby_header
}
export const getLobbyFooter = ({ settings }: CantinaState) => {
  return settings.lobby_footer
}
export const getFeatureFlags = ({ settings }: CantinaState) => {
  return settings?.featureFlags || []
}

export const makeFeatureFlagSelector = (
  requiredFlags: FeatureFlag[],
  customSelector = (_: CantinaState) => true
) => {
  return createSelector(
    getFeatureFlags,
    customSelector,
    (featureFlags, customSelectorCheck) => {
      const hasFlags = requiredFlags.every((flag) =>
        featureFlags.includes(flag)
      )
      return hasFlags && customSelectorCheck
    }
  )
}

/**
 * Returns a selector based on the "key" param
 */
export const makeSettingsKeySelector = (key: keyof Settings) => {
  return ({ settings }: CantinaState) => settings?.[key]
}

export const hasWatchRTCFeatureFlag = makeFeatureFlagSelector([
  FeatureFlag.WatchRTC,
])

export const hasMixPanelFeatureFlag = makeFeatureFlagSelector([
  FeatureFlag.MixPanel,
])

export const hasSidebarConversationsGuestCreateFeatureFlag =
  makeFeatureFlagSelector([
    FeatureFlag.SidebarConversations,
    FeatureFlag.SidebarConversationsGuestCreate,
  ])

export const hasAllowStereoAudioFeatureFlag = makeFeatureFlagSelector([
  FeatureFlag.AllowStereoAudio,
])

export const hasHideParticipantEmailFeatureFlag = makeFeatureFlagSelector([
  FeatureFlag.HideParticipantEmail,
])

export const hasHideRaiseHandFeatureFlag = makeFeatureFlagSelector([
  FeatureFlag.HideRaiseHand,
])

/**
 * Check if the SettingsState has the required params for the App.
 */
export const hasValidSettings = ({ settings }: CantinaState) => {
  return Boolean(
    settings.cantinaId &&
      settings.defaultRoomTemplateId &&
      settings?.domains.length
  )
}

export const getSignInNotificationBody = makeSettingsKeySelector(
  'sign_in_notification_body'
)

export const getBroadcasterBaseUrl = ({ settings }: CantinaState) => {
  return settings?.connectionSettings?.broadcasterBaseUrl || ''
}

export const getSubdomainURL = createSelector(
  makeFeatureFlagSelector([FeatureFlag.SubdomainRefresh]),
  (hasSubdomain) => {
    try {
      if (hasSubdomain) {
        const domain = CURRENT_HOSTNAME.split('.')[0]
        return `https://${CURRENT_HOSTNAME}`.replace(domain, `${domain}.api`)
      }
      return process.env.REACT_APP_LEGACY_BACKEND
    } catch (error) {
      return process.env.REACT_APP_LEGACY_BACKEND
    }
  }
)

export const getHeaderLogo = createSelector(
  ({ settings }: CantinaState) => settings.header_logo,
  makeFeatureFlagSelector([FeatureFlag.CustomHeaderLogo]),
  (headerLogo, hasFeatureFlag) => {
    return hasFeatureFlag ? headerLogo : null
  }
)

export const getTypeFormId = createSelector(
  ({ settings }: CantinaState) => settings.typeFormId,
  makeFeatureFlagSelector([FeatureFlag.HideTypeFormSurvey]),
  (typeFormId, hideTypeFormSurveyFF) => {
    return hideTypeFormSurveyFF ? null : typeFormId
  }
)

export const getInternalAPIEndpoint = createSelector(
  getCantinaId,
  getInternalAPIHost,
  (_: CantinaState, endpoint: RailsEndpoint) => endpoint,
  (cantinaId, internalAPIHost, endpoint) => {
    return `${internalAPIHost}${endpoint}`.replace(
      ':cantinaId',
      cantinaId as string
    )
  }
)

export const getCantinaBackendAPIEndpoint = createSelector(
  getCantinaId,
  getCantinaBackendHost,
  (_: CantinaState, endpoint: Endpoint) => endpoint,
  (cantinaId, cantinaBackendHost, endpoint) => {
    return `${cantinaBackendHost}${endpoint}`.replace(
      ':cantinaId',
      cantinaId as string
    )
  }
)

const getDefaultAppName = createSelector(
  getAppStyle,
  (appStyle) => `SignalWire ${capitalize(appStyle ?? 'work')}`
)

const getCustomAppName = createSelector(
  ({ settings }: CantinaState) => settings.custom_app_label,
  makeFeatureFlagSelector([FeatureFlag.CustomAppLabel]),
  (customAppLabel, hasFlag) => {
    return hasFlag && Boolean(customAppLabel) ? customAppLabel : null
  }
)

export const getAppName = createSelector(
  getDefaultAppName,
  getCustomAppName,
  (appName, customAppName) => {
    return customAppName ?? appName
  }
)

export const getCustomNameLabel = createSelector(
  ({ settings }: CantinaState) => settings.custom_name_label,
  makeFeatureFlagSelector([FeatureFlag.CustomNameLabel]),
  (customNameLabel, hasFlag) => {
    return hasFlag && customNameLabel ? customNameLabel : tr(Label.NAME)
  }
)

export const getCustomCompanyLabel = createSelector(
  ({ settings }: CantinaState) => settings.custom_company_label,
  makeFeatureFlagSelector([FeatureFlag.CustomCompanyLabel]),
  (customCompanyLabel, hasFlag) => {
    return hasFlag && customCompanyLabel
      ? customCompanyLabel
      : tr(Label.COMPANY)
  }
)

export const getVersion = ({ settings }: CantinaState) => {
  return settings.version
}
