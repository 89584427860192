import React, { FC } from 'react'
import { compose } from 'redux'
import { useSelector } from 'react-redux'
import {
  isDrawerShown,
  isChatShown,
  isClipeezeShown,
  isParticipantListShown,
  isRoomNavigatorShown,
} from 'common/redux/views/callView/callViewSelectors'
import { callViewActions } from 'common/redux/views'
import { tr, Label } from 'common/i18n'
import { TestId } from 'src/constants'
import {
  withDisableChatGuard,
  withHideParticipantListFeatureFlag,
} from 'common/hoc'
import {
  getActiveCallRoomId,
  isNotSelfModerator,
} from 'common/redux/features/calls/callSelectors'
import DrawerHeader from 'src/components/Header/DrawerHeader'
import { ParticipantList } from 'src/components/ParticipantList/ParticipantList'
import { SearchParticipantListWithFilter } from 'src/components/ParticipantList/SearchParticipantList'
import { ParticipantGroupControls } from 'src/components/ParticipantList/ParticipantGroupControls'
import { ZoneList } from 'src/components/ParticipantList/ZoneList'
import { Clipeeze } from 'src/components/Clipeeze/Clipeeze'
import { RoomNavigatorList } from 'src/components/RoomNavigator/RoomNavigatorList'
import { SearchRoomNavigatorWithFilter } from 'src/components/RoomNavigator/SearchRoomNavigator'
import { Chat, MessageInput } from 'src/components/Chat/Chat'

export const RightDrawer: FC = () => {
  const drawerShown = useSelector(isDrawerShown)
  const className = drawerShown ? 'opened' : ''

  return (
    <div className={`in-call-right-drawer block-primary ${className}`}>
      <RightDrawerParticipantList />
      <RightDrawerRoomNavigator />
      <RightDrawerChat />
      <RightDrawerClipeezeList />
    </div>
  )
}

const RightDrawerChat: FC = withDisableChatGuard(() => {
  const isShown = useSelector(isChatShown)

  if (!isShown) {
    return null
  }

  return (
    <div className='flex-1 flex flex-col overflow-auto w-full'>
      <DrawerHeader
        title='Chat'
        closeActionCreator={callViewActions.closeChat}
        testId={TestId.DrawerTitleChat}
      />
      <div className='block-primary flex flex-1 flex-col overflow-hidden relative'>
        <Chat />
        <MessageInput />
      </div>
    </div>
  )
})

const RightDrawerClipeezeList: FC = () => {
  const isShown = useSelector(isClipeezeShown)
  if (!isShown) {
    return null
  }

  return (
    <div className='flex-1 flex flex-col overflow-auto w-full'>
      <DrawerHeader
        title='ClipEEze'
        closeActionCreator={callViewActions.closeClipeeze}
        testId={TestId.DrawerTitleClipeeze}
      />
      <Clipeeze />
    </div>
  )
}

const RightDrawerParticipantListFunction: FC = () => {
  const isShown = useSelector(isParticipantListShown)
  const roomId = useSelector(getActiveCallRoomId)
  if (!isShown || !roomId) {
    return null
  }

  return (
    <div className='flex-1 flex flex-col overflow-auto w-full'>
      <DrawerHeader
        title='Participants'
        closeActionCreator={callViewActions.closeParticipantList}
        testId={TestId.DrawerTitleParticipantList}
      />
      <div className='block-primary flex flex-col h-full overflow-hidden'>
        <div className='shrink-0'>
          <SearchParticipantListWithFilter
            autoFocus
            className='h-10 text-base mt-3 mb-4 px-2'
          />
        </div>
        <div className='w-full flex flex-1 flex-col overflow-y-auto select-none'>
          {/* Grouped by attribute */}
          <ParticipantGroupControls roomId={roomId} />

          {/* Grouped by zone */}
          <div className='px-2 pt-2'>
            <ZoneList roomId={roomId} />
          </div>

          {/* The complete list */}
          <div className='participant-list-wrapper flex-1'>
            <ParticipantList roomId={roomId} />
          </div>
        </div>
      </div>
    </div>
  )
}

const RightDrawerParticipantList = compose(
  withHideParticipantListFeatureFlag({
    show: false,
    customSelector: isNotSelfModerator,
  })
)(RightDrawerParticipantListFunction)

const RightDrawerRoomNavigator: FC = () => {
  const isShown = useSelector(isRoomNavigatorShown)
  if (!isShown) {
    return null
  }

  return (
    <div className='flex-1 flex flex-col overflow-auto w-full'>
      <DrawerHeader
        title={tr(Label.ROOM_NAVIGATOR)}
        closeActionCreator={callViewActions.closeRoomNavigator}
        testId={TestId.DrawerTitleRoomNavigtor}
      />
      <div className='block-primary flex flex-col h-full overflow-hidden'>
        <div className='shrink-0'>
          <SearchRoomNavigatorWithFilter />
        </div>
        <div
          id='room-lazy-container'
          className='w-full flex-1 overflow-y-auto overflow-x-hidden'
        >
          <RoomNavigatorList />
        </div>
      </div>
    </div>
  )
}
